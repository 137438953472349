import type { AccountTypes } from '../constants';

export type TimeFormat = '12h' | '24h';

export interface IRegionalSettings {
    homeCountry: number;
    homeCountryCode: string;
    timezone: Nullable<number>;
    language: Nullable<string>;
    greetingLanguage: Nullable<string>;
    formattingLocale: Nullable<string>;
    timeFormat: Nullable<TimeFormat>;
}

export type IRCDetails = {
    rcPlatform: string;
    agentAssistantClientId: string;
    agentAssistantDomain: string;
    customerDataClientId: string;
    customerDataDomain: string;
    supervisorAssistantClientId: string;
    supervisorAssistantDomain: string;
    aiTrackerClientId: string;
    aiTrackerDomain: string;
};

export interface IUserDetails extends IRCDetails {
    passwordReset: boolean;
    iqUrl: string;
    port: number;
    agentDetails: Nullable<IAgent[]>;
    adminId: Nullable<number>;
    mainAccountId: Nullable<string>;
    managedMainAccountIds: Nullable<string[]>;
    loginHashcode: Nullable<string>;
    adminUrl: Nullable<string>;
    agentUrl: Nullable<string>;
    analyticsUrl: Nullable<string>;
    rcUser: Nullable<IRcUser>;
    ssoLogin: boolean;
    platformId: string;
    accessToken?: string;
    refreshToken?: string;
    tokenType?: string;
    redirectUrl?: Nullable<string>;
    userManagedByRC: boolean;
    regionalSettings: IRegionalSettings;
    availableWEM: boolean;
    availableRingSense?: boolean;
    availableAgentAssist?: boolean;
    esu: boolean;
    rcAccessToken?: string;
    rcRefreshToken?: string;
}

export type AccountType = (typeof AccountTypes)[keyof typeof AccountTypes];

export interface IFullUserDetails {
    accountType: AccountType;
    adminId: Nullable<number>;
    rcUserId: Nullable<number>;
    rcAccountId: Nullable<string>;
    evMainAccountId: Nullable<string>;
    digitalAccountId: Nullable<string>;
    digitalAccountApiUrl: Nullable<string>;
    digitalAccountAdminUrl: Nullable<string>;
    digitalAccountEmbedUrl: Nullable<string>;
    agentDetails: Nullable<IAgent[]>;
    inboxModeEnabled: boolean;
    taskModeEnabled: boolean;
    digitalAdminEnabled: boolean;
    digitalAnalyticsEnabled: boolean;
    voiceError?: string;
    digitalError?: string;
    digitalAgentEnabled: boolean;
    evsrUrl?: string;
}

export interface IAgent {
    agentId: number;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    email: Nullable<string>;
    username: string;
    agentType: string;
    rcUserId: Nullable<number>;
    accountId: string;
    accountName: string;
    agentGroupId: Nullable<number>;
    externalAgentId: Nullable<string>;
    location: Nullable<string>;
    team: Nullable<string>;
    allowLoginControl: boolean;
    allowLoginUpdates: boolean;
    password: Nullable<string>;
    agentRank: Nullable<number>;
    initLoginBaseState: Nullable<string>;
    ghostRnaAction: Nullable<string>;
    enableSoftphone: Nullable<boolean>;
    altDefaultLoginDest: Nullable<string>;
    phoneLoginPin: Nullable<string>;
    manualOutboundDefaultCallerId: Nullable<string>;
    directAgentExtension: Nullable<string>;
    maxChats: Nullable<number>;
}

export interface IRcUser {
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    company: Nullable<string>;
    jobTitle: Nullable<string>;
    email: Nullable<string>;
    businessPhone: Nullable<string>;
    mobilePhone: Nullable<string>;
    emailAsLoginName: Nullable<boolean>;
    department: Nullable<string>;
}

export enum StorageKeys {
    ACCESS_TOKEN = 'accessToken',
    REFRESH_TOKEN = 'refreshToken',
    DIGITAL_TOKEN = 'digitalToken',
    TOKEN_TYPE = 'tokenType',
    USER_DETAILS = 'userDetails',
    FULL_USER_DETAILS = 'fullUserDetails',
    RC_ACCESS_TOKEN = 'rcAccessToken',
    RC_REFRESH_TOKEN = 'rcRefreshToken',
    RC_DETAILS = 'rcDetails',
    RC_BRAND_INFO = 'rcBrandInfo',
    SHARED_DASHBOARD_TOKEN = 'sharedDashboardToken',
}

export type StorageKeyValue =
    | 'accessToken'
    | 'refreshToken'
    | 'digitalToken'
    | 'tokenType'
    | 'userDetails'
    | 'fullUserDetails'
    | 'rcAccessToken'
    | 'rcRefreshToken'
    | 'rcDetails'
    | 'rcBrandInfo'
    | 'sharedDashboardToken';

export type AppPermissions = {
    Agent: boolean;
    Admin: boolean;
    Analytics: boolean;
    WEMEnterprise: boolean;
    RingSense: boolean;
};

export interface IRCBrandInfo {
    id: string;
    name: string;
}
