import type { FC } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AdminIcon } from './assets/admin.svg';
import type { AdminAppButtonType } from './types';
import { AppButton } from '../AppButton';

export const AdminAppButton: FC<AdminAppButtonType> = ({
    adminUrl,
    stateManagement,
}) => {
    const { t } = useTranslation();
    return (
        <AppButton
            href={adminUrl}
            name={t('APPS.ADMIN')}
            stateManagement={stateManagement}
        >
            <AdminIcon />
        </AppButton>
    );
};

export default memo(AdminAppButton);
