export const AppSwitcherIcons = {
    AGENT: 'agent',
    ANALYTICS: 'analytics',
    ADMIN: 'admin',
    WEM_ENTERPRISE: 'wemEnterprise',
    RING_SENSE: 'ringSense',
};

export enum defaultLabels {
    AGENT = 'Agent',
    ANALYTICS = 'Analytics',
    ADMIN = 'Admin',
    WEM = 'WEM',
    AI = 'RingSense',
}
