import type { FC } from 'react';

import type { IAppIcon } from './types/AppIcon';

export const AppSwitcherAdmin: FC<IAppIcon> = () => {
    const defId = 'eui-AppSwitcherAdmin-' + Date.now() + Math.random();

    return (
        <svg width='48' height='48' xmlns='http://www.w3.org/2000/svg'>
            <defs>
                <linearGradient
                    x1='0%'
                    y1='3.31%'
                    x2='100%'
                    y2='96.69%'
                    id={defId}
                >
                    <stop stopColor='#066FAC' offset='0%' />
                    <stop stopColor='#509AC4' offset='100%' />
                </linearGradient>
                <path id='a' d='M0 0h90v90H0z' />
            </defs>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M28.4536 10.15l.5029.1904c.115.0433.256.096.8632.3276l.0462.0156.0362.013 1.411.5381c1.4295.5449 1.0198 1.7748.6904 2.7634-.3047.9108-.2277 1.3961-.0387 1.7066.2746.2435.5365.503.785.7762.3264.2125.8423.3265 1.8904-.0002 1.0688-.328 2.374-.7284 2.9756.6051l.5783 1.295a.8278.8278 0 01.0282.0571l.0245.0559c.322.723.322.723.3977.8913l.1668.3696c.5932 1.3339-.6094 1.9346-1.5756 2.4168l-.0325.0164c-1.1073.5445-1.3172 1.0208-1.3444 1.4202l-.003.0696a.9108.9108 0 01-.0083.0944c.004.161.0039.3227.0001.485.0178.4053.2018.8916 1.353 1.4353.9975.4747 2.2188 1.0564 1.6363 2.4052l-.5759 1.3289a.5095.5095 0 01-.014.034l-.5798 1.323c-.5857 1.3485-1.8944.9699-2.946.6651-1.0708-.3105-1.5907-.1887-1.9176.0282a10.483 10.483 0 01-.8224.8235c-.1654.3124-.2148.7897.0793 1.633l.0802.2335c.3197.9471.5818 2.0362-.744 2.5605l-.5133.2047c-.1134.0458-.2678.1079-.8613.344a.9056.9056 0 01-.0943.0367l-1.373.5476c-.2445.0968-.4655.144-.667.151-.9617.0335-1.4695-.8456-1.8886-1.5955L26 31.6888c3.656-.3707 6.534-3.6569 6.534-7.6797 0-4.0229-2.878-7.309-6.534-7.6797l-.0003-4.8483c.4921-.8893 1.125-1.8345 2.4539-1.3311zM22 10c.5523 0 1 .4477 1 1v26c0 .5523-.4477 1-1 1h-3c-.5523 0-1-.4477-1-1V11c0-.5523.4477-1 1-1h3zm-8 8c.5523 0 1 .4477 1 1v10c0 .5523-.4477 1-1 1h-3c-.5523 0-1-.4477-1-1V19c0-.5523.4477-1 1-1h3z'
                    fill={`url(#${defId})`}
                />
            </g>
        </svg>
    );
};
