import type { IPromiseCacheType, PromiseCacheValue } from './types';

const promiseCache: Record<string, Promise<unknown>> = {};

const keys: Readonly<IPromiseCacheType> = Object.freeze({
    LOGIN: 'login',
    FETCH_TOKEN_FROM_AUTH_CODE: 'fetchTokenFromAuthCode',
    VALIDATE_TOKEN: 'validateToken',
    REFRESH_TOKEN: 'refreshToken',
    FULL_USER_DETAILS: 'fullUserDetails',
    PERMISSIONS: 'permissions',
    REFRESH_RC_TOKEN: 'refreshRcToken',
    BRAND_INFO: 'brandInfo',
});

/**
 * @ignore
 *
 * Service used to manage multiple requests for one promise. Promises are cached by string values. When a promise has been either resolved or rejected, it is removed from the cache.
 */
export class PromiseCacheService {
    static get KEYS(): IPromiseCacheType {
        return keys;
    }

    /**
     * @ignore
     *
     * Checks to see if a promise is currently stored in the provided key value. If not, invokes the promiseFactory
     * function and stores a reference to the returned promise in its cache.
     *
     * @param {string} key - string used to look up promise that could be created by the promiseFactory.
     * @param {function} promiseFactory - Callable function that returns a promise.
     */
    static setIfEmpty(
        key: PromiseCacheValue,
        promiseFactory: () => Promise<unknown>
    ): void {
        if (typeof key !== 'string' || typeof promiseFactory !== 'function') {
            return;
        }

        if (!promiseCache[key]) {
            const promise = promiseFactory();

            if (
                promise &&
                typeof promise === 'object' &&
                typeof promise.then === 'function'
            ) {
                promiseCache[key] = promise
                    .then((resp) => resp)
                    .finally(() => {
                        delete promiseCache[key];
                    });
            }
        }
    }

    static get<T = string>(key: PromiseCacheValue): Promise<T> {
        return promiseCache[key] as Promise<T>;
    }
}
