import type { FC } from 'react';

import type { IUserItems } from './types/UserItems';
import { isMenuItemComponent, isMenuItemLink } from './types/UserItems';
import { StyledUserMenu, StyledUserMenuItem } from './UserItems.styled';

const UserItems: FC<IUserItems> = ({ items }) => (
    <StyledUserMenu>
        {items.map((item, index) => {
            return (
                <StyledUserMenuItem key={`__${index}`}>
                    <>
                        {isMenuItemLink(item) && (
                            <a
                                href={item.to}
                                target={item.isExternal ? '_blank' : '_self'}
                                rel='noreferrer noopener'
                                onClick={() => {
                                    item.onClick && item.onClick();
                                }}
                            >
                                {item.title}
                            </a>
                        )}
                        {isMenuItemComponent(item) && item.component}
                    </>
                </StyledUserMenuItem>
            );
        })}
    </StyledUserMenu>
);

export default UserItems;
