type ConsoleFunction = 'log' | 'info' | 'warn' | 'error';

type ConsoleHook = (
    type: ConsoleFunction,
    args: unknown[],
    originalConsole: Console
) => void;

export class ConsoleHooks {
    private static instance: ConsoleHooks;
    private readonly originalConsole: Console;
    private readonly callbacks: Set<ConsoleHook> = new Set();
    private callbackDepth = 0;

    private constructor() {
        this.originalConsole = { ...window.console };
        this.attachConsoleInterceptor();
    }

    public static getInstance() {
        if (!ConsoleHooks.instance) {
            ConsoleHooks.instance = new ConsoleHooks();
        }
        return ConsoleHooks.instance;
    }

    private attachConsoleInterceptor() {
        const consoleFunctions: ConsoleFunction[] = [
            'log',
            'info',
            'warn',
            'error',
        ];

        consoleFunctions.forEach((type) => {
            window.console[type] = (...args: unknown[]) => {
                this.originalConsole[type](...args);

                if (this.callbackDepth++ === 0) {
                    this.callbacks.forEach((callback) => {
                        callback(type, args, this.originalConsole);
                    });
                }
                this.callbackDepth--;
            };
        });
    }

    public addConsoleHook(hook: ConsoleHook) {
        this.callbacks.add(hook);
    }

    public removeConsoleHook(hook: ConsoleHook) {
        this.callbacks.delete(hook);
    }

    public removeAllConsoleHooks() {
        this.callbacks.clear();
    }
}
