import type { FC } from 'react';

import type { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EyeClosed: FC<SvgIconProps> = ({
    height = 16,
    width = 16,
    ...props
}) => (
    <svg viewBox={`0 0 16 14`} height={height} width={width} {...props}>
        <g
            id='Symbols'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
        >
            <g
                id='Icon-/-16-px-/-Eye-Close'
                transform='translate(0.000000, -1.000000)'
            >
                <g id='Group' transform='translate(0.000000, -0.000000)'>
                    <rect id='Rectangle' x='0' y='0' width='16' height='16' />
                    <path
                        d='M13.7287352,5.34275947 C14.4891748,6.0958051 15.096111,6.99787798 15.5,8 C14.3204545,10.9266667 11.4090909,13 8,13 C7.40090241,13 6.81717607,12.9359695 6.25551867,12.8145296 L7.74596847,11.3242045 C7.82984151,11.3302546 7.91455528,11.3333333 8,11.3333333 C9.88181818,11.3333333 11.4090909,9.84 11.4090909,8 C11.4090909,7.89122652 11.4037535,7.78366454 11.3933211,7.67755094 Z M8,3 C8.65746753,3 9.29642266,3.07711531 9.90801311,3.22259473 L8.43579024,4.69368684 C8.29308652,4.67585795 8.14763435,4.66666667 8,4.66666667 C6.11818182,4.66666667 4.59090909,6.16 4.59090909,8 C4.59090909,8.17013936 4.60396751,8.33731467 4.62915718,8.50061936 L2.3737792,10.7569667 C1.56529137,9.98434942 0.921877397,9.0467545 0.5,8 C1.67954545,5.07333333 4.59090909,3 8,3 Z'
                        id='strokedEye'
                        fill='#A1A1A1'
                    />
                    <rect
                        id='eyeClosed'
                        fill='#A1A1A1'
                        transform='translate(7.700000, 8.000000) rotate(-315.000000) translate(-7.700000, -8.000000) '
                        x='7'
                        y='1.13686838e-13'
                        width='1.4'
                        height='16'
                    />
                </g>
            </g>
        </g>
    </svg>
);
