import type { FC } from 'react';

import type { IAppIcon } from './types/AppIcon';

export const AppSwitcherAgent: FC<IAppIcon> = () => {
    const defId = 'eui-AppSwitcherAgent-' + Date.now() + Math.random();

    return (
        <svg width='48' height='48' xmlns='http://www.w3.org/2000/svg'>
            <defs>
                <linearGradient x1='0%' y1='0%' x2='100%' y2='100%' id={defId}>
                    <stop stopColor='#066FAC' offset='0%' />
                    <stop stopColor='#509AC4' offset='100%' />
                </linearGradient>
                <path id='a' d='M0 0h90v90H0z' />
            </defs>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M33.6605 20.0863c1.0921-.0023 1.998.8446 2.069 1.9345 1.0551.3103 2.1724.7034 2.4724 1.3448.1724.9727.2418 1.961.2069 2.9483a14.1207 14.1207 0 01-.207 2.9379c-.3.6517-1.4379 1.0759-2.4723 1.3552a2.069 2.069 0 01-1.738 1.9138c-.8682 2.955-3.4679 5.0694-6.538 5.3172a2.069 2.069 0 01-1.9137 1.4586h-3.4759c-1.1336-.1708-1.972-1.1449-1.972-2.2913 0-1.1465.8384-2.1206 1.972-2.2914h3.4759a1.9655 1.9655 0 011.738 1.1896 5.6069 5.6069 0 004.6861-3.4551 2.069 2.069 0 01-1.6138-2.0173v-8.2758c0-1.1427.9264-2.069 2.069-2.069zm-18.6207 0c1.1427 0 2.069.9263 2.069 2.069v8.2758c0 1.1427-.9263 2.069-2.069 2.069h-1.2931c-1.0921.0023-1.998-.8447-2.069-1.9345-1.0345-.2793-2.1724-.7035-2.4724-1.3552A14.1207 14.1207 0 019 26.7987l.0088-.4848a14.0483 14.0483 0 01.2069-2.9483c.3-.6414 1.4172-1.0345 2.4724-1.3448.071-1.0899.9768-1.9368 2.069-1.9345h1.2827zM23.7243 9c5.171 0 9.7136 3.4325 11.1258 8.407a.5172.5172 0 01-.5069.6518h-3.5068a1.0346 1.0346 0 01-.9518-.6104 6.9 6.9 0 00-12.3207 0 1.0346 1.0346 0 01-.9413.6104h-3.5173a.5174.5174 0 01-.5069-.6518C14.0106 12.4325 18.5533 9 23.7243 9z'
                    fill={`url(#${defId})`}
                />
            </g>
        </svg>
    );
};
