import type { FC } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AppIcon } from './assets/wem_enterprise.svg';
import type { WEMEnterpriseAppButtonType } from './types';
import { AppButton } from '../AppButton';

export const WEMEnterpriseAppButton: FC<WEMEnterpriseAppButtonType> = ({
    wemUrl,
}) => {
    const { t } = useTranslation();
    return (
        <AppButton href={wemUrl} name={t('APPS.WEM_ENTERPRISE')} openInNewTab>
            <AppIcon />
        </AppButton>
    );
};

export default memo(WEMEnterpriseAppButton);
