import { piiInterceptor } from '@ringcx/pii-interceptor';
import get from 'lodash/get';

import type { AdditionalFields, LogRecord, RangeCriteria } from '../types';

export function promisifyIDBRequest<T>(request: IDBRequest<T>) {
    return new Promise<T>((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

export function sanitizeObject(obj: unknown) {
    if (typeof obj === 'function') {
        return obj.toString();
    } else if (typeof obj === 'object') {
        try {
            return piiInterceptor.sanitizeToString(obj);
        } catch (error) {
            return '';
        }
    }

    return obj;
}

export function buildLogRecord<T extends LogRecord = LogRecord>(
    args: unknown[],
    extras: AdditionalFields<T, LogRecord>
) {
    const message = args.map(sanitizeObject).join(' ');

    return {
        ...extras,
        dts: new Date(),
        message,
    };
}

export function createDateRangeQuery(
    startDate: Date | undefined,
    endDate: Date | undefined
): IDBKeyRange {
    if (startDate && endDate) {
        return IDBKeyRange.bound(startDate, endDate);
    }

    if (startDate) {
        return IDBKeyRange.lowerBound(startDate);
    }

    if (endDate) {
        return IDBKeyRange.upperBound(endDate);
    }

    return {
        lower: undefined,
        upper: undefined,
        lowerOpen: false,
        upperOpen: false,
        includes: () => true,
    };
}

export function createRangesPredicate<T>(...ranges: RangeCriteria<T>[]) {
    return (record: T) => {
        return ranges.every(({ keyPath, range }) => {
            const value = Array.isArray(keyPath)
                ? keyPath.reduce((acc: unknown[], key) => {
                      acc.push(get(record, key));
                      return acc;
                  }, [])
                : get(record, keyPath);

            return range.includes(value);
        });
    };
}
