import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Session, AnalyticsCookies } from '@ringcx/shared';
import { Logo } from '@ringcx/ui';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
    LandingPageBody,
    LandingPageContent,
    LandingPageImgContainer,
    LandingPageEntrance,
    WelcomeView,
    EntranceButtons,
    WelcomeViewLogo,
    WelcomeHeadLine,
    WelcomeBody,
    LeftImage,
    SignInButton,
    ExternalLinkButton,
    EntranceFooter,
    LoadingDialog,
} from './LandingPage.styled';
import { AlternativeSignIn } from '../../components/AlternativeSignIn';
import type { IPlatform } from '../../components/AlternativeSignIn/type';
import { LoadingPlaceholder } from '../../components/LoadingPlaceholder';
import { RESPONSIVE_BREAKPOINT } from '../../constants/breakpoints';
import { SSO_LOGIN } from '../../constants/paths';
import axiosErrorParser from '../../helpers/AxiosErrorParser';
import { createExternalURL } from '../../helpers/createExternalURL';
import { getPlatforms } from '../../helpers/getPlatform';
import { AnalyticsSvc } from '../../services/analytics';
import { getUserLocale } from '../../services/translate/translate.helpers';
import { DEFAULT_LOGO } from '../Header/constants';
declare const NODE_ENV: string;

const LandingPage: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [platformItems, setPlatformItems] = useState<IPlatform[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isCRMScreen, setIsCRMScreen] = useState(
        window.innerWidth <= RESPONSIVE_BREAKPOINT
    );

    useEffect(() => {
        const { regionalSettings } = Session.getUserDetails();
        const defaultLocale = getUserLocale(regionalSettings?.language);
        AnalyticsSvc.register({
            appName: window.__settings.rcxClientAppType,
            appVersion: window.__settings.versions.reviewTag,
            userAgent: navigator.userAgent,
            language: defaultLocale,
            mp_lib: isCRMScreen ? 'ringcx_int' : 'ringcx_core',
        });
        // Track non-logged-in user in mixpanel
        let distinctIdAndAliasCookie = Cookies.get(
            AnalyticsCookies.MIXPANEL_DISTINCT_ID_AND_ALIAS
        );
        if (!distinctIdAndAliasCookie) {
            const distinctId = AnalyticsSvc.getDistinctId();
            if (distinctId.length > 0) {
                const cookieData = JSON.stringify({
                    distinctId: distinctId,
                    aliasExists: false,
                });
                distinctIdAndAliasCookie = cookieData;

                const isNotDevMode = NODE_ENV !== 'development';

                // Cookie domain handling for different environments:
                // - In development: No domain specified, allowing cookies to work on localhost
                // - In staging/prod: domain='.ringcentral.com' is set
                // Without this logic, cookies would be reset on every page refresh in local development
                Cookies.set(
                    AnalyticsCookies.MIXPANEL_DISTINCT_ID_AND_ALIAS,
                    cookieData,
                    {
                        expires: 365,
                        sameSite: 'None',
                        secure: true,
                        ...(isNotDevMode && {
                            domain: '.ringcentral.com',
                        }),
                    }
                );
            }
        }
        if (distinctIdAndAliasCookie) {
            const data = JSON.parse(distinctIdAndAliasCookie);
            AnalyticsSvc.track('Viewed RCX_login_CRM_RCXwelcome Page', {
                distinct_id: data.distinctId,
            });
        }

        const handleResize = () => {
            setIsCRMScreen(window.innerWidth <= RESPONSIVE_BREAKPOINT);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleToggleDialog = () => {
        if (!isOpen && !platformItems.length) {
            loadPlatformDetails();
        } else {
            setIsOpen((oldIsOpen) => !oldIsOpen);
        }
    };

    const handleCallbackUri = (callbackUri: string) => {
        if (callbackUri) {
            window.location.assign(callbackUri);
        }
    };

    const loadPlatformDetails = () => {
        setIsLoading(true);
        getPlatforms()
            .then((response) => {
                setPlatformItems(response.data);
                setIsOpen(true);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                const errorMessage = t('LANDING.ERROR', {
                    error: axiosErrorParser(error),
                });

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
            });
    };

    const ssoLoginOnclick = () => {
        let oauthRedirect = createExternalURL(SSO_LOGIN);

        oauthRedirect += `?ui_locales=${getUserLocale()}&redirectUrl=${encodeURIComponent(
            window.location.href
        )}`;

        AnalyticsSvc.track('RCX_login_CRM_RCXsignin');
        // on iFrame, login page should be in popup window
        if (window.self !== window.parent) {
            const height = window.screen.height / 2;
            const width = window.screen.width / 2;
            const left = (window.screen.width - width) / 2;
            const top = (window.screen.height - height) / 2;

            window.handleCallbackUri = handleCallbackUri;

            window.open(
                oauthRedirect,
                'rcx-oauth',
                `height=${height},width=${width},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no`
            );
        } else {
            window.location.assign(oauthRedirect);
        }
    };

    return (
        <>
            {isLoading && (
                <LoadingDialog>
                    <LoadingPlaceholder />
                </LoadingDialog>
            )}
            <LandingPageBody>
                <LandingPageContent>
                    <LandingPageImgContainer>
                        <LeftImage />
                    </LandingPageImgContainer>

                    <LandingPageEntrance>
                        <WelcomeView>
                            <WelcomeViewLogo>
                                <Logo defaultLogo={DEFAULT_LOGO} />
                            </WelcomeViewLogo>
                            <WelcomeHeadLine>
                                {t('GENERICS.WELCOME', { sign: '' })}
                            </WelcomeHeadLine>
                            <WelcomeBody>
                                {isCRMScreen
                                    ? t('LANDING.CRM_DESC')
                                    : t('LANDING.DESC')}
                            </WelcomeBody>
                        </WelcomeView>
                        <EntranceButtons>
                            <SignInButton onClick={ssoLoginOnclick}>
                                {t('GENERICS.SIGN_IN')}
                            </SignInButton>
                            <EntranceFooter>
                                {t('LANDING.NOT_A_RC')}
                            </EntranceFooter>
                            <ExternalLinkButton onClick={handleToggleDialog}>
                                {t('LANDING.USE_ALT')}
                            </ExternalLinkButton>
                            <AlternativeSignIn
                                isOpen={isOpen}
                                platformItems={platformItems}
                                handleToggleDialog={handleToggleDialog}
                            />
                        </EntranceButtons>
                    </LandingPageEntrance>
                </LandingPageContent>
            </LandingPageBody>
        </>
    );
};
export default LandingPage;
