import { memo, useMemo } from 'react';

import {
    DisabledOptionVariant,
    DisabledText,
    ListItemDotVariant,
    ListItemIconWrapper,
    StyledDigitalFontIcon,
    StyledListItem,
} from './ListItem.styled';
import type { IListItem } from './types';
import { Dot } from '../../../Dot';
import {
    HyperlinkListItemWrapper,
    HyperlinkTextEclipse,
    HyperlinkIconStyled,
} from '../../../Hyperlink';
import { TagComponent } from '../../../Tag';
import { TextEclipse } from '../../../TextEclipse';
import Tooltip from '../../../Tooltip/Tooltip';
import {
    isDotVariantGuard,
    isTagVariantGuard,
    isHyperlinkVariantGuard,
    isDisabledOptionVariantGuard,
} from '../../types';

const ListItem = ({
    item,
    index,
    handleMenuItemSelect,
    keyboardPositionIndex,
    isSelected,
    ...restProps
}: IListItem) => {
    const listItemClass: string =
        keyboardPositionIndex === index ? 'selected-item' : '';
    const startWithSelected = isSelected && keyboardPositionIndex === null;
    const highlightKeyboardPosition =
        isSelected && index === keyboardPositionIndex;
    const selected =
        startWithSelected || highlightKeyboardPosition ? 'selected-item' : '';

    const { displayName, variant, icon } = item;

    const renderListItemVariant = useMemo(() => {
        if (isTagVariantGuard(variant)) {
            return (
                <TagComponent
                    text={displayName}
                    color={variant.color}
                    onClick={() => handleMenuItemSelect(item, index)}
                />
            );
        }

        if (isDotVariantGuard(variant)) {
            return (
                <ListItemDotVariant>
                    <Dot color={variant.color} />
                    <TextEclipse tooltipMsg={displayName}>
                        {displayName}
                    </TextEclipse>
                </ListItemDotVariant>
            );
        }

        if (isHyperlinkVariantGuard(variant)) {
            return (
                <HyperlinkListItemWrapper>
                    <HyperlinkTextEclipse tooltipMsg={displayName}>
                        {displayName}
                    </HyperlinkTextEclipse>
                    <Tooltip title={variant.tooltipMsg}>
                        <div
                            className='icon-wrapper'
                            data-aid={`${variant.dataAid}_${index + 1}`}
                            onClick={(e) => {
                                variant.onClick();
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <HyperlinkIconStyled />
                        </div>
                    </Tooltip>
                </HyperlinkListItemWrapper>
            );
        }

        if (isDisabledOptionVariantGuard(variant)) {
            return (
                <DisabledOptionVariant>
                    <TextEclipse tooltipMsg={displayName}>
                        {displayName}
                    </TextEclipse>
                    <DisabledText>{variant.disabledText}</DisabledText>
                </DisabledOptionVariant>
            );
        }

        if (icon) {
            return (
                <ListItemIconWrapper>
                    <StyledDigitalFontIcon code={icon} />
                    <TextEclipse tooltipMsg={displayName}>
                        {displayName}
                    </TextEclipse>
                </ListItemIconWrapper>
            );
        }

        return (
            <TextEclipse tooltipMsg={displayName}>{displayName}</TextEclipse>
        );
    }, [displayName, handleMenuItemSelect, icon, index, item, variant]);

    return (
        <StyledListItem
            onClick={() => handleMenuItemSelect(item, index)}
            disableRipple={true}
            className={`eui-dropdown-list-item ${listItemClass}`}
            selected={isSelected}
            classes={{ selected }}
            disabled={isDisabledOptionVariantGuard(variant)}
            {...restProps}
        >
            {renderListItemVariant}
        </StyledListItem>
    );
};

export default memo(ListItem);
