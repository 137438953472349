export const COMPONENT_NAME = 'EAC';

export const STATE_MANAGEMENT_NAME = 'stateManagement';
export const STATE_MANAGEMENT_KEY = 'engage-auth:stateManagement';

export const CRM_EVENT_TYPE = 'CRM';

// RCX <--> CRM
export const CRM_REQUESTS = {
    SET_CRM_LOCALE: 'crm_set_locale',
    GET_CRM_LOCALE: 'crm_get_locale',
};

export const KEYS = {
    OVERRIDE_LOCALE: 'override_locale',
};
