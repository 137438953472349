import { Session, Navigation, UserService } from '@ringcx/shared';

import { WEM_URI } from '../../../constants/links';
import { SELECT_AGENT } from '../../../constants/paths';
import { createExternalURL } from '../../../helpers/createExternalURL';
import type { IAgentDetails } from '../types';

export const getUserDetailsResult = async () => {
    const {
        rcUser,
        ssoLogin,
        redirectUrl,
        agentDetails,
        mainAccountId,
        regionalSettings,
        managedMainAccountIds,
    } = Session.getUserDetails();

    const permissions = await UserService.getPermissions({ force: true });

    const agentId =
        agentDetails?.length === 1 ? agentDetails[0].agentId : undefined;

    const agentUrl = await Navigation.getAgentUrl(agentId);
    const adminUrl = Navigation.getAdminUrl();
    const analyticsUrl = Navigation.getAnalyticsUrl();
    const ringSenseUrl = await Navigation.getRingSenseUrl(
        Session.isEmbeddedAuthRingSenseClientAppType()
    );

    await Navigation.setWEMUrl?.();
    const wemUrl = Navigation.getWEMUrl?.() || WEM_URI;

    return {
        rcUser,
        regionalSettings,
        ssoLogin,
        permissions,
        ringSenseUrl,
        adminUrl: createExternalURL(adminUrl),
        analyticsUrl: createExternalURL(analyticsUrl),
        redirectUrl: redirectUrl || '',
        // TODO replace after update/sync all user details types
        agentDetails: (agentDetails || []) as IAgentDetails[],
        sessionType: Session.getSessionType(),
        mainAccountId:
            mainAccountId ||
            (managedMainAccountIds?.length ? managedMainAccountIds[0] : ''),
        agentUrl:
            agentId || UserService.isVodafoneBrand()
                ? createExternalURL(agentUrl)
                : SELECT_AGENT,
        wemUrl,
    };
};
