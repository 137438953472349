import type { FC, MouseEvent as MouseEventReact } from 'react';
import { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Form, SubmittedMessage } from './components';
import { LEGACY_LOGIN } from '../../constants/paths';
import { useQuery } from '../../hooks';
import PageLayout from '../../layouts/PageLayout';
import {
    Card,
    CardHeader,
    CardContent,
    TextMessages,
    ForgotPasswordLinkContainer,
    ForgotPasswordLink,
} from '../Login/Login.styled';

const ForgotPassword: FC = () => {
    const { app } = useParams<{ app: string }>();
    const platformId = useQuery('plat');

    const [email, setEmail] = useState('');
    const [submitComplete, setSubmitComplete] = useState(false);

    const cardContent = useMemo(
        () =>
            submitComplete ? (
                <SubmittedMessage
                    {...{
                        email,
                        onBack: (
                            event: MouseEventReact<
                                HTMLAnchorElement,
                                MouseEvent
                            >
                        ) => {
                            event.preventDefault();
                            setEmail('');
                            setSubmitComplete(false);
                        },
                    }}
                />
            ) : (
                <Form
                    {...{
                        app,
                        platformId,
                        onSubmitComplete: ({ email: eventEmail }) => {
                            setEmail(eventEmail);
                            setSubmitComplete(true);
                        },
                    }}
                />
            ),
        [app, submitComplete, email, platformId]
    );

    return (
        <PageLayout>
            <Card elevation={0}>
                <CardHeader>Password recovery</CardHeader>
                <CardContent>
                    {!submitComplete && (
                        <TextMessages>
                            Enter your email address and we will send you a link
                            to reset your password.
                        </TextMessages>
                    )}
                </CardContent>
                <CardContent>{cardContent}</CardContent>
                <ForgotPasswordLinkContainer>
                    <ForgotPasswordLink
                        to={`${LEGACY_LOGIN}${app}?plat=${platformId}`}
                    >
                        Sign in
                    </ForgotPasswordLink>
                </ForgotPasswordLinkContainer>
            </Card>
        </PageLayout>
    );
};

export default ForgotPassword;
