import type { FC, PropsWithChildren } from 'react';

import {
    StyledIconWrapper,
    StyledLabelWrapper,
    StyledLinkButton,
} from './LinkButton.styled';
import type { LinkButtonType } from './types/LinkButton';
import { EMPTY_CALLBACK } from '../../helpers/usage';

const LinkButton: FC<PropsWithChildren<LinkButtonType>> = ({
    underline = 'none',
    title,
    children,
    icon = null,
    color = 'primary',
    iconPosition = 'left',
    disabled = false,
    onClick = EMPTY_CALLBACK,
    ...restProps
}) => (
    <StyledLinkButton
        {...{
            underline,
            color,
            disabled,
            onClick,
            ...restProps,
        }}
    >
        {icon && iconPosition === 'left' && (
            <StyledIconWrapper iconPosition={iconPosition} disabled={disabled}>
                {icon}
            </StyledIconWrapper>
        )}
        <StyledLabelWrapper>{title || children}</StyledLabelWrapper>
        {icon && iconPosition === 'right' && (
            <StyledIconWrapper iconPosition={iconPosition} disabled={disabled}>
                {icon}
            </StyledIconWrapper>
        )}
    </StyledLinkButton>
);

export default LinkButton;
