import { WEM_URI, CURRENT_ACCOUNT_KEY, WEM_URI_KEY } from './constants';
import { getURLParam } from '../../../helpers/getURLParam';
import { FfsService, VODAFONE_BRAND_REDIRECT_FLAG } from '../../featureFlags';
import {
    VODAFONE_CLIENT_ID,
    VODAFONE_AGENT_APP_URL,
} from '../services/constants';
import { HttpService } from '../services/http.service';
import StorageService from '../services/storage.service';
import type { IUserDetails } from '../services/types';
import { UserService } from '../services/user.service';
import {
    RCX_CLIENT_APP_TYPE,
    Session,
    SessionType,
    INIT_ROUTE_PATH,
} from '../Session';
import type { IOptions, IQueryParams } from '../types';
import { Utils } from '../Utils';

/**
 * The nav module is responsible for helping to consume applications navigate back to the Engage Auth login pages.
 *
 */
export class Navigation {
    static wemUrl = '';
    /**
     * Returns the url for the Engage Auth admin login page.
     *
     * @deprecated since 0.12.0. use Navigation.getLoginUrl() instead.
     * @returns {string}
     */
    static getAdminLoginUrl(): string {
        return this.getLoginUrl();
    }

    /**
     * Navigates to the Engage Auth admin login page.
     *
     */
    static navigateToAdminLogin(): void {
        window.location.href = this.getAdminLoginUrl();
    }

    /**
     * Fetches the url for navigating to the agent login page.
     *
     * @deprecated since 0.12.0. use Navigation.getLoginUrl() instead.
     * @returns {string}
     */
    static getAgentLoginUrl(): string {
        return this.getLoginUrl();
    }

    /**
     * Navigates to the Engage Auth agent login page.
     */
    static navigateToAgentLogin(): void {
        window.location.href = this.getAgentLoginUrl();
    }

    /**
     * Gets the login url based on the user's previous session.
     *
     * @param {Object} options Options argument. The following options can be provided:
     * @param {"admin" | "agent" | "analytics" | "none" | "reset_password"} options.fallbackSessionType determines the session type that will be used if we can't determine a session type.
     * @param {string} options.errorMessage specifies an error message that will be displayed to the user upon reaching the login screen
     * @returns {string}
     */
    static getLoginUrl(options: IOptions = {}): string {
        const baseLoginUrl: string = HttpService.getApiBase() + '/';
        const userDetails: IUserDetails = Session.getUserDetails();
        const queryParams: IQueryParams = {};
        let sessionType: string;
        let fallbackSessionType: string = SessionType.NONE;
        let errorMessage = '';
        let loginUrl = '';
        let queryString = '';

        if (Utils.isObject(options)) {
            fallbackSessionType =
                options.fallbackSessionType || fallbackSessionType;
            errorMessage =
                (typeof options.errorMessage === 'string' &&
                    options.errorMessage) ||
                errorMessage;
        }

        if (errorMessage.length > 0) {
            queryParams.error = errorMessage;
        }

        try {
            sessionType = Session.getSessionType();
        } catch (e) {
            sessionType = fallbackSessionType;
        }

        switch (sessionType) {
            case SessionType.ADMIN:
            case SessionType.RESET_PASSWORD:
                loginUrl = `${baseLoginUrl}login/admin`;
                queryParams.plat = userDetails && userDetails.platformId;
                break;
            case SessionType.ANALYTICS:
                loginUrl = `${baseLoginUrl}login/analytics`;
                queryParams.plat = userDetails && userDetails.platformId;
                break;
            case SessionType.AGENT:
                loginUrl = `${baseLoginUrl}login/agent`;
                queryParams.plat = userDetails && userDetails.platformId;
                break;

            default:
                loginUrl = baseLoginUrl;
        }

        const queryParamsKeys = Object.keys(queryParams) as Array<
            keyof IQueryParams
        >;

        queryString += queryParamsKeys
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        queryParams[key] || ''
                    )}`
            )
            .join('&');

        // Check url contains rcx_client_app_type param, if exists append to existing url
        if (!Session.isDefaultClientAppType()) {
            loginUrl += Session.initialUrlQueryString;
        }

        const separator = loginUrl.includes('?') ? '&' : '?';

        return (
            loginUrl +
            (queryString.length > 0 ? `${separator}${queryString}` : '')
        );
    }

    /**
     * Fetches the url for navigating to agent-js.
     *
     * @param {number} agentId - the agentID that the user will use to start their Agent session.
     * @returns {string}
     */
    static async getAgentUrl(agentId?: number): Promise<string> {
        const { agentUrl } = StorageService.getUserDetails();

        const rcxClientType =
            Session.isCRMClientAppType() ||
            Session.isEmbeddedAgentClientAppType()
                ? Session.rcxClientAppType
                : '';

        if (
            UserService.isVodafoneBrand() &&
            !Session.isEmbeddedAgentClientAppType()
        ) {
            const { flags } = await FfsService.instance().getFeatureFlag({
                storeFfsDomain: true,
                discoveryParams: { isExternal: false },
                ffsParams: { body: { flags: [VODAFONE_BRAND_REDIRECT_FLAG] } },
            });

            const shouldRedirectToVodafoneAgentApp =
                flags[VODAFONE_BRAND_REDIRECT_FLAG];

            if (shouldRedirectToVodafoneAgentApp) {
                return agentUrl || '';
            }
        }

        if (!agentId) {
            return '/select/agent';
        }

        return typeof agentUrl === 'string' && agentUrl.length > 0
            ? `${agentUrl}?agentId=${agentId}${
                  rcxClientType
                      ? `&${RCX_CLIENT_APP_TYPE}=${rcxClientType}`
                      : ''
              }`
            : '';
    }

    /**
     * Fetches the url for navigating to the Vodafone Agent App
     *
     * @returns {string}
     */
    static async getVodafoneAgentUrl(): Promise<string> {
        const code = await Session.getRCAuthCode(VODAFONE_CLIENT_ID);
        return `${VODAFONE_AGENT_APP_URL}?code=${code}`;
    }

    /**
     * Fetches the url for navigating to AdminJS
     *
     * @returns {string}
     */
    static getAdminUrl(): string {
        const { adminUrl } = StorageService.getUserDetails();
        const initialRoute = getURLParam(INIT_ROUTE_PATH) || '';
        const rcxClientType =
            Session.isCRMAdminClientAppType() ||
            Session.isEmbeddedAdminClientAppType()
                ? Session.rcxClientAppType
                : '';

        return typeof adminUrl === 'string' && adminUrl
            ? `${adminUrl}${initialRoute}${
                  rcxClientType ? `${Session.initialUrlQueryString}` : ''
              }`
            : '';
    }

    /**
     * Fetches the url for navigating to the Analytics App
     *
     * @returns {string}
     */
    static getAnalyticsUrl(): string {
        const { analyticsUrl } = StorageService.getUserDetails();

        if (!analyticsUrl || typeof analyticsUrl !== 'string') {
            return '';
        }

        const rcxClientType = Session.isEmbeddedAnalyticsClientAppType()
            ? Session.rcxClientAppType
            : '';

        return `${analyticsUrl}${
            rcxClientType ? Session.initialUrlQueryString : ''
        }`;
    }

    static async getRingSenseUrl(
        withAuthCode: boolean = false
    ): Promise<string> {
        let RING_SENSE_PATH = `oauthlogin?state=none&appType=RCXWeb`;

        if (withAuthCode) {
            try {
                const clientInfo = await Session.getRCClientId();

                const clientId =
                    clientInfo?.provisioning?.interopClientIds?.ringSense;

                const authCode = await Session.getRCAuthCode(clientId);

                RING_SENSE_PATH += `&code=${authCode}`;
            } catch (error) {
                console.error('Error getting RingSense auth code', error);
            }
        }

        if (window.location.host.includes('aws91-l08')) {
            return `https://xmrupxmn.ringsense.rclabenv.com/${RING_SENSE_PATH}`;
        }

        if (window.location.host.includes('.lab')) {
            return `https://cffe1aws.ringsense.rclabenv.com/${RING_SENSE_PATH}`;
        }

        if (
            window.location.host.includes('fra20') ||
            window.location.host.includes('aws46')
        ) {
            return `https://ringsense.ops.ringcentral.com/${RING_SENSE_PATH}`;
        }

        return `https://ringsense.ringcentral.com/${RING_SENSE_PATH}`;
    }

    static getWEMUrl(): string {
        if (this.wemUrl) {
            return this.wemUrl;
        }
        const wemUrlFromStorage = localStorage.getItem(WEM_URI_KEY);
        if (wemUrlFromStorage !== null) {
            return wemUrlFromStorage;
        }
        return WEM_URI;
    }

    static async setWEMUrl(
        accountId?: string,
        isAgent?: boolean
    ): Promise<void> {
        const updateWEM = (wemUrl: string) => {
            this.wemUrl = wemUrl;
            localStorage.setItem(WEM_URI_KEY, this.wemUrl);
        };
        const { agentDetails, rcAccountId } = await UserService.getLoggedInUser(
            {
                force: false,
            }
        );
        if (!agentDetails || agentDetails.length === 0) {
            updateWEM(WEM_URI);
            return;
        }

        const agent = agentDetails[0];
        const email = agent.email;

        if (accountId) {
            updateWEM(
                `${WEM_URI}?rc_account=${rcAccountId}&rcx_subaccount=${accountId}&user_email=${email}`
            );
            return;
        }

        const currentAccount: string | null =
            localStorage.getItem(CURRENT_ACCOUNT_KEY);

        // case for first login if agent is there
        if (!currentAccount || isAgent) {
            updateWEM(
                `${WEM_URI}?rc_account=${rcAccountId}&rcx_subaccount=${agent.accountId}&user_email=${email}`
            );
            return;
        }

        // case for handling not first login
        const selectedAccount = JSON.parse(currentAccount);
        updateWEM(
            `${WEM_URI}?rc_account=${rcAccountId}&rcx_subaccount=${selectedAccount.accountId}&user_email=${email}`
        );
    }
}
