import type { FC } from 'react';
import { useState, useMemo } from 'react';

import { SingleSelect } from '@ringcx/ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    StyledDialog,
    DialogStoryWrapper,
    Row,
    RoundButton,
    Heading,
    SignInButtonWrapper,
} from './AlternativeSignIn.styled';
import type { IApplication, IAppPickerDialog } from './type';
import { APP_TITLES } from '../../constants/appTitles';
import { ADMIN, AGENT, ANALYTICS, LEGACY_LOGIN } from '../../constants/paths';

const AlternativeSignIn: FC<IAppPickerDialog> = ({
    handleToggleDialog,
    isOpen,
    platformItems,
}: IAppPickerDialog) => {
    const { push } = useHistory();
    const [platform, setPlatform] = useState<string>('');
    const [application, setApplication] = useState<string>('');
    const [platformError, setPlatformError] = useState<boolean>(false);
    const [applicationError, setApplicationError] = useState<boolean>(false);

    const { t } = useTranslation();

    const onApplicationChange = (value: string) => {
        setApplication(value);
        setApplicationError(!value);
    };

    const onPlatformChange = (value: string) => {
        setPlatform(value);
        setPlatformError(!value);
    };

    const applicationItems: IApplication[] = useMemo(
        () => [
            {
                id: 'admin',
                displayName: APP_TITLES['ADMIN'],
                path: ADMIN,
            },
            {
                id: 'agent',
                displayName: APP_TITLES['AGENT'],
                path: AGENT,
            },
            {
                id: 'analytics',
                displayName: APP_TITLES['ANALYTICS'],
                path: ANALYTICS,
            },
        ],
        [t]
    );

    const handelOnSubmit = () => {
        if (!platform) {
            setPlatformError(true);
            return;
        }
        if (!application) {
            setApplicationError(true);
            return;
        }

        const platformDetails = platformItems.find(
            (platformItem) => platformItem.id === platform
        );

        const applicationDetails = applicationItems.find(
            (applicationItem) => applicationItem.id === application
        );

        handleToggleDialog();
        push(
            [
                LEGACY_LOGIN,
                applicationDetails?.path,
                `?plat=${platformDetails?.id}`,
            ].join('')
        );
    };

    const form = useMemo(
        () => (
            <>
                <Heading>
                    Sign in to your existing platform without RingCentral
                    credentials
                </Heading>
                <Row>
                    <SingleSelect
                        selectedItemId={platform}
                        title='Platform'
                        placeholder='Select'
                        size='small'
                        error={platformError}
                        isSearchable={false}
                        message=''
                        data={{
                            items: platformItems,
                        }}
                        onChange={onPlatformChange}
                        data-aid='platform'
                    />
                </Row>
                <Row>
                    <SingleSelect
                        selectedItemId={application}
                        title='Application'
                        placeholder='Select'
                        size='small'
                        error={applicationError}
                        message=''
                        isSearchable={false}
                        data={{
                            items: applicationItems,
                        }}
                        onChange={onApplicationChange}
                        data-aid='application'
                    />
                </Row>
            </>
        ),
        [
            t,
            platform,
            platformError,
            platformItems,
            application,
            applicationError,
            applicationItems,
        ]
    );

    return (
        <DialogStoryWrapper>
            <StyledDialog
                open={isOpen}
                onClose={handleToggleDialog}
                dialogTitle='Alternative sign in'
                content={form}
                actions={[
                    <SignInButtonWrapper key='buttonWrapper'>
                        <RoundButton key='action1' onClick={handelOnSubmit}>
                            Next
                        </RoundButton>
                    </SignInButtonWrapper>,
                ]}
                disableBackdropClick={false}
            />
        </DialogStoryWrapper>
    );
};

export default AlternativeSignIn;
