import { useCallback, type FC } from 'react';

import { Navigation, Session } from '@ringcx/shared';
import { Logo, RemoveText } from '@ringcx/ui';
import { useTranslation } from 'react-i18next';

import {
    ErrorPageLayout,
    ErrorPageLogo,
    ErrorPageContent,
    ErrorPageIcon,
    ErrorPageLine,
    LogoutButton,
} from './ErrorPage.styled';
import PageLayout from '../../layouts/PageLayout';
import { DEFAULT_LOGO } from '../Header/constants';

const ErrorPage: FC<{ isCRMAdminClient?: boolean }> = ({
    isCRMAdminClient = false,
}) => {
    const { t } = useTranslation();
    const onClickLogout = useCallback(() => {
        Session.logout().finally(() => {
            const loginUrl = Navigation.getLoginUrl();
            Session.clearSession();
            window.location.assign(loginUrl);
        });
    }, []);

    return (
        <PageLayout>
            <ErrorPageLayout>
                <ErrorPageLogo>
                    <Logo defaultLogo={DEFAULT_LOGO} />
                </ErrorPageLogo>
                <ErrorPageContent>
                    <ErrorPageIcon>
                        <RemoveText />
                    </ErrorPageIcon>
                    <ErrorPageLine>
                        {isCRMAdminClient
                            ? t('ERRORS.ERROR_PAGE_NO_ADMIN')
                            : t('ERRORS.ERROR_PAGE')}
                    </ErrorPageLine>
                </ErrorPageContent>
                <LogoutButton onClick={onClickLogout}>
                    {t('GENERICS.SIGN_OUT')}
                </LogoutButton>
            </ErrorPageLayout>
        </PageLayout>
    );
};

export default ErrorPage;
