import { TopPanel } from '@ringcx/ui';
import styled from 'styled-components';

export const StyledTopPanel = styled(TopPanel)`
    flex-shrink: 0;
    flex-grow: 0;
`;

export const LogoutMenuItem = styled.button`
    && {
        color: ${({ theme }) => theme.colors.primary};
        &:active,
        &:hover {
            background-color: ${({ theme }) => theme.colors.gray[0]};
        }
    }
`;
