import type { ForwardedRef, SyntheticEvent } from 'react';
import { useCallback, useEffect, useState, forwardRef } from 'react';

import { Autocomplete as AutocompleteUI, Box } from '@mui/material';
import type { PopperProps } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
    AutoCompleteWrapper,
    ListGroupHeading,
    StyledPopper,
    StyledTextField,
    StyledTooltip,
} from './Autocomplete.styled';
import type { IAutocompleteProps, IOption } from './types';
import { TEST_AID } from '../../../constants';
import { Arrow } from '../../../icons/Arrow';
import { CloseSvg } from '../../../icons/CloseSvg';
import { Filter } from '../../../icons/Filter';
import { themeV4toV5 } from '../../../theme/theme';
import Spinner from '../../Spinner';
import { TextEclipse } from '../../TextEclipse';
import { TooltipAdornment } from '../Input.styled';
import { VisuallyHiddenLabel } from '../VisuallyHiddenLabel';

/**
 *@deprecated
 * DO NOT USE THIS DIRECTLY, use renderAutocompleteControl helper instead
 */
const Autocomplete = forwardRef<HTMLInputElement, IAutocompleteProps<unknown>>(
    <T,>(
        {
            label,
            tooltipIcon,
            data,
            value,
            onChange,
            onOpen,
            filterOptions,
            groupBy,
            message,
            error,
            placeholder,
            loading = false,
            disableClearable = true,
            required,
            noOptionsText,
            renderOption,
            multiple,
            getInputProps,
            blurOnSelect = true,
            inputClass,
            showFilterIcon,
            inputId = '',
            ariaLabelText = '',
            ...restProps
        }: IAutocompleteProps<T>,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const { t } = useTranslation();
        const [localValue, setLocalValue] =
            useState<Nullable<IOption<T> | IOption<T>[]>>();
        const NO_OPTION_TEXT = t('GENERICS.LABELS.NO_OPTIONS_TEXT');

        useEffect(() => {
            if (data && restProps.defaultValue === undefined) {
                if (multiple) {
                    const selectedValue = data.filter(
                        ({ id }) => value && value.includes(id)
                    );
                    setLocalValue(selectedValue || null);
                } else {
                    const selectedValue = data.find(({ id }) => id === value);
                    setLocalValue(selectedValue || null);
                }
            }
        }, [value, data, multiple, restProps.defaultValue]);

        const handleChange = useCallback(
            (
                _: SyntheticEvent,
                newValue:
                    | NonNullable<string | IOption<T>>
                    | (string | IOption<T>)[]
                    | null
            ) => {
                if (typeof newValue === 'string') {
                    onChange(newValue);
                    return;
                }
                if (Array.isArray(newValue)) {
                    onChange(
                        newValue.map((item) =>
                            typeof item === 'string' ? item : item.id
                        )
                    );
                    return;
                }

                onChange(newValue?.id);
            },
            [onChange]
        );

        const checkOptionEqualToValue = (
            option: IOption<T>,
            value: IOption<T>
        ) => option.id === value.id;
        const [isOpen, setIsOpen] = useState(false);

        const openCallback = useCallback(() => {
            setIsOpen(true);
            onOpen?.();
        }, [onOpen]);
        const closeCallback = useCallback(() => setIsOpen(false), []);

        const renderPopperComponent = useCallback((props: PopperProps) => {
            const modifiers = [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: [],
                    },
                },
            ];

            return (
                <StyledPopper
                    {...props}
                    modifiers={modifiers}
                    popperOptions={{
                        placement: 'bottom',
                    }}
                />
            );
        }, []);

        const renderAutocomplete = () => (
            <>
                <AutocompleteUI
                    {...restProps}
                    multiple={multiple}
                    componentsProps={{
                        // bug with the useModal dialog component,
                        // popper shows behind the dialog because dialog z-index is set to 9999
                        popper: { sx: { zIndex: 9999 } },
                    }}
                    value={localValue}
                    options={data}
                    noOptionsText={noOptionsText || NO_OPTION_TEXT}
                    autoComplete={false}
                    onChange={handleChange}
                    groupBy={groupBy}
                    loading={loading}
                    isOptionEqualToValue={checkOptionEqualToValue}
                    disableClearable={disableClearable}
                    blurOnSelect={blurOnSelect}
                    onOpen={openCallback}
                    onClose={closeCallback}
                    PopperComponent={renderPopperComponent}
                    {...(filterOptions && {
                        filterOptions,
                    })}
                    fullWidth
                    renderOption={(props, option) =>
                        (renderOption && renderOption(props, option)) || (
                            <Box component='li' {...props} key={option.id}>
                                <TextEclipse
                                    tooltipMsg={
                                        option?.optionDisplayName ||
                                        option?.label
                                    }
                                    // bug with the useModal dialog component,
                                    // popper shows behind the dialog because dialog z-index is set to 9999
                                    popperProps={{
                                        style: { zIndex: 9999 },
                                    }}
                                >
                                    {option?.optionDisplayName || option?.label}
                                </TextEclipse>
                            </Box>
                        )
                    }
                    renderGroup={(props) => {
                        return (
                            <li key={props.key}>
                                {props.group && (
                                    <ListGroupHeading
                                        data-aid={
                                            TEST_AID.AUTOCOMPLETE.GROUP_HEADER
                                        }
                                    >
                                        {props.group}
                                    </ListGroupHeading>
                                )}
                                {props.children}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <>
                            {!label && (
                                <VisuallyHiddenLabel htmlFor={inputId}>
                                    {ariaLabelText}
                                </VisuallyHiddenLabel>
                            )}
                            <StyledTextField
                                {...params}
                                error={error}
                                helperText={message}
                                label={label}
                                $isOpen={isOpen}
                                InputLabelProps={{ shrink: true }}
                                placeholder={placeholder}
                                required={required}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {showFilterIcon && <Filter />}
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                    endAdornment: (
                                        <>
                                            {loading && (
                                                <Spinner size='small' />
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                    ...(localValue &&
                                        getInputProps &&
                                        getInputProps(localValue)),
                                    className:
                                        params.InputProps.className +
                                        (inputClass ? ` ${inputClass}` : ''),
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    id:
                                        !label && inputId
                                            ? inputId
                                            : params.inputProps.id,
                                }}
                                ref={ref}
                            />
                        </>
                    )}
                    sx={{
                        '.MuiOutlinedInput-root': {
                            gap: '4px',
                        },
                        '&.Mui-expanded .MuiOutlinedInput-root': {
                            // Increased specificity
                            border: 'none !important',
                            boxShadow:
                                'rgba(171, 171, 171, 0.5) 0px 2px 12px 0px !important',
                        },
                    }}
                    popupIcon={<Arrow />}
                    clearIcon={<CloseSvg />}
                />
                {tooltipIcon && (
                    <StyledTooltip title={tooltipIcon.message} arrow>
                        <TooltipAdornment>{tooltipIcon.icon}</TooltipAdornment>
                    </StyledTooltip>
                )}
            </>
        );

        return (
            <ThemeProvider theme={themeV4toV5}>
                <AutoCompleteWrapper>
                    {(localValue !== undefined ||
                        restProps.defaultValue !== undefined) &&
                        renderAutocomplete()}
                </AutoCompleteWrapper>
            </ThemeProvider>
        );
    }
);

export default Autocomplete;
