import type { Theme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import type { DeprecatedThemeOptions } from '@mui/material';
import { adaptV4Theme } from '@mui/material/styles';
import { Session, UserService } from '@ringcx/shared';

import border from './border';
import defaultColors, { BrandIds, brandMainColors } from './colors';
import dimensions from './dimensions';
import font from './font';
import type { ITheme } from './types/theme';
import zIndexes from './zindexes';

export const theme: ITheme = {
    colors: defaultColors,
    font,
    border,
    zIndexes,
    dimensions,
};

export const getBrandId = (): BrandIds => {
    if (Session.isEmbeddedAgentClientAppType()) {
        // Always setting Vodafone colors in UC+CC
        if (UserService.isVodafoneBrand()) {
            return BrandIds.VODAFONE;
        }

        // Setting dynamic Jupiter themes only with enabled LS flag
        // Displaying default light blue theme by default
        const isThemeEnabled = localStorage.getItem('rcx-theme-enabled');
        return isThemeEnabled ? BrandIds.JUPITER : BrandIds.DEFAULT;
    } else {
        return BrandIds.DEFAULT;
    }
};

export const getThemeByBrandId = (brandId: BrandIds) => {
    const colors = getBrandColors(brandId);
    return {
        colors,
        font: { ...font, color: colors.gray[900] },
        border,
        zIndexes,
        dimensions,
    };
};

export const getBrandColors = (brandID = BrandIds.DEFAULT) => {
    const colors = brandMainColors[brandID];
    return {
        primary: colors.mainColors[500],
        secondary: colors.firetrunkColors[500],
        main: colors.mainColors,
        firetruck: colors.firetrunkColors,
        gray: colors.grayColors,
        accent: colors.accentColors,
        semitransparent: colors.semitransparentColors,
        success: colors.accentColors.olive,
        error: colors.accentColors.firetruck,
        info: colors.grayColors[850],
        warning: colors.accentColors.orange,
        background: colors.grayColors[0],
        select: colors.mainColors[50],
        chip: colors.chipColors,
    };
};

// default material library theme colors
export const materialTheme: Theme = createMaterialTheme();

// allow overriding of theme settings
export function setTheme(newTheme: ITheme) {
    Object.assign(theme, newTheme);
    createMaterialTheme();
}

export function createMaterialTheme(brandId = BrandIds.DEFAULT): Theme {
    const colors = getBrandColors(brandId);
    return createTheme({
        palette: {
            primary: {
                ...colors.main,
                main: colors.primary,
            },
            secondary: {
                ...colors.firetruck,
                main: colors.accent.firetruck,
            },
            success: {
                main: colors.accent.emerald,
            },
            action: {
                disabled: colors.gray[300],
            },
            background: {
                paper: colors.gray[0],
                default: colors.gray[0],
            },
        },
        typography: {
            fontSize: 16,
        },
        // TODO: EVAA-16489 we should have only one place for zindexes
        zIndex: {
            modal: 9999,
        },
        overrides: {
            MuiTouchRipple: {
                rippleVisible: {
                    opacity: 0.24,
                },
            },
        },
    });
}

export const themeV4toV5 = createTheme(
    // @ts-ignore
    adaptV4Theme(theme as DeprecatedThemeOptions)
);
