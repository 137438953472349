import type { FC } from 'react';

import {
    StyledListItem,
    StyledListItemLink,
    StyledListItemTitle,
} from './ListItem.styled';
import type { ListItemType } from './types';
import { TEST_AID } from '../../../../constants';
import { Icon } from '../Icon';

const ListItem: FC<ListItemType> = ({
    name,
    path,
    icon,
    openInNewTab = false,
    onTrackAnalytics,
}) => {
    const onAppLinkClicked = () => {
        onTrackAnalytics &&
            onTrackAnalytics('RCX_app_picker_selected', {
                appName: icon,
            });
    };
    return (
        <StyledListItem data-aid={TEST_AID.APP_SWITCHER_LIST_ITEM}>
            <StyledListItemLink
                href={path}
                rel='noopener noreferrer'
                target={openInNewTab ? '_blank' : '_self'}
                onClick={onAppLinkClicked}
            >
                <Icon icon={icon} />
            </StyledListItemLink>
            <StyledListItemTitle>{name}</StyledListItemTitle>
        </StyledListItem>
    );
};

export default ListItem;
