import type { FC } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AgentIcon } from './assets/agent.svg';
import type { AgentAppButtonType } from './types';
import { AppButton } from '../AppButton';

export const AgentAppButton: FC<AgentAppButtonType> = ({
    agentUrl,
    stateManagement,
}) => {
    const { t } = useTranslation();
    return (
        <AppButton
            name={t('APPS.AGENT')}
            href={agentUrl}
            stateManagement={stateManagement}
        >
            <AgentIcon />
        </AppButton>
    );
};

export default memo(AgentAppButton);
