import type { FC, Dispatch, SetStateAction } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

export const ReportAnIssueButton: FC<{
    setOpen: Dispatch<SetStateAction<boolean>>;
}> = memo(({ setOpen }) => {
    const { t } = useTranslation();
    return (
        <a
            href='#report-an-issue'
            onClick={(e) => {
                e.preventDefault();

                setOpen(true);
            }}
        >
            {t('FEEDBACK.MENU_BUTTON')}
        </a>
    );
});
