import type { FC } from 'react';

import { ONLINE_TRAINING_LINK } from '@ringcx/shared';
import { useTranslation } from 'react-i18next';

import {
    NeedHelpContainer,
    Title,
    Text,
    Links,
    Link,
    LinkDivider,
} from './NeedHelp.styled';
import { DOCUMENTATION, TRAINING } from '../../../../constants/testId';
import { AnalyticsSvc } from '../../../../services/analytics';

export const DOCUMENTATION_URL = 'https://support.ringcentral.com/ringcx/';

const NeedHelp: FC = () => {
    const { t } = useTranslation();
    return (
        <NeedHelpContainer>
            <Title>{t('ROOT.NEED_HELP.TITLE')}</Title>
            <Text>{t('ROOT.NEED_HELP.TEXT')}</Text>
            <Links>
                <Link
                    data-aid={DOCUMENTATION}
                    href={DOCUMENTATION_URL}
                    target='_blank'
                    onClick={() =>
                        AnalyticsSvc.track('RCX_serviceWelcomePage_Action', {
                            option: 'documentation',
                        })
                    }
                >
                    {t('ROOT.NEED_HELP.LINKS.DOC')}
                </Link>
                <LinkDivider />
                <Link
                    data-aid={TRAINING}
                    href={ONLINE_TRAINING_LINK}
                    target='_blank'
                    onClick={() =>
                        AnalyticsSvc.track('RCX_serviceWelcomePage_Action', {
                            option: 'online training',
                        })
                    }
                >
                    {t('ROOT.NEED_HELP.LINKS.ONLINE')}
                </Link>
            </Links>
        </NeedHelpContainer>
    );
};

export default NeedHelp;
