export const RCX_CLIENT_APP_TYPE = 'rcx_client_app_type';

export const INIT_ROUTE_PATH = 'route_path';

export const DEFAULT_RCX_CLIENT_APP_TYPE = 'RCX_UI';

export const EMBEDDED_AGENT_RCX_CLIENT_APP_TYPE = 'RCX_EMBEDDED_AGENT';

export const EMBEDDED_ADMIN_RCX_CLIENT_APP_TYPE = 'RCX_EMBEDDED_ADMIN';

export const EMBEDDED_ANALYTICS_RCX_CLIENT_APP_TYPE = 'RCX_EMBEDDED_ANALYTICS';

export const EMBEDDED_AUTH_RINGSENSE_RCX_CLIENT_APP_TYPE =
    'RCX_EMBEDDED_AUTH_RINGSENSE';

export const EMBEDDED_AUTH_WEM_RCX_CLIENT_APP_TYPE = 'RCX_EMBEDDED_AUTH_WEM';

export const ZENDESK_RCX_CLIENT_APP_TYPE = 'RCX_ZENDESK';

export const SALESFORCE_RCX_CLIENT_APP_TYPE = 'RCX_SALESFORCE';

export const SALESFORCE_ADMIN_RCX_CLIENT_APP_TYPE = 'RCX_SALESFORCE_ADMIN';

export const DYNAMICS365_RCX_CLIENT_APP_TYPE = 'RCX_DYNAMICS365';

export const HUBSPOT_RCX_CLIENT_APP_TYPE = 'RCX_HUBSPOT';

export const ZOHO_RCX_CLIENT_APP_TYPE = 'RCX_ZOHO';

export const SERVICE_NOW_RCX_CLIENT_APP_TYPE = 'RCX_SERVICE_NOW';

export const WHITELABEL_CRM_RCX_CLIENT_APP_TYPE = 'RCX_WHITELABEL_CRM';

export const NETSUITE_RCX_CLIENT_APP_TYPE = 'RCX_NETSUITE';

export const FRESHDESK_RCX_CLIENT_APP_TYPE = 'RCX_FRESHDESK';

export const FRESHSERVICE_RCX_CLIENT_APP_TYPE = 'RCX_FRESHSERVICE';

export const BYOT_RCX_CLIENT_APP_TYPE = 'RCX_SALESFORCE_BYOT';

export const RCX_CLIENT_ID = 'GBN6JgdsTmWpaSesm0jjvg';

export const INITIAL_URL_QUERY_STRING_CACHE_KEY = 'INITIAL_URL_QUERY_STRING';
