import { alpha } from '@material-ui/core/styles';
import styled from 'styled-components';

export const StyledListItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray[900]};
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 10px;
    max-width: 155px;
`;

export const StyledListItemLink = styled.a`
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 8px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    place-content: center;
    align-items: center;
    border: 1.4px solid ${({ theme }) => theme.colors.gray[200]};
    color: currentColor;
    outline: none;

    &:hover {
        border-color: transparent;
        box-shadow: 0px 2px 6px 0px ${alpha('#000', 0.24)};
    }

    &:active {
        box-shadow: 0px 2px 4px 0px ${alpha('#000', 0.24)};
    }
`;
export const StyledListItemTitle = styled.div`
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    padding: 0 5px;
    width: 100%;
    overflow-wrap: break-word;
`;
