import { Session, StorageService, Navigation } from '@ringcx/shared';
export const sendTokenToByot = async (agentId?: number) => {
    let userDetails = StorageService.getUserDetails();
    const fullUserDetails = StorageService.getFullUserDetails();
    const accessToken = StorageService.getAccessToken();
    const refreshToken = StorageService.getRefreshToken();
    const tokenType = StorageService.getTokenType();
    userDetails = {
        ...userDetails,
        accessToken,
        refreshToken,
        tokenType,
    };
    window.parent.postMessage(
        {
            type: 'BYOT_LOGIN_SUCCESS',
            data: { userDetails, agentId, fullUserDetails },
        },
        '*'
    );
};

export const logoutAndSendFailToByot = () => {
    window.parent.postMessage(
        {
            type: 'BYOT_LOGIN_FAILED',
        },
        '*'
    );
    Session.logout().finally(() => {
        const loginUrl = Navigation.getLoginUrl();
        Session.clearSession();
        window.location.assign(loginUrl);
    });
};
