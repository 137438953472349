import type { FC, PropsWithChildren } from 'react';

import { Layout, Body } from './PageLayout.styled';
import { Header } from '../../containers';

const PageLayout: FC<PropsWithChildren<{ withoutHeader?: boolean }>> = ({
    children,
    withoutHeader = false,
}) => {
    return (
        <Layout>
            {withoutHeader ? null : <Header />}
            <Body>{children}</Body>
        </Layout>
    );
};

export default PageLayout;
