import { nonProdDomains } from '../common';

export const getAnalyticsToken = () => {
    const isNonProdDomain = nonProdDomains.some((el) =>
        window.location.hostname.includes(el)
    );

    if (isNonProdDomain) {
        return process.env['NX_MIXPANEL_TOKEN_TEST'] || '';
    }

    return process.env['NX_MIXPANEL_TOKEN'] || '';
};

export const getAnalyticsCookieDomain = () => {
    if (window.location.hostname.includes('engage.ringcentral.com')) {
        return '.engage.ringcentral.com';
    }

    if (window.location.hostname.includes('ringcx.ringcentral.com')) {
        return '.ringcx.ringcentral.com';
    }

    return window.location.hostname;
};
