import styled from 'styled-components';

export const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

export const Body = styled.div`
    background-color: var(--content-background);
    overflow: auto;
    flex: 1;
`;
