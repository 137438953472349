//ffs flags
export const CHANGE_AGENT_STATE_AVAILABILITY_FLAG =
    'rcx-native.ui.change-agent-state.availability';
export const AGENT_WARM_TRANSFER_ON_HOLD_FLAG =
    'rcx.ui.agent-warm-transfer-on-hold.availability';

export const AGENT_SIP_GEO_TAG = 'rcx.sip.geo_tags';
export const UNIFIED_CONTACT_MANAGEMENT_AVAILABILITY_FLAG =
    'rcx-native.ui.contact-management.availability';

export const VODAFONE_BRAND_REDIRECT_FLAG = 'rcx.ui.brand.vodafone.redirect';

export const REALTIME_AGENT_ACTIVITY_REPORT =
    'rcx.ui.realtime-agent-activity-report.availability';

export const REALTIME_WALLBOARDS_AVAILABILITY_FLAG =
    'rcx.ui.realtime-wallboards.availability';

export const REAL_TIME_WALLBOARD_KB_URL_FLAG =
    'rcx.ui.wallboard-kb-article-url';

//others
export const CURRENT_ACCOUNT_KEY = 'engage-admin.SelectedAccount';
