import { initVersionsHelper, initSentryService } from '@ringcx/shared';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js/stable';

import App from './App';
import { COMPONENT_NAME } from './constants';
import { ConsoleLogger } from './services/logging/consoleLogger';
import { initRequestLogging } from './services/transport';
import './styles/index.css';

initRequestLogging();

const currentPageSearch = new URLSearchParams(window.location.search);
initVersionsHelper(currentPageSearch.has('state') || undefined);

const container = document.getElementById('root');
/* istanbul ignore if */
if (container) {
    const root = createRoot(container);

    root.render(<App />);
}

ConsoleLogger.getInstance().initialize();

initSentryService({
    dsn: process.env.NX_SENTRY_DSN,
    metadata: {
        app: COMPONENT_NAME,
    },
    onErrorSending(error) {
        // Uncomment to enable sending Sentry errors to CPR
        // AuthClientCrp.getInstance().handleSentryError(error);
    },
});
