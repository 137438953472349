import type { ErrorEvent } from '@sentry/browser';
import { truncate } from 'lodash';

import {
    BRAND_ID,
    CLIENT_ID,
    CPR_ATTACHMENTS_SECTION,
    CPR_INFO_SECTION,
    RCX_CLIENT_APP_TYPE,
    WEBINAR_CLIENT_ENDPOINT_ID,
} from './constants';
import { EdrClient } from './edrClient';
import { getZipData } from './helpers/zipData';
import type {
    CprUserInfo,
    EdrInfo,
    ProblemReportData,
    ProblemReportSendingParams,
    ProblemReportRequest,
    ProblemReportResponse,
} from './types';
import { ProductType, productTypeMap, ReportType } from './types';
import { downloadBlob } from '../../helpers';
import { Session, StorageService } from '../auth';

export abstract class CprClient<T extends object> {
    protected readonly edrClient: EdrClient;
    protected readonly tokenType: string;

    public constructor() {
        this.tokenType = StorageService.getTokenType();

        const { rcPlatform } = Session.getUserDetails();

        this.edrClient = new EdrClient(rcPlatform, this.tokenType);

        //@ts-expect-error Element implicitly has an any type
        window[Symbol.for('evaa_logger')] = this;
    }

    public async downloadLog(id: string) {
        const encodedFileInfo = '';
        const edrInfo = await this.edrClient.getEdrInfo();
        await fetch(
            `${edrInfo?.cprUri}/cpr-api/v1/problem-reports/${id}/downloads/${encodedFileInfo}`
        );
    }

    public async downloadLogLocal() {
        const logs = await this.collectLogs();
        const blob = new Blob([JSON.stringify(logs, null, 2)], {
            type: 'application/json',
        });
        downloadBlob(blob, `evaa_logs_${new Date().toISOString()}_log.json`);
    }

    protected async sendProblemReport(
        {
            reportType = ReportType.ProblemReport,
            productType,
            title,
            description,
            attachments = [],
        }: ProblemReportSendingParams,
        filterParams?: T
    ) {
        if (!title) {
            throw new Error(`The 'title' field cannot be empty`);
        }

        const logs = await this.collectLogs(filterParams);

        const edrInfo = await this.edrClient.getEdrInfo();

        const cprInfo = this.getCprInfo({
            reportType,
            productType,
            title,
            description,
        });

        const cprResult = await this.sendDataToCpr({
            edrInfo,
            cprInfo,
            logs,
            attachments,
        });

        console.log('Data sent to CPR successfully');

        return {
            edrInfo,
            cprInfo,
            cprResult,
        };
    }

    public async handleProblemReportSubmit(
        productType: ProductType,
        reportData: ProblemReportData
    ): Promise<void> {
        await this.sendProblemReport(
            CprClient.reportDataToSendParams(productType, reportData)
        );
    }

    public async handleSentryError(errorEvent: ErrorEvent) {
        try {
            if (
                errorEvent.exception?.values?.some(
                    (ex) =>
                        ex.stacktrace?.frames?.some(
                            (frame) =>
                                frame.function?.includes(
                                    this.sendProblemReport.name
                                )
                        )
                )
            ) {
                return;
            }

            const title = `Sentry Error ${errorEvent.event_id}`;

            const description = [
                errorEvent.message,
                ...(errorEvent.exception?.values?.map((value) => value.value) ||
                    []),
            ].join('\n');

            await this.sendProblemReport({
                productType: ProductType.RCX,
                title,
                description,
            });
        } catch (error) {
            console.error('Failed to submit Sentry-related report', error);
        }
    }

    protected abstract collectLogs(filterParams?: T): Promise<unknown[]>;

    protected getCprInfo(params: {
        reportType: ReportType;
        productType: ProductType;
        title: string;
        description: string;
    }): ProblemReportRequest {
        const userInfo = this.getUserInfo();

        return {
            title: params.title,
            description: params.description,
            submitterEmail: userInfo.email,
            reportType: params.reportType,
            clientId: CLIENT_ID, // RCX
            clientEndpointId: WEBINAR_CLIENT_ENDPOINT_ID, // temporary use
            clientVersion: window?.__settings?.versions?.reviewTag || 'unknown',
            clientAppType: RCX_CLIENT_APP_TYPE,
            clientDetails: this.edrClient.userAgent,
            accountId: userInfo.accountId,
            userId: userInfo.userId,
            brandId: BRAND_ID,
            productCategory: this.getProductCategory(params.productType),
            productSubcategory: userInfo.productSubcategory,
        };
    }

    protected getProductCategory(productType: ProductType): string {
        return productTypeMap[productType];
    }

    protected abstract getUserInfo(): CprUserInfo;

    protected async sendDataToCpr(params: {
        edrInfo: EdrInfo;
        cprInfo: ProblemReportRequest;
        logs: unknown;
        attachments?: File[];
    }): Promise<ProblemReportResponse> {
        const { blob, filename } = await getZipData({
            logs: params.logs,
            attachments: params.attachments,
        });

        const formData = new FormData();

        formData.append(
            CPR_INFO_SECTION,
            new Blob([JSON.stringify(params.cprInfo)], {
                type: 'application/json',
            })
        );
        formData.append(CPR_ATTACHMENTS_SECTION, blob, filename);

        const res = await fetch(
            `${params.edrInfo.cprUri}/cpr-api/v1/problem-reports`,
            {
                method: 'POST',
                headers: new Headers({
                    Authorization: `${this.tokenType} ${params.edrInfo.token}`,
                    'User-Agent': this.edrClient.userAgent,
                    'X-User-Agent': this.edrClient.xUserAgent,
                }),
                body: formData,
            }
        );

        if (res.ok) {
            return res.json();
        } else {
            throw new Error(await res.text());
        }
    }

    public static reportDataToSendParams(
        productType: ProductType,
        reportData: ProblemReportData
    ): ProblemReportSendingParams {
        const description = [
            reportData.description,
            reportData.when || '',
        ].join('\n');

        const title = truncate(reportData.description, { length: 128 });

        return {
            reportType: ReportType.ProblemReport,
            productType,
            title,
            description,
            attachments: reportData.attachments,
        };
    }
}
