import JSZip from 'jszip';

import {
    EXPORT_LOG_TYPE,
    LOG_FILES_FOLDER,
    LOG_FILE_PREFIX,
} from '../constants';
import type { CprParams, ZipParams } from '../types';

export async function getZipData({
    logs,
    attachments,
}: ZipParams): Promise<CprParams> {
    const zip = new JSZip();

    const fileBaseName = `${LOG_FILE_PREFIX}_${Date.now()}`;
    zip.file(`${fileBaseName}.${EXPORT_LOG_TYPE}`, JSON.stringify(logs));

    if (attachments?.length) {
        const filesZip = zip.folder(LOG_FILES_FOLDER);
        const getFileName = checkFileNames();
        attachments.forEach((file) => {
            filesZip?.file(getFileName(file), file);
        });
    }

    const blob = await zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
            level: 9,
        },
    });

    return {
        blob,
        filename: `${fileBaseName}.zip`,
    };
}

function checkFileNames() {
    const nameCounter: Record<string, number> = {};

    return (file: File) => {
        let fileName;

        if (!nameCounter[file.name]) {
            fileName = file.name;
            nameCounter[file.name] = 1;
        } else {
            fileName = file.name.replace(
                /(?=\.[^.]+$)/g,
                `(${nameCounter[file.name]})`
            );
            nameCounter[file.name]++;
        }

        return fileName;
    };
}
