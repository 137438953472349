import { DEFAULT_BRAND_ID, VODAFONE_BRAND_ID } from './constants';
import { HttpService } from './http.service';
import { PromiseCacheService } from './promise-cache.service';
import StorageService from './storage.service';
import type { IFullUserDetails, AppPermissions, IRCBrandInfo } from './types';
import { getDefaultContentTypeHeader, Session } from '../Session';
export const defaultPermissions: AppPermissions = {
    Agent: false,
    Admin: false,
    Analytics: false,
    WEMEnterprise: false,
    RingSense: false,
};

/**
 * This class is responsible for hosting methods that provide the ui with details about the currently
 * logged-in user.
 */
export class UserService {
    /**
     * @ignore
     * Returns information we have in storage about the current user across engageVoice, engageDigital and RC. This object
     * does not include any information about the session
     *
     * @returns {Object} Object containing the full user details.
     */
    static getFullUserDetails(): IFullUserDetails {
        return StorageService.getFullUserDetails();
    }

    /**
     * Fetches information from the logged-in user from engage-api if not force and has not been previously set.
     *
     * @returns {Promise} Promise that resolves to "fullUserDetails".
     * */
    static getLoggedInUser(
        args: Partial<{
            force: boolean;
            evSubAccountId: string;
        }> = {}
    ): Promise<IFullUserDetails> {
        const { force, evSubAccountId } = args;
        const headers = {
            ...Session.getAuthHeader(),
            ...getDefaultContentTypeHeader(),
        };
        const existingUser = UserService.getFullUserDetails();

        // if user has been stored in local storage, and the force flag set to false, we return the user in storage.
        // this will make the method faster, but should not be called when setting for a session.
        if (existingUser && this.anyIdExists(existingUser) && !force) {
            return Promise.resolve(existingUser);
        }

        PromiseCacheService.setIfEmpty(
            PromiseCacheService.KEYS.FULL_USER_DETAILS,
            () => {
                const searchParams = new URLSearchParams();

                if (evSubAccountId) {
                    searchParams.append('evSubAccountId', evSubAccountId);
                }

                return HttpService.get(
                    `/api/v6/userDetail/self${
                        searchParams.size ? '?' + searchParams.toString() : ''
                    }`,
                    { headers }
                ).then((fullUserDetails) => {
                    try {
                        const parsedUserDetails = JSON.parse(
                            fullUserDetails.response
                        );
                        StorageService.setFullUserDetails(parsedUserDetails);
                        return parsedUserDetails;
                    } catch (e) {
                        window.console.error(
                            'There was an error parsing the response from /userDetail/self: ',
                            e
                        );
                    }
                });
            }
        );

        return PromiseCacheService.get<IFullUserDetails>(
            PromiseCacheService.KEYS.FULL_USER_DETAILS
        );
    }

    /**
     * checks to see if there is any valid id on the object
     * @param userDetails
     */
    static anyIdExists(userDetails: IFullUserDetails): boolean {
        let hasAgentIds = false;
        if (
            userDetails.agentDetails !== null &&
            userDetails.agentDetails.length > 0
        ) {
            hasAgentIds = userDetails.agentDetails.some(
                (agentDetails) => agentDetails.agentId
            );
        }

        return (
            userDetails.rcUserId !== null ||
            userDetails.adminId !== null ||
            hasAgentIds
        );
    }

    /**
     * Fetches information about the brand from RingCentral API.
     */

    static async getRcBrandInfo(
        args: Partial<{
            force: boolean;
        }> = {}
    ): Promise<IRCBrandInfo> {
        const { force } = args;
        const { rcPlatform } = Session.getUserDetails();
        const existingUser = UserService.getFullUserDetails();
        const headers = {
            ...Session.getRCAuthHeader(),
            'Content-Type': 'application/json',
        };

        const existingBrandInfo = StorageService.getRcBrandInfo();
        if (
            (existingBrandInfo && existingBrandInfo.id && !force) ||
            // Skip the Brand Info request for Legacy Login
            !existingUser?.rcAccountId
        ) {
            return Promise.resolve(existingBrandInfo);
        }

        PromiseCacheService.setIfEmpty(
            PromiseCacheService.KEYS.BRAND_INFO,
            async () =>
                HttpService.externalGet(
                    rcPlatform,
                    `/restapi/v1.0/account/${existingUser.rcAccountId}/service-info`,
                    { headers }
                ).then((res) => {
                    try {
                        const parsedBrandInfo = JSON.parse(res.response).brand;

                        StorageService.setRcBrandInfo(parsedBrandInfo);
                        return Promise.resolve(parsedBrandInfo);
                    } catch (e) {
                        window.console.error(
                            'There was an error parsing the response from /account/:rcAccountId: ',
                            e
                        );
                        return Promise.resolve(undefined);
                    }
                })
        );

        return PromiseCacheService.get<IRCBrandInfo>(
            PromiseCacheService.KEYS.BRAND_INFO
        );
    }

    static isDefaultBrand(): boolean {
        return (
            StorageService.getRcBrandInfo().id === DEFAULT_BRAND_ID ||
            localStorage.getItem('feature:brandId') === '1210'
        );
    }

    static isVodafoneBrand(): boolean {
        return (
            StorageService.getRcBrandInfo().id === VODAFONE_BRAND_ID ||
            localStorage.getItem('feature:brandId') === '7010'
        );
    }

    /**
     * checks to see permissions of the logged-in user
     *
     * TODO Be careful with getPermissions for app switcher,
     *  for correct work we need to rewrite init session mechanism, and how we init rcx app type in apps start
     */
    static async getPermissions(
        args: Partial<{
            force: boolean;
            evSubAccountId: string;
        }> = {}
    ): Promise<AppPermissions> {
        const { force, evSubAccountId } = args;
        const permissions = { ...defaultPermissions };
        const { availableWEM, availableRingSense } = Session.getUserDetails();

        const { adminId, agentDetails } = await UserService.getLoggedInUser({
            force,
            evSubAccountId,
        });

        await UserService.getRcBrandInfo({ force });

        permissions.Analytics = adminId !== null;
        permissions.Admin = adminId !== null;
        permissions.Agent = agentDetails !== null && agentDetails.length > 0;
        // temporary changes till BE adds a flag
        permissions.WEMEnterprise = availableWEM;
        permissions.RingSense =
            typeof availableRingSense === 'undefined'
                ? availableWEM
                : availableRingSense;

        if (
            Session.isCRMClientAppType() ||
            Session.isEmbeddedAgentClientAppType()
        ) {
            permissions.Admin = false;
            permissions.Analytics = false;
            permissions.WEMEnterprise = false;
            permissions.RingSense = false;
        }

        if (
            Session.isCRMAdminClientAppType() ||
            Session.isEmbeddedAdminClientAppType()
        ) {
            permissions.Agent = false;
            permissions.Analytics = false;
            permissions.WEMEnterprise = false;
            permissions.RingSense = false;
        }

        if (Session.isEmbeddedAnalyticsClientAppType()) {
            permissions.Agent = false;
            permissions.Admin = false;
            permissions.WEMEnterprise = false;
            permissions.RingSense = false;
        }

        if (Session.isEmbeddedAuthRingSenseClientAppType()) {
            permissions.Agent = false;
            permissions.Admin = false;
            permissions.WEMEnterprise = false;
            permissions.Analytics = false;
        }

        if (Session.isEmbeddedAuthWEMClientAppType()) {
            permissions.Agent = false;
            permissions.Admin = false;
            permissions.Analytics = false;
            permissions.RingSense = false;
        }

        return permissions;
    }
}
