export const KEYS = {
    AVAILABILITY: 'AQA_helper',
    VERSIONS_KEY: 'AQA_helper_versions',
    OVERRIDE_LOCALE: 'override_locale',
    OVERRIDE_FORMAT_LOCALE: 'override_format_locale',
};

export const IDS = {
    STYLE: 'AQA_helper_style',
    BODY: 'AQA_helper_body',
    VERSIONS: 'AQA_helper_versions',
    ED_CONTROLS: 'AQA_helper_ed_controls',
    ED_URL: 'AQA_helper_ed_url',
};

export const APPS: Record<string, string> = {
    eac: '/',
    ead: '/voice/admin/',
    eag: '/voice/agent/',
    esu: '/superadmin/',
    eua: '/analytics/',
    eis: '/omni/workflow-studio/',
    esr: '/voice/script-studio/',
};

export const APPS_LIST = Object.keys(APPS);

export const CLASSES = {
    BODY: 'AQA_helper_body',
    GREEN: 'AQA_helper_body_green',
    BLUE: 'AQA_helper_body_blue',
    VERSIONS: 'AQA_helper_version',
    VERSIONS_VISIBLE: 'AQA_helper_version_visible',
    CONTROLS: 'AQA_helper_version_control',
    CONTROLS_BLUE: 'AQA_helper_version_control_blue',
    CONTROLS_GREEN: 'AQA_helper_version_control_green',
    CONTROLS_BUTTONS: 'AQA_helper_version_control_buttons',
    LOCALE_CONTROLS: 'AQA_helper_locale_controls',
    CONTROLS_BUTTON_CANCEL: 'AQA_helper_version_control_button_cancel',
    CONTROLS_BUTTON_SUBMIT: 'AQA_helper_version_control_button_submit',
    TICKET: 'AQA_helper_ticket',
};

// List of supported locales form engage
export const ENGAGE_SUPPORT_LOCALE_LIST = [
    'rc-XX',
    'en-US',
    'en-GB',
    'en-AU',
    'de-DE',
    'fr-CA',
    'fr-FR',
    'it-IT',
    'es-ES',
    'es-419',
];

// This list is use for format locale, because it's relay to browser Intl API and supported more locales from the box
export const FULL_RC_LOCALE_LIST = [
    ...ENGAGE_SUPPORT_LOCALE_LIST,
    'zh-TW',
    'fi-FI',
    'ja-JP',
    'ko-KR',
    'nl-NL',
    'pt-BR',
    'zh-CN',
    'pt-PT',
    'zh-HK',
];

export const MARKERS = {
    NAME: '_name',
    BLUE: '_blue',
    GREEN: '_green',
    TICKET: '_ticket',
    CANCEL: '_cancel',
    SUBMIT: '_submit',
    LOCALE: '_locale',
    FORMAT_LOCALE: '_format_locale',
};

export const EMPTY_LOCALE = '-----';

export const COOKIE_ATTRIBUTES =
    process.env.NODE_ENV === 'test'
        ? 'path=/'
        : 'path=/; SameSite=None; Secure';

export const COLOR = {
    BLUE: '#1f7ad0',
    GREEN: '#13a81a',
    TICKET: '#c82626',
};

export const VALUES = {
    BLUE: 'blue',
    GREEN: 'green',
};

export const CSS = `
        .${CLASSES.BODY} {
            position: fixed;
            bottom: 0;
            left: 0;
            background: ${COLOR.TICKET};
            opacity: 0.5;
            font-size: 14px;
            padding: 10px;
            border-top-right-radius: 5px;
            cursor: pointer;
            z-index: 99999;
        }

        @media (max-width: 300px) {
            .${CLASSES.BODY} {
                bottom: 60px;
            }
        }

        .${CLASSES.BODY}:hover {
            opacity: 1;
            outline: 1px solid #000;
        }

        .${CLASSES.BODY}.${CLASSES.BLUE} {
            background: ${COLOR.BLUE};
        }

        .${CLASSES.BODY}.${CLASSES.GREEN} {
            background: ${COLOR.GREEN};
        }

        .${CLASSES.VERSIONS} {
            display: none;
            position: fixed;
            top: 50%;
            left: 50%;
            background: #fff;
            font-size: 14px;
            border-radius: 5px;
            transform: translate(-50%, -50%);
            border: 1px solid #000;
            z-index: 99999;
        }

        @media screen and (max-width: 360px) {
            .${CLASSES.VERSIONS} {
                width: 100%;
            }

            .${CLASSES.VERSIONS} table td.${CLASSES.TICKET} {
                text-align: initial;
            }

            .${CLASSES.VERSIONS} .${CLASSES.TICKET} input {
                max-width: 75px;
            }
        }

        .${CLASSES.VERSIONS}.${CLASSES.VERSIONS_VISIBLE} {
            display: block;
        }

        .${CLASSES.VERSIONS} table {
            border-collapse: collapse;
        }

        .${CLASSES.VERSIONS} table th,
        .${CLASSES.VERSIONS} table td {
            border: 1px solid #000;
            padding: 5px 10px;
            text-align: center;
        }

        .${CLASSES.VERSIONS} .${CLASSES.CONTROLS_BUTTONS} {
            padding: 10px;
            display: flex;
            justify-content: space-between;
        }

        .${CLASSES.VERSIONS} .${CLASSES.CONTROLS_BUTTONS} button {
            border: 1px solid #d5d9d9;
            border-radius: 8px;
            box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
            box-sizing: border-box;
            color: #0f1111;
            cursor: pointer;
            display: inline-block;
            font-size: 13px;
            line-height: 29px;
            padding: 0 10px 0 11px;
            position: relative;
            text-align: center;
            text-decoration: none;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            vertical-align: middle;
            width: 100px;
        }

        .${CLASSES.VERSIONS} .${CLASSES.CONTROLS_BUTTONS} button:hover {
            background-color: #f7fafa;
        }

        .${CLASSES.VERSIONS} td:first-child {
            background: ${COLOR.TICKET}
        }

        .${CLASSES.VERSIONS} td:first-child.${CLASSES.CONTROLS_BLUE} {
            background: ${COLOR.BLUE};
        }

        .${CLASSES.VERSIONS} td:first-child.${CLASSES.CONTROLS_GREEN} {
            background: ${COLOR.GREEN};
        }

        .${CLASSES.LOCALE_CONTROLS} {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
        }

        .${CLASSES.LOCALE_CONTROLS} fieldset {
            width: 180px;
            border: 0;
        }

        .${CLASSES.LOCALE_CONTROLS} select {
            border: 1px solid #000;
            border-radius: 3px;
            text-align: center;
            height: 30px;
            width: 100px;
        }

        #${IDS.ED_CONTROLS} {
            margin: 10px;
            display: none;
        }
    `;
