import { CURRENT_ACCOUNT_KEY } from './constants';
import { Session } from '../auth';

/**
 * Rules for FFS
 * Some pre-defined tool methods, please use them as needed.
 */
export const FFS_RULES = {
    withRegion: 'platformId',
    withMainAccountId: 'mainAccountId',
    withAdminId: 'adminId',
    withSubAccountId: {
        name: 'subAccountId',
        value: () => {
            const selectedAccount = localStorage.getItem(CURRENT_ACCOUNT_KEY);
            if (!selectedAccount) return null;
            try {
                const parsedAccount = JSON.parse(selectedAccount);
                return parsedAccount?.accountId;
            } catch (e) {
                console.error(
                    'Error parsing selected account from localStorage',
                    e
                );
                return null;
            }
        },
    },
    withRcxClientAppType: {
        name: 'rcxClientAppType',
        value: () => Session.rcxClientAppType,
    },
};

export type FFS_RULES_KEYS = keyof typeof FFS_RULES;
