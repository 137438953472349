import styled, { css } from 'styled-components';

import { device } from '../../constants/breakpoints';

export const AppPickerWrapper = styled.div`
    min-width: 848px;
    @media ${device.crm} {
        min-width: auto;
    }
    height: 100%;
    overflow: auto;
`;

export const Title = styled.h2`
    margin-top: 64px;
    color: ${({ theme }) => theme.colors.gray[900]};
    letter-spacing: 0px;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
`;

export const Information = styled.p`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    max-width: 500px;
    margin: 25px auto 0;
`;

export const Container = styled.div<{ tilesNumber: number }>`
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 0 30px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

    @media ${device.crm} {
        flex-direction: column;
    }

    ${({ tilesNumber }) => {
        return (
            tilesNumber > 4 &&
            css`
                max-width: 592px;

                a:nth-child(3n) {
                    margin-right: 0;
                }
            `
        );
    }}
`;
