import styled from 'styled-components';

export const NeedHelpContainer = styled.div`
    max-width: 592px;
    margin: 56px auto 40px;
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
    margin: 56px 0 0;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin: 25px 0 0;
`;

export const Links = styled.p`
    margin: 14px 0 0;
`;

export const Link = styled.a`
    margin: 0;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    letter-spacing: 0.25px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`;

export const LinkDivider = styled.span`
    display: inline-block;
    height: 12px;
    width: 1px;
    background: ${({ theme }) => theme.colors.gray[600]};
    margin: 0 16px;
    vertical-align: middle;
`;
