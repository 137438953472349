import type { FC, PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';

import {
    Button,
    Name,
    StyledBGButton,
    StyledBGWrapper,
} from './AppButton.styled';
import type { AppButtonType } from './types';
import { BGTypes } from './types';
import { VERSION_KEY } from '../../../../constants/paths';
import { APP_TILE } from '../../../../constants/testId';
import { AnalyticsSvc } from '../../../../services/analytics';

const urlHasQueryParams = (url: string) => url.includes('?');

const AppButton: FC<PropsWithChildren<AppButtonType>> = ({
    name,
    href,
    openInNewTab,
    stateManagement,
    children,
}) => {
    const props = (version = '') => {
        const versionKey = (urlHasQueryParams(href) ? '&' : '?') + VERSION_KEY;
        const hrefWithParams = `${href}${version ? versionKey + version : ''}`;

        if (/^https?:\/\//.test(href)) {
            return {
                ...(openInNewTab && {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                }),
                href: hrefWithParams,
            };
        }

        return {
            to: hrefWithParams,
            component: Link,
        };
    };

    const trackRCXAppSection = () => {
        AnalyticsSvc.track('RCX_App_Section', {
            section: name,
        });
    };

    return (
        <Button
            variant='outlined'
            data-aid={APP_TILE}
            {...props()}
            onClick={trackRCXAppSection}
        >
            <Name>{name}</Name>
            {children}
            {stateManagement && (
                <StyledBGWrapper>
                    <StyledBGButton
                        {...props(BGTypes.BLUE)}
                        version={BGTypes.BLUE}
                        data-aid={BGTypes.BLUE}
                    />
                    <StyledBGButton
                        {...props(BGTypes.GREEN)}
                        version={BGTypes.GREEN}
                        data-aid={BGTypes.GREEN}
                    />
                </StyledBGWrapper>
            )}
        </Button>
    );
};

export default AppButton;
