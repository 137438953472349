import type { EngageClientRequestIdHeader } from './logging/types';
import type { PromiseObj, RejectCallback, ResolveCallback } from './types';
import LoggingService from '../services/logging';
import type { IRequestOptions } from '../Session';
import { Utils } from '../Utils';

// Set the apiBase
let apiBase = window.location.origin;

/**
 * @ignore
 *
 * Adds headers to XMLHttpRequest based on configuration object.
 *
 * @param {Object} config - Config object passed to HttpService methods.
 * @param {XMLHttpRequest} req - Instance of XMLHttpRequest that needs to be configured.
 */
export function addHeaders(config: IRequestOptions, req: XMLHttpRequest): void {
    if (!Utils.isObject(config)) {
        return;
    }

    const headers = config.headers;

    if (!Utils.isObject(headers)) {
        return;
    }

    const headersKeys = Object.getOwnPropertyNames(headers) as Array<
        keyof typeof headers
    >;
    headersKeys.forEach((key) => {
        req.setRequestHeader(key, (headers && headers[key]) || '');
    });
}

/**
 * @ignore
 *
 * Adds logging headers to XMLHttpRequest.
 *
 * @param {XMLHttpRequest} req - Instance of XMLHttpRequest that needs to be configured.
 */
function addLoggingHeader(req: XMLHttpRequest): void {
    const clientRequestIdHeader =
        LoggingService.getEngageClientRequestIdHeader();
    const clientRequestIdHeaderKeys = Object.keys(
        clientRequestIdHeader
    ) as Array<keyof EngageClientRequestIdHeader>;

    clientRequestIdHeaderKeys.forEach((key) => {
        req.setRequestHeader(key, clientRequestIdHeader[key]);
    });
}

/**
 * @ignore
 *
 * Configures an XMLHttpRequest object to properly resolve/reject a promise, depending on the outcome of the request.
 *
 * @param {Function} resolve - Resolve callback function from a promise. Invoked if the request completed successfully.
 * @param {Function} reject - Reject callback function from a promise. Invoked if the request failed.
 * @param {XMLHttpRequest} req - Instance of XMLHttpRequest that will be configured.
 */
function addCompletionListeners(
    resolve: ResolveCallback,
    reject: RejectCallback,
    req: XMLHttpRequest
): void {
    req.addEventListener('error', (e: Event) => reject(e));
    req.addEventListener('timeout', () => reject(new Error('request timeout')));
    req.addEventListener('load', function () {
        if (this.status !== 200) {
            reject({
                status: this.status,
                response: this.responseText,
            });
        } else {
            resolve({
                status: this.status,
                response: this.responseText,
            });
        }
    });
}

/**
 * @ignore
 *
 * Takes a config object and serializes/URI encodes the contents of the body property. If the "Content-Type" header is set
 * to "application/json", it encodes the payload as JSON. Otherwise, we assume that the payload should be x-www-form-urlencoded.
 */
export function getUriEncodedBody(
    config: IRequestOptions
): XMLHttpRequestBodyInit {
    const contentType =
        config && config.headers && config.headers['Content-Type'];
    let body: XMLHttpRequestBodyInit = (config && config.body) || '';

    if (contentType === 'application/json') {
        body = JSON.stringify(body);
    } else {
        if (Utils.isObject(body)) {
            const bodyObj = Object.assign({}, body);
            body = '';
            const bodyObjKeys = Object.keys(bodyObj) as Array<
                keyof typeof bodyObj
            >;
            body += bodyObjKeys
                .map(
                    (key) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                            bodyObj[key]
                        )}`
                )
                .join('&');
        }
    }

    return body;
}

/**
 * @ignore
 *
 * Internally used service that makes HTTP requests to Engage Auth.
 */
export class HttpService {
    /**
     * @ignore
     *
     * Gets the base URL of Engage Auth.
     *
     * @returns {string}
     */
    static getApiBase(): string | undefined {
        return apiBase;
    }

    /**
     * @ignore
     *
     * Sets the base URL for Engage Auth.
     *
     * @param {string} newBase
     */
    static setApiBase(newBase: string): void {
        apiBase = newBase;
    }

    /**
     * @ignore
     *
     * Makes a GET request to Engage Auth.
     *
     * @param {string} path - Relative path to append to apiUrl.
     * @param {Request} config - Object describing different properties of the request.
     * @returns {Promise} Promise that represents status of the request. Resolves if server responds with 200 status code, and is rejected otherwise.
     */
    static get(path: string, config: IRequestOptions): Promise<PromiseObj> {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open('GET', apiBase + path);
            addHeaders(config, req);
            addLoggingHeader(req);
            addCompletionListeners(resolve, reject, req);
            req.send();
        });
    }

    /**
     * @ignore
     *
     * Makes a GET request to Engage Auth.
     *
     * @param {string} path - Relative path to append to apiUrl.
     * @param {Object} config - Object describing different properties of the request.
     * @returns {Promise} Promise that represents status of the request. Resolves if server responds with 200 status code, and is rejected otherwise.
     */
    static post(path: string, config: IRequestOptions): Promise<PromiseObj> {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open('POST', apiBase + path);
            addHeaders(config, req);
            addLoggingHeader(req);
            addCompletionListeners(resolve, reject, req);
            req.send(getUriEncodedBody(config));
        });
    }

    /**
     * @ignore
     *
     * Make a DELETE request to Engage Auth.
     *
     * @param {string} path - Relative path to append to apiUrl
     * @param {*} config - Object describing different properties of the request.
     * @returns {Promise} Promise that represents status of the request. Resolves if server responds with 200 status code, and is rejected otherwise.
     */
    static delete(
        path: string,
        config: IRequestOptions | null
    ): Promise<PromiseObj> {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open('DELETE', apiBase + path);
            if (config) {
                addHeaders(config, req);
            }
            addLoggingHeader(req);
            addCompletionListeners(resolve, reject, req);
            req.send();
        });
    }

    static externalGet(
        externalApiBase: string,
        path: string,
        config: IRequestOptions
    ): Promise<PromiseObj> {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open('GET', externalApiBase + path);
            addHeaders(config, req);
            addLoggingHeader(req);
            addCompletionListeners(resolve, reject, req);
            req.send();
        });
    }

    static externalPost(
        externalApiBase: string,
        path: string,
        config: IRequestOptions
    ): Promise<PromiseObj> {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open('POST', externalApiBase + path);
            addHeaders(config, req);
            addLoggingHeader(req);
            addCompletionListeners(resolve, reject, req);
            req.send(getUriEncodedBody(config));
        });
    }
}
