export const BASE_API = '/voice/api/v1/admin';
export const BASE_API_PER_ACCOUNT = BASE_API + '/accounts';

export const BASE_OMNI_API = '/omni/config';
export const BASE_OMNI_API_PER_ACCOUNT = BASE_OMNI_API + '/accounts';
export const enum ApiVersion {
    V1 = 'v1',
    V2 = 'v2',
    V2_SKINNY = 'v2-skinny',
}
export const nonProdDomains = (process.env['NX_NON_PROD_DOMAINS'] || '').split(
    ','
);
