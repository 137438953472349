import {
    compare,
    hasSameVersionFormat,
    validate,
} from './appVersionComparison.helpers';
import {
    GETTING_VERSION_ERROR_MSG,
    REQUEST_VERSION_ERROR_MSG,
    VERSION_FORMAT_ERROR_MSG,
    DEFAULT_MANIFEST_PATH,
    DEFAULT_TIMEOUT,
} from './constants';
import type {
    AppVersionComparisonOptions,
    AppVersionComparisonResponse,
} from './types';

class AppVersionComparison {
    private static running = false;
    private static timer: number | null = null;

    static stop() {
        this.running = false;

        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }

    static start(options?: AppVersionComparisonOptions) {
        this.stop();

        const {
            manifestPath = DEFAULT_MANIFEST_PATH,
            timeout = DEFAULT_TIMEOUT,
            onResult,
            onError,
            onUrgentDifference,
            onSatisfiedDifference,
        } = options || {};

        this.running = timeout > 0;

        const makeRequest = async () => {
            return this.compare(manifestPath)
                .then((result) => {
                    if (result.greater || result.updateRequired) {
                        onUrgentDifference && onUrgentDifference();
                    } else if (!result.equal) {
                        onSatisfiedDifference && onSatisfiedDifference();
                    }

                    onResult && onResult(result);
                })
                .catch((reason) => onError && onError(reason));
        };

        const runTimer = () => {
            if (this.running) {
                this.timer = window.setTimeout(() => {
                    makeRequest().then(runTimer);
                }, timeout);
            }
        };

        makeRequest().then(runTimer);
    }

    static startOnce(options?: Omit<AppVersionComparisonOptions, 'timeout'>) {
        this.start({ ...options, timeout: 0 });
    }

    static async compare(
        manifestPath: string = DEFAULT_MANIFEST_PATH
    ): Promise<AppVersionComparisonResponse> {
        let currentVersion;
        let localVersion;
        let updateRequired;

        try {
            const timestamp = new Date().getTime();
            const cachelessPath = `${manifestPath}?v=${timestamp}`;

            ({
                versions: { reviewTag: currentVersion, updateRequired },
            } = await fetch(cachelessPath).then((r) => r.json()));
        } catch (e) {
            return Promise.reject(new Error(REQUEST_VERSION_ERROR_MSG));
        }

        try {
            ({ reviewTag: localVersion } = window.__settings.versions);
        } catch (e) {
            return Promise.reject(new Error(GETTING_VERSION_ERROR_MSG));
        }

        if ([localVersion, currentVersion].every(validate)) {
            const result = {
                equal: false,
                greater: false,
                lower: false,
                updateRequired: !!updateRequired,
                currentVersion,
                localVersion,
            };

            if (hasSameVersionFormat(localVersion, currentVersion)) {
                const comparison = compare(localVersion, currentVersion);

                result.equal = comparison === 0;
                result.greater = comparison > 0;
                result.lower = comparison < 0;
            }

            return result;
        }

        return Promise.reject(new Error(VERSION_FORMAT_ERROR_MSG));
    }
}

export default AppVersionComparison;
