import type { FC, Dispatch, SetStateAction } from 'react';
import { memo, useState, useEffect } from 'react';

import { BRAND_IMG_BASE_PATH } from './constants';
import { StyledLogo } from './Logo.styled';
import type { ILogo } from './types/Logo';

let currentLogoSrc = '';

const Logo: FC<ILogo> = ({
    mainAccountId,
    subAccountId,
    onClick,
    defaultLogo,
    withToggle = false,
}) => {
    const defaultSource = `${BRAND_IMG_BASE_PATH}${defaultLogo}_default_v2.svg?v=5`;
    const mainAccountLogo = `${BRAND_IMG_BASE_PATH}${mainAccountId}_large.png?v=5`;
    const subAccountLogo = `${BRAND_IMG_BASE_PATH}${subAccountId}_large.png?v=5`;

    const [logoSrc, setLogoSrc] = useState<string>(currentLogoSrc);

    useEffect(() => {
        if (mainAccountId !== undefined && subAccountId === undefined) {
            if (mainAccountId) {
                setupLogo([mainAccountLogo, defaultSource], setLogoSrc);
            }
        } else if (mainAccountId !== undefined && subAccountId !== undefined) {
            if (mainAccountId && subAccountId) {
                setupLogo(
                    [subAccountLogo, mainAccountLogo, defaultSource],
                    setLogoSrc
                );
            }
        } else {
            setupLogo([defaultSource], setLogoSrc);
        }
    }, [
        defaultSource,
        mainAccountId,
        mainAccountLogo,
        subAccountId,
        subAccountLogo,
    ]);

    return (
        <StyledLogo {...{ withToggle, onClick }}>
            {logoSrc && <img src={logoSrc} alt='logo' />}
        </StyledLogo>
    );
};

async function setupLogo(
    sources: string[],
    setter: Dispatch<SetStateAction<string>>
): Promise<void> {
    currentLogoSrc = await pickSrc(sources);

    setter(currentLogoSrc);
}

async function pickSrc(sources: string[]): Promise<string> {
    const [url, ...rest] = sources;

    if (!url) {
        return Promise.resolve(currentLogoSrc);
    }

    return new Promise((resolve) => {
        const img = document.createElement('img');

        img.addEventListener('load', () => resolve(url));
        img.addEventListener('error', () => resolve(pickSrc(rest)));

        img.src = url;
    });
}

export default memo(Logo);
