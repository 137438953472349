import {
    AGENT_WARM_TRANSFER_ON_HOLD_FLAG,
    CHANGE_AGENT_STATE_AVAILABILITY_FLAG,
    UNIFIED_CONTACT_MANAGEMENT_AVAILABILITY_FLAG,
    VODAFONE_BRAND_REDIRECT_FLAG,
} from './constants';

const defaultFlags = {
    [CHANGE_AGENT_STATE_AVAILABILITY_FLAG]: true,
    [AGENT_WARM_TRANSFER_ON_HOLD_FLAG]: false,
    [VODAFONE_BRAND_REDIRECT_FLAG]: false,
    [UNIFIED_CONTACT_MANAGEMENT_AVAILABILITY_FLAG]: false,
};

export default defaultFlags;
