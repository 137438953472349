import type { FC, FormEvent } from 'react';
import { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import axiosErrorParser from '../../../../helpers/AxiosErrorParser';
import { Label, Input, Button } from '../../../Login/Login.styled';

const Form: FC<{
    app: string;
    platformId: string;
    onSubmitComplete(args: { email: string }): void;
}> = ({ app, platformId, onSubmitComplete }) => {
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSubmitting(true);

        const data = new URLSearchParams();

        data.append('app', app);
        data.append('username', email);
        data.append('platformId', platformId);

        axios
            .post('/api/auth/password/requestreset', data)
            .then(() => {
                setSubmitting(false);
                onSubmitComplete({ email });
            })
            .catch((error) => {
                setSubmitting(false);
                const errorMessage = `Forget password request failed: ${axiosErrorParser(
                    error
                )}`;

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
            });
    };

    return (
        <form onSubmit={onSubmit}>
            <Label htmlFor='email'>Email</Label>
            <Input
                id='email'
                type='email'
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />

            <Button size='small' fullWidth type='submit' disabled={submitting}>
                {submitting ? (
                    <CircularProgress
                        color='inherit'
                        size='1.2em'
                        data-aid='loading'
                    />
                ) : (
                    'Send link'
                )}
            </Button>
        </form>
    );
};

export default Form;
