import type { FC } from 'react';
import { useLayoutEffect } from 'react';

import { Redirect as RRRedirect } from 'react-router-dom';

const Redirect: FC<{
    to: string;
}> = ({ to }) => {
    const isExternal = /^https?:\/\//.test(to);

    useLayoutEffect(() => {
        if (isExternal) {
            window.location.assign(to);
        }
    }, [to, isExternal]);

    return isExternal ? null : <RRRedirect {...{ to }} />;
};

export default Redirect;
