import {
    registerHandler,
    unregisterHandler,
    waitMessage,
} from './postMessage.helpers';
import type { SendMessage } from './types';

export const unregisterPostMessageListener = () => {
    unregisterHandler();
};

export const registerPostMessageListener = () => {
    unregisterPostMessageListener();

    registerHandler();
};

export const sendPostMessage = <T, A = never>({
    message,
    callbackCondition,
    stayActive = false,
    timeout = 60000,
    iframeSelector = () => document.getElementsByTagName('iframe')?.[0],
    toParent = false,
    transfer,
}: SendMessage<T, A>): Promise<void | A> => {
    const iframe = toParent ? window.parent : iframeSelector()?.contentWindow;

    if (iframe) {
        if (transfer) {
            iframe.postMessage(message, '*', transfer);
        } else {
            iframe.postMessage(message, '*');
        }

        if (callbackCondition) {
            return waitMessage<A>(callbackCondition, stayActive, timeout);
        }

        return Promise.resolve();
    }

    return Promise.reject('Iframe was not found');
};
