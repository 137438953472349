import type { FC } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AnalyticsIcon } from './assets/analytics.svg';
import type { AnalyticsAppButtonType } from './types';
import { AppButton } from '../AppButton';

export const AnalyticsAppButton: FC<AnalyticsAppButtonType> = ({
    analyticsUrl,
    stateManagement,
}) => {
    const { t } = useTranslation();
    return (
        <AppButton
            name={t('APPS.ANALYTICS')}
            href={analyticsUrl}
            stateManagement={stateManagement}
        >
            <AnalyticsIcon />
        </AppButton>
    );
};

export default memo(AnalyticsAppButton);
