const zIndexes = {
    sideNavSub: 1401,
    sideNav: 1400,
    modal: 1000,
    tophat: 500,
    toast: 500,
    dropdown: 50,
    popper: 50,
    stickyHeader: 25,
    spinnerOverlay: 46,
    tooltip: 10,
};

export default zIndexes;
