import { AccountTypes } from './constants';
import { StorageKeys } from './types';
import type {
    IFullUserDetails,
    IRegionalSettings,
    IUserDetails,
    IRCDetails,
    StorageKeyValue,
    IRCBrandInfo,
} from './types';
import { Utils } from '../Utils';

export const defaultRegionalSettings: IRegionalSettings = {
    homeCountry: 1,
    homeCountryCode: '1',
    timeFormat: null,
    timezone: null,
    language: null,
    greetingLanguage: null,
    formattingLocale: null,
};

export const defaultRCDetail: IRCDetails = {
    rcPlatform: '',
    agentAssistantClientId: '',
    agentAssistantDomain: '',
    customerDataClientId: '',
    customerDataDomain: '',
    supervisorAssistantDomain: '',
    supervisorAssistantClientId: '',
    aiTrackerClientId: '',
    aiTrackerDomain: '',
};

export const defaultUserDetail: IUserDetails = {
    ...defaultRCDetail,
    redirectUrl: null,
    accessToken: '',
    platformId: '',
    refreshToken: '',
    tokenType: '',
    iqUrl: '',
    port: 0,
    adminId: null,
    rcUser: null,
    ssoLogin: false,
    adminUrl: null,
    agentUrl: null,
    analyticsUrl: null,
    mainAccountId: null,
    managedMainAccountIds: null,
    loginHashcode: null,
    agentDetails: null,
    passwordReset: false,
    userManagedByRC: false,
    availableWEM: false,
    availableRingSense: false,
    regionalSettings: defaultRegionalSettings,
    esu: false,
};

export const defaultFullUserDetails: IFullUserDetails = {
    accountType: AccountTypes.OMNICHANNEL,
    adminId: null,
    digitalAccountId: null,
    digitalAccountApiUrl: null,
    digitalAccountAdminUrl: null,
    digitalAccountEmbedUrl: null,
    agentDetails: null,
    rcUserId: null,
    rcAccountId: null,
    evMainAccountId: null,
    inboxModeEnabled: false,
    taskModeEnabled: false,
    digitalAdminEnabled: false,
    digitalAnalyticsEnabled: false,
    digitalAgentEnabled: false,
};

export const defaultBrandInfo: IRCBrandInfo = {
    id: '',
    name: '',
};

const makeKey = (key: string) => {
    const pfx = 'engage-auth:';
    return (pfx + key) as `${typeof pfx}${string}`;
};

export function set(key: StorageKeyValue, val: string): void {
    window.localStorage.setItem(makeKey(key), val);
    Utils.dispatchStorageEvent();
}

export function get(key: StorageKeyValue): string {
    return window.localStorage.getItem(makeKey(key)) || '';
}

function remove(key: StorageKeyValue): void {
    window.localStorage.removeItem(makeKey(key));
    Utils.dispatchStorageEvent();
}

/**
 * @ignore
 *
 * Service that abstracts logic for storing session info in localStorage.
 */
export default class {
    public static makeKey = makeKey;

    static getAccessToken(): string {
        return get(StorageKeys.ACCESS_TOKEN);
    }

    static setAccessToken(accessToken: string): void {
        set(StorageKeys.ACCESS_TOKEN, accessToken);
    }

    static getRefreshToken(): string {
        return get(StorageKeys.REFRESH_TOKEN);
    }

    static setRefreshToken(refreshToken: string): void {
        set(StorageKeys.REFRESH_TOKEN, refreshToken);
    }

    static getTokenType(): string {
        return get(StorageKeys.TOKEN_TYPE);
    }

    static setTokenType(tokenType: string): void {
        set(StorageKeys.TOKEN_TYPE, tokenType);
    }

    static getRCAccessToken(): string {
        return get(StorageKeys.RC_ACCESS_TOKEN);
    }

    static setRCAccessToken(rcAccessToken: string): void {
        set(StorageKeys.RC_ACCESS_TOKEN, rcAccessToken);
    }

    static getRCRefreshToken(): string {
        return get(StorageKeys.RC_REFRESH_TOKEN);
    }

    static setRCRefreshToken(rcRefreshToken: string): void {
        set(StorageKeys.RC_REFRESH_TOKEN, rcRefreshToken);
    }

    static getSharedDashboardToken(): string {
        return get(StorageKeys.SHARED_DASHBOARD_TOKEN);
    }

    static setSharedDashboardToken(sharedDashboardToken: string): void {
        set(StorageKeys.SHARED_DASHBOARD_TOKEN, sharedDashboardToken);
    }

    static getRCDetails(): IRCDetails {
        try {
            const rawRCDetails = get(StorageKeys.RC_DETAILS);

            if (!rawRCDetails) {
                return defaultRCDetail;
            }

            const rcDetails: IRCDetails = JSON.parse(rawRCDetails);
            return (Utils.isObject(rcDetails) && rcDetails) || defaultRCDetail;
        } catch (e) {
            window.console.error('Engage-Auth-JS: ', e);
            return defaultRCDetail;
        }
    }

    static setRCDetails(rcDetails: IRCDetails): void {
        set(StorageKeys.RC_DETAILS, JSON.stringify(rcDetails));
    }

    static getUserDetails(): IUserDetails {
        try {
            const rawUserDetails = get(StorageKeys.USER_DETAILS);

            if (rawUserDetails) {
                const userDetails: IUserDetails = JSON.parse(rawUserDetails);
                return (
                    (Utils.isObject(userDetails) && userDetails) ||
                    defaultUserDetail
                );
            }

            return defaultUserDetail;
        } catch (e) {
            window.console.error('Engage-Auth-JS: ', e);
            return defaultUserDetail;
        }
    }

    static getRcBrandInfo(): IRCBrandInfo {
        try {
            const rawBrandInfo = get(StorageKeys.RC_BRAND_INFO);

            if (rawBrandInfo) {
                const brandInfo: IRCBrandInfo = JSON.parse(rawBrandInfo);

                return Utils.isObject(brandInfo) ? brandInfo : defaultBrandInfo;
            }

            return defaultBrandInfo;
        } catch (e) {
            window.console.error('Engage-Auth-JS: ', e);
            return defaultBrandInfo;
        }
    }

    static setUserDetails(userDetails: IUserDetails): void {
        set(StorageKeys.USER_DETAILS, JSON.stringify(userDetails));
    }

    static setFullUserDetails(fullUserDetails: IFullUserDetails): void {
        set(StorageKeys.FULL_USER_DETAILS, JSON.stringify(fullUserDetails));
    }

    static setRcBrandInfo(brandInfo: IRCBrandInfo): void {
        set(StorageKeys.RC_BRAND_INFO, JSON.stringify(brandInfo));
    }

    static getFullUserDetails(): IFullUserDetails {
        try {
            const rawFullUserDetails = get(StorageKeys.FULL_USER_DETAILS);

            if (rawFullUserDetails) {
                const fullUserDetails: IFullUserDetails =
                    JSON.parse(rawFullUserDetails);
                return (
                    (Utils.isObject(fullUserDetails) && fullUserDetails) ||
                    defaultFullUserDetails
                );
            }

            return defaultFullUserDetails;
        } catch (e) {
            window.console.error('Engage-Auth-JS: ', e);
            return defaultFullUserDetails;
        }
    }

    static clearSharedKey(): void {
        remove(StorageKeys.SHARED_DASHBOARD_TOKEN);
    }

    static clearSession(): void {
        const sharedKeys = [StorageKeys.SHARED_DASHBOARD_TOKEN];
        const allKeys = Object.values(StorageKeys);

        const correctKeys = allKeys.filter((key) => !sharedKeys.includes(key));
        correctKeys.forEach(remove);
    }
}
