import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useMemo } from 'react';

import { Session, SessionType, BYOT_RCX_CLIENT_APP_TYPE } from '@ringcx/shared';
import {
    useAppVersionNotification,
    useBrowserVersionNotification,
} from '@ringcx/ui';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router-dom';

import { Container } from './MainLayout.styled';
import { LoadingPlaceholder } from '../../components/LoadingPlaceholder';
import { Redirect } from '../../components/Redirect';
import { SUPPORTED_BROWSERS } from '../../constants/links';
import {
    ROOT,
    SELECT_AGENT,
    LEGACY_LOGIN_ALL,
    LEGACY_LOGIN_ADMIN,
    LEGACY_LOGIN_AGENT,
    FORGOT_PASSWORD_ALL,
    LEGACY_LOGIN_ANALYTICS,
    SHARED,
} from '../../constants/paths';
import {
    Login,
    AppPicker,
    AgentPicker,
    ForgotPassword,
    LandingPage,
    Shared,
} from '../../containers';
import { Error404 } from '../../containers/Error404';
import { ErrorPage } from '../../containers/ErrorPage';
import { AppContext, AuthStates } from '../../contexts';
import {
    logoutAndSendFailToByot,
    sendTokenToByot,
} from '../../helpers/sendTokenToByot';
import { useQuery } from '../../hooks';

const MainLayout: FC = () => {
    const { location, replace } = useHistory();

    const error = useQuery('error');
    const { enqueueSnackbar } = useSnackbar();
    const {
        wemUrl,
        agentUrl,
        adminUrl,
        authState,
        redirectUrl,
        sessionType,
        permissions,
        analyticsUrl,
        agentDetails,
        ringSenseUrl,
    } = useContext(AppContext);
    // Clean up hash router page ending
    useLayoutEffect(() => {
        if (location.hash) {
            replace({
                ...location,
                hash: '',
            });
        }
    }, [location, replace]);

    const isAuthenticated = authState === AuthStates.AUTHENTICATED;
    const isNotAuthenticated = authState === AuthStates.NOT_AUTHENTICATED;

    const { t } = useTranslation();

    useLayoutEffect(() => {
        if (isAuthenticated && redirectUrl) {
            Session.clearRedirectUrl();
            window.location.assign(redirectUrl);
        }
    }, [isAuthenticated, redirectUrl]);

    const browserVersionConfig = useMemo(
        () => ({
            unsupportedBrowser: {
                text: t('NOTIFICATIONS.UNSUPPORTED_BROWSER'),
                options: {
                    primary: {
                        actionTitle: t('NOTIFICATIONS.SUPPORTED_BROWSERS'),
                        external: true,
                        action: () => window.open(SUPPORTED_BROWSERS, '_blank'),
                    },
                },
            },
            unsupportedVersion: t('NOTIFICATIONS.UNSUPPORTED_BROWSER_VERSION'),
        }),
        [t]
    );

    useBrowserVersionNotification(browserVersionConfig);

    const appVersionConfig = useMemo(
        () => ({
            urgentDifference: {
                text: t('NOTIFICATIONS.ROLLBACK'),
                options: {
                    primary: {
                        actionTitle: t('GENERICS.RELOAD'),
                        action: () => window.location.reload(),
                    },
                },
            },
            satisfiedDifference: {
                text: t('NOTIFICATIONS.NEW_VERSION'),
                options: {
                    primary: {
                        actionTitle: t('GENERICS.RELOAD'),
                        action: () => window.location.reload(),
                    },
                },
            },
        }),
        [t]
    );
    useAppVersionNotification(appVersionConfig);
    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top',
                },
                persist: true,
            });
        }
    }, [error, enqueueSnackbar]);
    const renderLegacyLogin = () => {
        if (isAuthenticated) {
            if (sessionType === SessionType.NONE) {
                return (
                    <Route path={LEGACY_LOGIN_ALL}>
                        <Redirect to={ROOT} />
                    </Route>
                );
            }

            return [
                <Route key={1} path={LEGACY_LOGIN_AGENT}>
                    {sessionType === SessionType.AGENT ? (
                        <Redirect to={ROOT} />
                    ) : (
                        <Login />
                    )}
                </Route>,
                <Route key={2} path={LEGACY_LOGIN_ADMIN}>
                    {sessionType === SessionType.ANALYTICS ? (
                        <Redirect to={adminUrl} />
                    ) : (
                        <Redirect to={ROOT} />
                    )}
                </Route>,
                <Route key={3} path={LEGACY_LOGIN_ANALYTICS}>
                    {sessionType === SessionType.ADMIN ? (
                        <Redirect to={analyticsUrl} />
                    ) : (
                        <Redirect to={ROOT} />
                    )}
                </Route>,
            ];
        }

        return <Route path={LEGACY_LOGIN_ALL} component={Login} />;
    };

    const renderForgotPassword = () => {
        if (isAuthenticated) {
            return (
                <Route path={FORGOT_PASSWORD_ALL}>
                    <Redirect to={ROOT} />
                </Route>
            );
        }

        return <Route path={FORGOT_PASSWORD_ALL} component={ForgotPassword} />;
    };

    const renderShared = () => {
        return <Route path={SHARED} component={Shared} />;
    };

    const renderSelectAgent = () => {
        if (isAuthenticated) {
            if (agentDetails.length > 1) {
                return <Route path={SELECT_AGENT} component={AgentPicker} />;
            }

            if (Session.rcxClientAppType === BYOT_RCX_CLIENT_APP_TYPE) {
                sendTokenToByot();
            }

            return (
                <Route path={SELECT_AGENT}>
                    <Redirect to={ROOT} />
                </Route>
            );
        }

        return null;
    };

    const renderRoot = () => {
        if (!isAuthenticated) {
            return <LandingPage />;
        }

        switch (sessionType) {
            case SessionType.ADMIN:
                return <Redirect to={adminUrl} />;

            case SessionType.AGENT:
                return <Redirect to={agentUrl} />;

            case SessionType.ANALYTICS:
                return <Redirect to={analyticsUrl} />;

            case SessionType.NONE: {
                const availableAppNumber =
                    Object.values(permissions).filter(Boolean).length;

                if (availableAppNumber === 1) {
                    if (permissions.Agent) {
                        return <Redirect to={agentUrl} />;
                    } else if (permissions.Admin) {
                        return <Redirect to={adminUrl} />;
                    } else if (permissions.Analytics) {
                        return <Redirect to={analyticsUrl} />;
                    } else if (permissions.WEMEnterprise) {
                        return <Redirect to={wemUrl} />;
                    } else if (permissions.RingSense) {
                        return <Redirect to={ringSenseUrl} />;
                    }
                }

                if (availableAppNumber === 0) {
                    if (Session.rcxClientAppType === BYOT_RCX_CLIENT_APP_TYPE) {
                        logoutAndSendFailToByot();
                        return null;
                    } else if (
                        Session.isCRMClientAppType() ||
                        Session.isCRMAdminClientAppType()
                    ) {
                        return (
                            <ErrorPage
                                isCRMAdminClient={Session.isCRMAdminClientAppType()}
                            />
                        );
                    }
                }

                return <AppPicker />;
            }
        }
    };

    return (
        <Container>
            {isAuthenticated || isNotAuthenticated ? (
                <Switch>
                    <Route exact path={ROOT}>
                        {renderRoot()}
                    </Route>

                    {renderShared()}

                    {renderLegacyLogin()}

                    {renderForgotPassword()}

                    {renderSelectAgent()}
                    <Route component={Error404} />
                </Switch>
            ) : (
                <LoadingPlaceholder />
            )}
        </Container>
    );
};

export default MainLayout;
