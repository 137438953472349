import type { FC, PropsWithChildren, ReactElement } from 'react';

import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { ButtonWrapper, ButtonTheme, StyledSpinnerWrap } from './Button.styled';
import type { IStyledButtonProps } from './types';
import type { ISpinnerProps } from '../Spinner';
import Spinner from '../Spinner';

const StyledSpinner = (props: ISpinnerProps): ReactElement => (
    <StyledSpinnerWrap>
        <Spinner {...props} />
    </StyledSpinnerWrap>
);

const Button: FC<PropsWithChildren<IStyledButtonProps>> = ({
    children,
    action,
    disabled = false,
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    disableElevation = true,
    loading = false,
    ...props
}) => (
    <ButtonWrapper
        {...{
            action,
            variant,
            size,
            color,
            disabled,
            disableElevation,
            ...props,
        }}
    >
        {loading ? <StyledSpinner size='small' /> : children}
    </ButtonWrapper>
);
const WithStyledButton = withStyles(ButtonTheme)(Button);

export default styled(WithStyledButton)``;
