import {
    DAYS_TO_KEEP,
    INDEX_DTS,
    KEY_DTS,
    MAX_RECORDS_LIMIT,
} from './constants';
import {
    buildLogRecord,
    createRangesPredicate,
    promisifyIDBRequest,
} from './helpers';
import type {
    AdditionalFields,
    ILogStorage,
    LogIndexDescriptor,
    LogRecord,
    LogStorageGetRecordsParams,
    LogStorageParams,
} from './types';

class NullLogStorage<T extends LogRecord = LogRecord>
    implements ILogStorage<T>
{
    public addRecord() {
        return Promise.resolve();
    }

    public getRecords() {
        return Promise.resolve([]);
    }

    public clearRecords() {
        return Promise.resolve();
    }

    public purgeOldRecords() {
        return Promise.resolve();
    }

    public deleteDatabase() {
        return Promise.resolve();
    }
}

export class LogStorage<T extends LogRecord = LogRecord>
    implements ILogStorage<T>
{
    protected db: Nullable<IDBDatabase> = null;
    protected readonly loggerName: string;
    protected readonly dbVersion: number;
    protected readonly storeName: string;
    protected readonly indices: LogIndexDescriptor<T>[];
    protected readonly purgeOld: boolean;
    protected readonly daysToKeep: number;

    public static async create<T extends LogRecord = LogRecord>(
        params: LogStorageParams<T>,
        onStart?: (storage: ILogStorage<T>) => void
    ): Promise<ILogStorage<T>> {
        const storage = new this(params);

        try {
            await storage.initialize(onStart);
        } catch (error) {
            console.warn(`${params.loggerName}: Initialization error`, error);

            return new NullLogStorage();
        }

        return storage;
    }

    protected constructor({
        loggerName,
        dbVersion,
        storeName,
        indices = [],
        purgeOld = true,
        daysToKeep = DAYS_TO_KEEP,
    }: LogStorageParams<T>) {
        this.loggerName = loggerName;
        this.dbVersion = dbVersion;
        this.storeName = storeName;
        this.indices = indices;
        this.purgeOld = purgeOld;
        this.daysToKeep = daysToKeep;
    }

    public async addRecord(
        args: unknown[],
        extras: AdditionalFields<T, LogRecord>
    ) {
        const objectStore = await this.getObjectStore('readwrite');

        await promisifyIDBRequest(
            objectStore.add(buildLogRecord(args, extras))
        );
    }

    public async getRecords({
        index,
        query,
        ranges = [],
        filterPredicate = () => true,
        limit = MAX_RECORDS_LIMIT,
    }: LogStorageGetRecordsParams<T>) {
        const objectStore = await this.getObjectStore('readonly');

        const cursorRequest = objectStore
            .index(index)
            .openCursor(query, 'prev');

        const rangesPredicate = createRangesPredicate(...ranges);

        return new Promise<T[]>((resolve, reject) => {
            const records: T[] = [];

            cursorRequest.onsuccess = () => {
                const cursor = cursorRequest.result;

                if (cursor && records.length < limit) {
                    const record = cursor.value as T;

                    if (rangesPredicate(record) && filterPredicate(record)) {
                        records.push(record);
                    }

                    cursor.continue();
                } else {
                    resolve(records);
                }
            };

            cursorRequest.onerror = () => {
                this.logError('Error getting records', cursorRequest.error);

                reject(cursorRequest.error);
            };
        });
    }

    public async purgeOldRecords() {
        const objectStore = await this.getObjectStore('readwrite');

        const cursorRequest = objectStore
            .index(INDEX_DTS)
            .openCursor(this.getOldRecordsQuery());

        return new Promise<void>((resolve, reject) => {
            cursorRequest.onsuccess = () => {
                const cursor = cursorRequest.result;
                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                } else {
                    resolve();
                }
            };

            cursorRequest.onerror = () => {
                this.logError('Error purging old records', cursorRequest.error);

                reject(cursorRequest.error);
            };
        });
    }

    public async clearRecords() {
        const objectStore = await this.getObjectStore('readwrite');

        await promisifyIDBRequest(objectStore.clear());

        this.logMessage('Logger database cleared');
    }

    public async deleteDatabase() {
        if (!this.isInitialized()) {
            throw new Error('Database not initialized');
        }

        this.db.close();

        await promisifyIDBRequest(
            window.indexedDB.deleteDatabase(this.loggerName)
        );

        this.logMessage('Logger database deleted');
    }

    protected async initialize(onStart?: (storage: ILogStorage<T>) => void) {
        await this.openDatabase((db) => {
            this.logMessage('Upgrading database');

            if (!db.objectStoreNames.contains(this.storeName)) {
                const objectStore = db.createObjectStore(this.storeName, {
                    autoIncrement: true,
                });

                [
                    { name: INDEX_DTS, keyPath: KEY_DTS },
                    ...this.indices,
                ].forEach((index) => {
                    objectStore.createIndex(index.name, index.keyPath, {
                        unique: false,
                    });
                });
            }
        });

        this.logMessage('Logging database opened');

        if (this.purgeOld) {
            await this.purgeOldRecords();
        }

        onStart?.(this);
    }

    private async openDatabase(onUpgrade?: (db: IDBDatabase) => void) {
        if (!('indexedDB' in window)) {
            throw new Error('IndexedDB not supported');
        }

        const dbRequest = window.indexedDB.open(
            this.loggerName,
            this.dbVersion
        );

        dbRequest.onupgradeneeded = () => {
            onUpgrade?.(dbRequest.result);
        };

        this.db = await promisifyIDBRequest(dbRequest);
    }

    public isInitialized(): this is { db: IDBDatabase } {
        return !!this.db;
    }

    protected async getObjectStore(mode: IDBTransactionMode | undefined) {
        if (!this.isInitialized()) {
            throw new Error('Database not initialized');
        }

        let transaction: Nullable<IDBTransaction> = null;

        for (let attempts = 2; attempts > 0; attempts--) {
            try {
                transaction = this.db.transaction([this.storeName], mode);
                break;
            } catch (e: any) {
                if (e.name !== 'InvalidStateError') {
                    throw e;
                }

                await this.openDatabase();
            }
        }

        if (!transaction) {
            throw new Error('Cannot reopen database');
        }

        return transaction.objectStore(this.storeName);
    }

    protected getOldRecordsQuery() {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - this.daysToKeep);
        endDate.setHours(0, 0, 0, 0);

        return IDBKeyRange.upperBound(endDate);
    }

    protected logMessage(message: string, ...optionalParams: any[]) {
        console.log(`${this.loggerName}: ${message}`, ...optionalParams);
    }

    protected logError(message: string, ...optionalParams: any[]) {
        console.error(`${this.loggerName}: ${message}`, ...optionalParams);
    }
}
