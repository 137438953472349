import type {
    AnalyticsProvider,
    InitializeEvent,
    RegisterOptions,
} from '../../types';

const style = 'color: #235253;';

const localProvider: AnalyticsProvider = {
    loadProvider(): void {
        window.console.info('%cAnalytics: loaded', style);
    },
    initialize(options: InitializeEvent): void {
        window.console.info('%cAnalytics: initialized user %o', style, options);
    },
    register(info: RegisterOptions): void {
        window.console.info('%cAnalytics: register', style, info);
    },
    track(eventName: string, properties: object): void {
        window.console.info('%cAnalytics: track', style, {
            eventName,
            properties,
        });
    },
    reset(): void {
        window.console.info('%cAnalytics: reset', style);
    },
    alias(existingAliasId: string, otherDistinctId: string): void {
        window.console.info('%cAnalytics: alias', style, {
            existingAliasId,
            otherDistinctId,
        });
    },
    getDistinctId(): string {
        return '';
    },
};

export default localProvider;
