// Because EAC based on root level - we need provide overwritten routes to webpack dev server
// See OVERWRITTEN_APP_ROUTES in config/paths.js

export const ADMIN = 'admin';
export const AGENT = 'agent';
export const ANALYTICS = 'analytics';

export const ROOT = '/';

export const SSO_LOGIN = '/oauthredirect';

export const LEGACY_LOGIN = '/login/';
export const LEGACY_LOGIN_ADMIN = `${LEGACY_LOGIN}:app(${ADMIN})`;
export const LEGACY_LOGIN_AGENT = `${LEGACY_LOGIN}:app(${AGENT})`;
export const LEGACY_LOGIN_ANALYTICS = `${LEGACY_LOGIN}:app(${ANALYTICS})`;
export const LEGACY_LOGIN_ALL = `${LEGACY_LOGIN}:app(${ADMIN}|${AGENT}|${ANALYTICS})`;

export const FORGOT_PASSWORD = '/forgotPassword/';
export const FORGOT_PASSWORD_ALL = `${FORGOT_PASSWORD}:app(${ADMIN}|${ANALYTICS})`;

export const SHARED = '/shared';

export const SELECT_AGENT = `/select/${AGENT}`;
export const GET_PLATFORM_ENDPOINT =
    '/common/api/public/aps/v1.0/voice/platforms';

export const VERSION_KEY = 'version=';
