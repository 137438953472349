import MuiButton from '@material-ui/core/Button';
import { Close as MuiClose } from '@material-ui/icons';
import styled from 'styled-components';

export const Button = styled(MuiButton)`
    border-radius: 50%;
    padding: 5px;
    min-width: 0;
`;

export const CloseIcon = styled(MuiClose)`
    color: ${({ theme }) => theme.colors.gray[0]};
`;
