export const WHATS_NEW_LINK =
    'https://support.ringcentral.com/release-notes/customer-engagement/ringCX/';
export const ONLINE_TRAINING_LINK =
    'https://university.ringcentral.com/page/en-rcx-ringcx';

export const SUPPORTED_BROWSERS =
    'https://support.ringcentral.com/article-v2/RingCX-system-requirement-specifications.html?brand=RingCentral&product=RingCX';
export const RC_SUBMIT_AN_IDEA =
    'https://ideas.ringcentral.com/forums/958496-contact-center-ringcx';
export const TERMS_OF_SERVICE =
    'https://www.ringcentral.com/legal/eulatos.html';
export const PRIVACY_NOTICE =
    'https://www.ringcentral.com/legal/privacy-notice.html';

export const SHARED_ANALYTICS_PATHNAME = '/analytics/voice/shared';
