import { Link } from '@material-ui/core';
import styled, { css } from 'styled-components';

import type { LinkIconPositionEnum } from './types/LinkButton';

export const StyledIconWrapper = styled.div<{
    iconPosition: LinkIconPositionEnum;
    disabled: boolean;
}>`
    && {
        display: flex;
        align-items: center;
        justify-self: center;

        i {
            font-size: 16px;
        }

        ${({ iconPosition }) =>
            iconPosition === 'right'
                ? css`
                      margin-left: 6px;
                  `
                : css`
                      margin-right: 6px;
                  `};
        ${({ theme, disabled }) =>
            disabled &&
            css`
                color: ${theme.colors.gray[300]};
            `}
    }
`;

export const StyledLabelWrapper = styled.div``;

export const StyledLinkButton = styled(Link)<{
    disabled: boolean;
    color?: string;
}>`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: 0.15px;
        color: ${({ color, theme }) =>
            `var(--link-button, ${
                color ? theme.colors[color] : theme.colors.primary
            })`};

        ${({ theme, disabled, color }) => {
            if (disabled) {
                return css`
                    color: ${theme.colors.gray[400]};
                    cursor: default;
                    text-decoration: none;
                    outline: none;
                    pointer-events: none;
                `;
            }
            return css`
                &:hover,
                &:active,
                &:focus {
                    cursor: pointer;
                    color: var(
                        --link-button-hover,
                        ${color ? theme.colors[color] : theme.colors.primary}
                    );
                    text-decoration: none;

                    &:not(:focus) {
                        ${StyledLabelWrapper} {
                            text-decoration: underline;
                        }
                    }
                }
            `;
        }}
    }
`;
