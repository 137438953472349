import type { FC, MouseEventHandler } from 'react';

import { TextContainer } from './SubmittedMessage.styled';

const SubmittedMessage: FC<{
    email: string;
    onBack: MouseEventHandler<HTMLAnchorElement>;
}> = ({ email, onBack }) => {
    return (
        <TextContainer data-aid='success'>
            <p>
                We sent an email confirmation to <b>{email}</b>. Follow the
                instructions in the email to reset your password.
            </p>
            <p>
                If you haven&apos;t received an email, make sure that the email
                address provided is associated with a valid account.
            </p>
            <p>
                Wrong email? Please{' '}
                <a href='#' onClick={onBack}>
                    re-enter your address.
                </a>
            </p>
        </TextContainer>
    );
};

export default SubmittedMessage;
