import type { FC, SyntheticEvent } from 'react';
import { useMemo } from 'react';

import { TagBorder, TagCloseButton, TagText } from './Tag.styled';
import type { ITagProps } from './types';
import { TextEclipse } from '../TextEclipse';

const Tag: FC<ITagProps> = ({
    color,
    text,
    onClick,
    onClose,
    disabled,
    bordered,
    eclipsable = true,
}) => {
    const renderText = useMemo(() => {
        const handler = (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            return !disabled && onClick && onClick();
        };
        const textElement = <TagText onClick={handler}>{text}</TagText>;

        if (eclipsable) {
            return <TextEclipse tooltipMsg={text}>{textElement}</TextEclipse>;
        }

        return textElement;
    }, [disabled, eclipsable, onClick, text]);

    const renderCloseButton = useMemo(() => {
        if (!onClose) {
            return null;
        }

        const handler = (e: SyntheticEvent<SVGSVGElement, MouseEvent>) => {
            e.stopPropagation();
            return !disabled && onClose && onClose();
        };
        return <TagCloseButton onClick={handler} />;
    }, [disabled, onClose]);

    return (
        <TagBorder
            color={color}
            bordered={bordered}
            disabled={disabled}
            clickable={!!onClick}
            closable={!!onClose}
            eclipsable={eclipsable}
        >
            {renderText}
            {renderCloseButton}
        </TagBorder>
    );
};

export default Tag;
