// This fabric need for correct work with module closure especially for reset them to every test run
import type { CacheValue } from './types';
import { isObject, isFunction } from '../../helpers';

const bigPapaCache: Record<symbol, CacheValue> = {};

function _setCacheValue(symbol: symbol, originalValue: unknown) {
    // Primitives handle
    let value = originalValue;

    if (isFunction(value)) {
        // Function handle
        value = value();
    } else if (Array.isArray(value)) {
        // Array handle
        value = [...value];
    } else if (isObject(value)) {
        // Object handle
        value = {
            ...value,
        };
    }

    bigPapaCache[symbol] = {
        value,
        originalValue,
    };
}

export function createModuleClosure<T>(value: T | (() => T)): {
    value: T;
} {
    const uniqueSymbol = Symbol();

    _setCacheValue(uniqueSymbol, value);

    return Object.defineProperty({}, 'value', {
        get() {
            return bigPapaCache[uniqueSymbol].value;
        },
        set(data) {
            bigPapaCache[uniqueSymbol].value = data;
        },
    }) as {
        value: T;
    };
}

export function clearAllModuleClosures() {
    Object.getOwnPropertySymbols(bigPapaCache).forEach((key) => {
        _setCacheValue(key, bigPapaCache[key].originalValue);
    });
}
