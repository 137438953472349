import type { BrowserInfo, DetectedInfoType } from 'detect-browser';
import { detect } from 'detect-browser';

import { SupportedBrowsers } from './types/BrowserVersion';
import type { SupportInfo } from './types/SupportInfo';

interface LabeledInfo {
    type: DetectedInfoType;
}

function isBrowser(info: LabeledInfo | null): info is BrowserInfo {
    if (info === null) {
        return false;
    }

    return info.type === 'browser';
}

export const filterUnsupportedBrowsers = (
    allVersions: string[],
    unSupportedBrowserVersions?: string[]
): string[] => {
    if (
        !unSupportedBrowserVersions ||
        unSupportedBrowserVersions.length === 0
    ) {
        return allVersions;
    }

    const unsupportedSet = new Set(
        unSupportedBrowserVersions.map((b) => b.toLowerCase())
    );

    return allVersions.filter((version) => {
        const [browserName] = version.toLowerCase().split(' ');
        return (
            !unsupportedSet.has(browserName) &&
            !unsupportedSet.has(version.toLowerCase())
        );
    });
};

export const getSupportedBrowserVersions = (
    unSupportedBrowserVersions?: string[]
): string[] | null => {
    const allSupportedVersions =
        window?.__settings?.supportedBrowserVersions ||
        ENGAGE_SUPPORTED_BROWSER_VERSIONS;

    if (!allSupportedVersions?.length) {
        window.console.warn(
            'ring-cx-versioning:: Supported browser versions list is empty or undefined.'
        );
        return null;
    }

    return filterUnsupportedBrowsers(
        allSupportedVersions,
        unSupportedBrowserVersions
    );
};

export function getBrowserInfo(
    unSupportedBrowserVersions?: string[]
): SupportInfo | null {
    const versions = getSupportedBrowserVersions(unSupportedBrowserVersions);
    const browser = detect();

    if (!versions || !isBrowser(browser)) {
        return null;
    }

    const supportInfo = SupportedBrowsers.listCompare(
        versions,
        browser.name,
        browser.version
    );

    return {
        name: browser.name,
        version: browser.version,
        ...supportInfo,
    };
}
