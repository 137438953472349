import {
    RCX_CLIENT_ID,
    RCX_CLIENT_APP_TYPE,
    BYOT_RCX_CLIENT_APP_TYPE,
    ZOHO_RCX_CLIENT_APP_TYPE,
    HUBSPOT_RCX_CLIENT_APP_TYPE,
    DEFAULT_RCX_CLIENT_APP_TYPE,
    ZENDESK_RCX_CLIENT_APP_TYPE,
    NETSUITE_RCX_CLIENT_APP_TYPE,
    FRESHDESK_RCX_CLIENT_APP_TYPE,
    SALESFORCE_RCX_CLIENT_APP_TYPE,
    SERVICE_NOW_RCX_CLIENT_APP_TYPE,
    DYNAMICS365_RCX_CLIENT_APP_TYPE,
    FRESHSERVICE_RCX_CLIENT_APP_TYPE,
    EMBEDDED_ADMIN_RCX_CLIENT_APP_TYPE,
    EMBEDDED_AGENT_RCX_CLIENT_APP_TYPE,
    WHITELABEL_CRM_RCX_CLIENT_APP_TYPE,
    INITIAL_URL_QUERY_STRING_CACHE_KEY,
    SALESFORCE_ADMIN_RCX_CLIENT_APP_TYPE,
    EMBEDDED_AUTH_WEM_RCX_CLIENT_APP_TYPE,
    EMBEDDED_ANALYTICS_RCX_CLIENT_APP_TYPE,
    EMBEDDED_AUTH_RINGSENSE_RCX_CLIENT_APP_TYPE,
} from './constants';
import type {
    IAccessTokenResult,
    IAuthHeader,
    IContentTypeHeader,
    IGetDigitalJWT,
} from './types';
import { DigitalJWTModifier, LoginApp, SessionType } from './types';
import { getDigitalJWT, setDigitalJWT } from '../helpers/digitalJWTCache';
import { Navigation } from '../Navigation';
import { HttpService } from '../services/http.service';
import { PromiseCacheService } from '../services/promise-cache.service';
import StorageService from '../services/storage.service';
import type { IRCDetails, IUserDetails, PromiseObj } from '../services/types';
import type {
    IClaims,
    ICredentials,
    IDigitalClaims,
    IUserInfo,
} from '../types';
import { Utils } from '../Utils';

export function getDefaultContentTypeHeader(): IContentTypeHeader {
    return {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
}

/**
 * @ignore
 * Stores session information provided by Engage Auth server. This includes the accessToken, refreshToken, and other user information.
 *
 * @param {Object} Object representing the serialized responseText from HttpService. This object should of the form `{"response": string}`
 */
function storeAccessTokenResult({
    response: responseText,
}: IAccessTokenResult): void {
    try {
        const resp: IUserDetails = JSON.parse(responseText);

        StorageService.setAccessToken(resp.accessToken || '');
        delete resp.accessToken;

        StorageService.setRefreshToken(resp.refreshToken || '');
        delete resp.refreshToken;

        StorageService.setTokenType(resp.tokenType || '');
        delete resp.tokenType;

        delete resp.rcAccessToken;
        delete resp.rcRefreshToken;

        const { redirectUrl } = StorageService.getUserDetails();

        // Prevent an unused redirect URL we have in local storage from being
        // overridden by an empty redirect URL sent back from the server
        if (
            typeof redirectUrl === 'string' &&
            redirectUrl.length > 0 &&
            !resp.redirectUrl
        ) {
            resp.redirectUrl = redirectUrl;
        }

        StorageService.setUserDetails(resp);
    } catch (e) {
        throw new Error("Couldn't deserialize server response!");
    }
}

export function storeRCAccessTokenResult({
    response: responseText,
}: IAccessTokenResult): void {
    try {
        const resp: IUserDetails = JSON.parse(responseText);

        StorageService.setRCAccessToken(resp.rcAccessToken || '');
        StorageService.setRCRefreshToken(resp.rcRefreshToken || '');

        const rcDetails: IRCDetails = {
            agentAssistantClientId: resp.agentAssistantClientId,
            agentAssistantDomain: resp.agentAssistantDomain,
            customerDataClientId: resp.customerDataClientId,
            customerDataDomain: resp.customerDataDomain,
            rcPlatform: resp.rcPlatform,
            supervisorAssistantClientId: resp.supervisorAssistantClientId,
            supervisorAssistantDomain: resp.supervisorAssistantDomain,
            aiTrackerClientId: resp.aiTrackerClientId,
            aiTrackerDomain: resp.aiTrackerDomain,
        };

        StorageService.setRCDetails(rcDetails);
    } catch (e) {
        throw new Error("Couldn't deserialize server response!");
    }
}

/**
 * The session module provides APIs to initialize an EngageAuth session and to retrieve details of said session.
 */
export class Session {
    static rcxClientAppType = DEFAULT_RCX_CLIENT_APP_TYPE;
    static initialUrlQueryString: string | null;

    /**
     * Returns boolean value indicating whether the current client app working in default mode
     *
     * @returns {boolean} Represents whether the current client app is in default mode.
     */
    static isDefaultClientAppType(): boolean {
        return Session.rcxClientAppType === DEFAULT_RCX_CLIENT_APP_TYPE;
    }

    /**
     * Returns boolean value indicating whether the current client app working in embedded agent mode
     *
     * @returns {boolean} Represents whether the current client app is in embedded agent mode.
     */
    static isEmbeddedAgentClientAppType(): boolean {
        return Session.rcxClientAppType === EMBEDDED_AGENT_RCX_CLIENT_APP_TYPE;
    }

    /**
     * Returns boolean value indicating whether the current client app working in embedded admin mode
     *
     * @returns {boolean} Represents whether the current client app is in embedded admin mode.
     */
    static isEmbeddedAdminClientAppType(): boolean {
        return Session.rcxClientAppType === EMBEDDED_ADMIN_RCX_CLIENT_APP_TYPE;
    }

    /**
     * Returns boolean value indicating whether the current client app working in embedded analytics mode
     *
     * @returns {boolean} Represents whether the current client app is in embedded analytics mode.
     */
    static isEmbeddedAnalyticsClientAppType(): boolean {
        return (
            Session.rcxClientAppType === EMBEDDED_ANALYTICS_RCX_CLIENT_APP_TYPE
        );
    }

    /**
     * Returns boolean value indicating whether the current client app working in embedded auth wem mode
     *
     * @returns {boolean} Represents whether the current client app is in embedded analytics mode.
     */
    static isEmbeddedAuthWEMClientAppType(): boolean {
        return (
            Session.rcxClientAppType === EMBEDDED_AUTH_WEM_RCX_CLIENT_APP_TYPE
        );
    }

    /**
     * Returns boolean value indicating whether the current client app working in embedded auth ringsense mode
     *
     * @returns {boolean} Represents whether the current client app is in embedded analytics mode.
     */
    static isEmbeddedAuthRingSenseClientAppType(): boolean {
        return (
            Session.rcxClientAppType ===
            EMBEDDED_AUTH_RINGSENSE_RCX_CLIENT_APP_TYPE
        );
    }

    /**
     * Returns boolean value indicating whether the current client app working in crm mode
     *
     * @returns {boolean} Represents whether the current client app is in crm mode.
     */
    static isCRMClientAppType(): boolean {
        switch (Session.rcxClientAppType) {
            case ZENDESK_RCX_CLIENT_APP_TYPE:
            case SALESFORCE_RCX_CLIENT_APP_TYPE:
            case DYNAMICS365_RCX_CLIENT_APP_TYPE:
            case ZOHO_RCX_CLIENT_APP_TYPE:
            case HUBSPOT_RCX_CLIENT_APP_TYPE:
            case SERVICE_NOW_RCX_CLIENT_APP_TYPE:
            case WHITELABEL_CRM_RCX_CLIENT_APP_TYPE:
            case NETSUITE_RCX_CLIENT_APP_TYPE:
            case FRESHDESK_RCX_CLIENT_APP_TYPE:
            case FRESHSERVICE_RCX_CLIENT_APP_TYPE:
            case BYOT_RCX_CLIENT_APP_TYPE:
                return true;

            default:
                return false;
        }
    }

    /**
     * Returns boolean value indicating whether the current client app working in crm admin mode
     *
     * @returns {boolean} Represents whether the current client app is in crm admin mode.
     */
    static isCRMAdminClientAppType(): boolean {
        return [SALESFORCE_ADMIN_RCX_CLIENT_APP_TYPE].includes(
            Session.rcxClientAppType
        );
    }

    /**
     * Returns boolean value indicating whether the user is logged in or not.
     *
     * @returns {boolean} Represents logged in state of user. If we have a non-empty refreshToken in storage,
     * the method will return true. Otherwise, it returns false.
     */
    static isLoggedIn(): boolean {
        return StorageService.getRefreshToken().length > 0;
    }

    /**
     * Returns information we have in storage about the current user.
     *
     * @returns {IUserDetails} Object containing user details.
     */
    static getUserDetails(): IUserDetails {
        return StorageService.getUserDetails();
    }

    static getRCDetails(): IRCDetails {
        return StorageService.getRCDetails();
    }

    /**
     * Returns an object specifying the header needed for authorizing HTTP requests.
     *
     * @returns {Object} Object containing header information for authenticating requests. The key is the intended HTTP header name,
     * and the value is the header value. Ex: `{"Authorization": "Bearer 123.345.456"}`
     */
    static getAuthHeader(): IAuthHeader {
        return {
            Authorization: `${StorageService.getTokenType()} ${StorageService.getAccessToken()}`,
        };
    }

    static getRCAuthHeader(): IAuthHeader {
        return {
            Authorization: `${StorageService.getTokenType()} ${StorageService.getRCAccessToken()}`,
        };
    }

    /**
     * Returns the X-Auth-Token embedded in the access token. If there is no X-Auth-Token embedded, undefined is
     * returned instead.
     *
     * @returns {String|undefined}
     */
    static getXAuthToken(): string {
        return Utils.getClaimsFromToken<IClaims>(
            StorageService.getAccessToken()
        ).xauth;
    }

    /**
     * Returns the access token currently stored in local storage.
     *
     * @returns {String}
     */
    static getAccessToken(): IUserDetails['accessToken'] {
        return StorageService.getAccessToken();
    }

    /**
     * Makes an HTTP GET request to validate our current access token.
     *
     * @returns {Promise} Promise that represents our HTTP request. Resolves if our accessToken in storage is valid, gets rejected if
     * the token is invalid.
     */
    static validateToken(): Promise<string> {
        PromiseCacheService.setIfEmpty(
            PromiseCacheService.KEYS.VALIDATE_TOKEN,
            () =>
                HttpService.get('/api/auth/token/validate', {
                    headers: {
                        ...getDefaultContentTypeHeader(),
                        ...this.getAuthHeader(),
                    },
                })
        );

        return PromiseCacheService.get(PromiseCacheService.KEYS.VALIDATE_TOKEN);
    }

    /**
     * Return a session type based on the claims in the user's access token. Throws an error if we couldn't
     * determine the session type.
     *
     * @returns {SessionType.ADMIN | SessionType.AGENT | SessionType.ANALYTICS | SessionType.NONE | SessionType.RESET_PASSWORD}
     */
    static getSessionType(): string {
        const claims: IClaims = Utils.getClaimsFromToken<IClaims>(
            StorageService.getAccessToken()
        );

        if (claims.lapp) {
            switch (claims.lapp) {
                case LoginApp.ADMIN:
                    return SessionType.ADMIN;

                case LoginApp.AGENT:
                    return SessionType.AGENT;

                case LoginApp.ANALYTICS:
                    return SessionType.ANALYTICS;

                case LoginApp.SSO:
                    return SessionType.NONE;
            }
        }

        const { passwordReset = false } = StorageService.getUserDetails();

        if (passwordReset) {
            return SessionType.RESET_PASSWORD;
        }

        throw new Error("Couldn't determine session type!");
    }

    /**
     * Return if a current user is OKTA Super Admin User.
     *
     * @returns {boolean}
     */
    static isSuperUser(): boolean {
        const claims: IClaims = Utils.getClaimsFromToken<IClaims>(
            StorageService.getAccessToken()
        );

        return claims.esu;
    }

    /**
     * Return voice claims
     *
     * @returns {IClaims}
     */
    static getClaims(): IClaims {
        return Utils.getClaimsFromToken<IClaims>(
            StorageService.getAccessToken()
        );
    }

    /**
     * Check if accessToken is expired
     */
    static isAccessTokenExpired(): boolean {
        const { exp } = this.getClaims();
        return Utils.isJwtTimeExpired(exp);
    }

    /**
     * Removes the redirectUrl property from the userDetails we have in storage.
     */
    static clearRedirectUrl(): void {
        const userDetails = StorageService.getUserDetails();

        if (Utils.isObject(userDetails)) {
            delete userDetails.redirectUrl;
        }

        StorageService.setUserDetails(userDetails);
    }

    /**
     * Clears all session data from storage.
     */
    static clearSession(): void {
        StorageService.clearSession();
    }

    /**
     * Returns a Digital SSO JWT.
     * @param modifier {DigitalJWTModifier}
     * @param evSubAccountId {string}
     * @param agentId {string}
     * @param force {boolean}
     *
     * @returns {Promise<string>}
     */
    static async getDigitalJwt({
        modifier,
        evSubAccountId,
        agentId,
        force,
    }: IGetDigitalJWT): Promise<string> {
        const { jwt, exp, type, id } = getDigitalJWT();
        const modifierIdMatcher = {
            [DigitalJWTModifier.ADMIN]: evSubAccountId,
            [DigitalJWTModifier.AGENT]: agentId,
        };
        const currentIdToModifier = modifierIdMatcher[modifier];
        const modifierIdMatched =
            modifier === type && currentIdToModifier === id;

        if (
            !force &&
            jwt &&
            modifierIdMatched &&
            !Utils.isJwtTimeExpired(exp)
        ) {
            return Promise.resolve(jwt);
        }
        if (this.isAccessTokenExpired()) {
            await this.refreshToken();
        }

        let path = `/api/auth/token/digitaljwt/${modifier}`;
        if (
            modifier === DigitalJWTModifier.ADMIN &&
            evSubAccountId &&
            /^\d+$/.test(evSubAccountId)
        ) {
            path += `?evSubAccountId=${evSubAccountId}`;
        }
        if (modifier === DigitalJWTModifier.AGENT && agentId) {
            path += `?agentId=${agentId}`;
        }

        const token = await HttpService.get(path, {
            headers: {
                ...getDefaultContentTypeHeader(),
                ...this.getAuthHeader(),
            },
        }).then((respObj) => respObj.response);

        const claims = Utils.getClaimsFromToken<IDigitalClaims>(token);
        setDigitalJWT({
            jwt: token,
            exp: claims.exp,
            type: modifier,
            id: currentIdToModifier || '',
        });
        return Promise.resolve(token);
    }

    // ----------------------------------------------------
    // region LOGIN / AUTHENTICATION / LOGOUT METHODS
    // ----------------------------------------------------

    /**
     * Starts up the Engage Auth JS application by validating the client is logged in, either by checking
     * against the "code" parameter, or by attempting to refresh the access token. If either of these
     * succeed, the promise will be resolved with an "ok" parameter.  If they both fail, the promise
     * will be rejected.
     *
     * @param redirectToLogin When true, if the automatic authentication fails, user will be redirected
     * to the standard Engage Auth login page.
     *
     * @returns {Promise<string>}
     *
     * TODO Be careful with a rcx client app type - now only init take it from query params or separate call initClientAppType,
     *  better architecture to rewrite init methods and take it in every app where it's needed and hide from query params.
     *  init RCX client app type now included only in agent and admin apps.
     *
     * TODO Fetch from code should be called only for EAC, all other APPS should use Session.init()
     *  without call this part and instead of custom logic around refreshToken
     */
    static init(redirectToLogin: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            this.initClientAppType();

            this.fetchTokenFromAuthCode()
                .then(() => {
                    resolve('ok');
                })
                .catch(() =>
                    this.refreshToken()
                        .then(() => {
                            resolve('ok');
                        })
                        .catch(() => {
                            reject('invalid token');

                            if (redirectToLogin) {
                                window.location.href = Navigation.getLoginUrl();
                            }
                        })
                );
        });
    }

    static async getEngageAccessTokenAndUserDetails(): Promise<string> {
        const makeRequest = async () => {
            const rcAccessToken = StorageService.getRCAccessToken();
            const body = {
                rcAccessToken: rcAccessToken,
                rcTokenType: 'Bearer',
                includeRefresh: true,
            };

            return HttpService.post('/api/auth/login/rc/accesstoken', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body,
            });
        };

        try {
            const res = await makeRequest();
            return Promise.resolve(res.response);
        } catch (error: unknown) {
            if (
                error instanceof Error &&
                'status' in error &&
                error.status === 401
            ) {
                try {
                    await this.refreshRCToken();
                    const retryRes = await makeRequest();
                    return Promise.resolve(retryRes.response);
                } catch (refreshError) {
                    return Promise.reject(
                        new Error(
                            'Failed to refresh RC token and retry request'
                        )
                    );
                }
            }
            return Promise.reject(error);
        }
    }

    /**
     * Refreshes our access token with the refresh token associated with the current session.
     *
     * @param {String=} xAuthToken If specified, the access token returned by EngageAuth will have this xAuthToken value embedded in its claims. Otherwise,
     * the claims xAuthToken value will be sourced from the one associated to the current refresh token.
     * @returns {Promise<string>} Promise that resolves to "ok" if the refresh was successful. Otherwise, the promise is rejected.
     */
    static refreshToken(xAuthToken?: string): Promise<string> {
        const body: {
            refresh_token: string;
            'X-Auth-Token'?: string;
        } = { refresh_token: StorageService.getRefreshToken() };

        if (typeof xAuthToken === 'string' && xAuthToken.length > 0) {
            body['X-Auth-Token'] = xAuthToken;
        }

        PromiseCacheService.setIfEmpty(
            PromiseCacheService.KEYS.REFRESH_TOKEN,
            () =>
                HttpService.post('/api/auth/token/refresh', {
                    headers: {
                        ...getDefaultContentTypeHeader(),
                        ...this.getAuthHeader(),
                    },
                    body,
                }).then((respObj) => {
                    storeAccessTokenResult(respObj);
                    return 'ok';
                })
        );

        return PromiseCacheService.get(PromiseCacheService.KEYS.REFRESH_TOKEN);
    }

    static refreshRCToken(): Promise<string> {
        const { rcPlatform } = StorageService.getUserDetails();
        const body = {
            grant_type: 'refresh_token',
            refresh_token: StorageService.getRCRefreshToken(),
            client_id: RCX_CLIENT_ID,
        };
        PromiseCacheService.setIfEmpty(
            PromiseCacheService.KEYS.REFRESH_RC_TOKEN,
            () =>
                HttpService.externalPost(rcPlatform, `/restapi/oauth/token`, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body,
                }).then((respResult) => {
                    const resp = JSON.parse(respResult.response);
                    StorageService.setRCAccessToken(resp.access_token || '');
                    StorageService.setRCRefreshToken(resp.refresh_token || '');
                    return 'ok';
                })
        );

        return PromiseCacheService.get(
            PromiseCacheService.KEYS.REFRESH_RC_TOKEN
        );
    }

    static async revokeRCToken(): Promise<string> {
        const { rcPlatform } = StorageService.getUserDetails();
        const body = {
            token: StorageService.getRCAccessToken(),
            client_id: RCX_CLIENT_ID,
        };

        try {
            await HttpService.externalPost(
                rcPlatform,
                `/restapi/oauth/revoke`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body,
                }
            );
        } catch (err) {
            window.console.warn(err);
        }
        return Promise.resolve('ok');
    }

    static async getRCAuthCode(
        clientId: string,
        isRetry = true
    ): Promise<string> {
        try {
            await this.refreshRCToken();
        } catch {
            await this.logout();
            this.clearSession();
            window.location.href = Navigation.getLoginUrl();
            return Promise.reject('invalid token');
        }

        try {
            const res = await this.generateRCAuthCode(clientId);
            const resp = JSON.parse(res);
            return Promise.resolve(resp.code);
        } catch {
            if (isRetry) {
                return this.getRCAuthCode(clientId, false);
            }
            await this.logout();
            this.clearSession();
            window.location.href = Navigation.getLoginUrl();
            return Promise.reject('invalid token');
        }
    }

    static async generateRCAuthCode(clientId: string): Promise<string> {
        const { rcPlatform } = StorageService.getUserDetails();

        const body: {
            clientId: string;
        } = { clientId };

        try {
            const res = await HttpService.externalPost(
                rcPlatform,
                '/restapi/v1.0/interop/generate-code',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        ...this.getRCAuthHeader(),
                    },
                    body,
                }
            );
            return Promise.resolve(res.response);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async getRCClientId(): Promise<IUserInfo> {
        const { rcPlatform } = StorageService.getUserDetails();

        try {
            const res = await HttpService.externalGet(
                rcPlatform,
                '/restapi/v1.0/client-info',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        ...this.getRCAuthHeader(),
                    },
                }
            );
            return Promise.resolve(JSON.parse(res.response));
        } catch (e) {
            return Promise.reject(e);
        }
    }

    // TODO Hide query params from rcx client app type after init?
    static initClientAppType(): void {
        this.initialUrlQueryString = window.location.search;

        if (this.initialUrlQueryString) {
            sessionStorage.setItem(
                INITIAL_URL_QUERY_STRING_CACHE_KEY,
                this.initialUrlQueryString
            );
        } else {
            this.initialUrlQueryString = sessionStorage.getItem(
                INITIAL_URL_QUERY_STRING_CACHE_KEY
            );
        }

        const urlParams = new URLSearchParams(this.initialUrlQueryString || '');
        this.rcxClientAppType =
            urlParams.get(RCX_CLIENT_APP_TYPE) || DEFAULT_RCX_CLIENT_APP_TYPE;

        window.__settings.rcxClientAppType = this.rcxClientAppType;
    }

    /**
     * Attempts to start a session with Engage Auth by exchanging an AuthCode for session information.
     * Looks at the query parameter "code" for an AuthCode value.
     *
     * @returns {Promise} Promise that represents the attempt to fetch session info from an AuthCode
     * - If no AuthCode is present, the promise is rejected.
     * - If we find an AuthCode, make a request to Engage Auth to exchange it for session info
     *    - If The request succeeds, the session information is stored and the promise resolves to "ok"
     *    - Otherwise, the promise is rejected
     */
    static fetchTokenFromAuthCode(): Promise<string> {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        if (typeof authCode === 'string' && authCode.length > 0) {
            try {
                if (window.opener && window.opener?.handleCallbackUri) {
                    const handleCallbackUri = window.opener?.handleCallbackUri;
                    delete window.opener.handleCallbackUri;
                    handleCallbackUri &&
                        handleCallbackUri(window.location.href);
                    window.close();
                    return Promise.reject('Handled in opener');
                }
            } catch (error) {
                /* ignore error */
            }

            PromiseCacheService.setIfEmpty(
                PromiseCacheService.KEYS.FETCH_TOKEN_FROM_AUTH_CODE,
                () =>
                    HttpService.post('/api/auth/login/code', {
                        headers: getDefaultContentTypeHeader(),
                        body: { code: authCode },
                    })
                        .then((respObj) => {
                            storeAccessTokenResult(respObj);
                            storeRCAccessTokenResult(respObj);
                            return 'ok';
                        })
                        .finally(() => {
                            try {
                                // Remove the code parameter
                                urlParams.delete('code');

                                const path = window.location.href.split('?')[0];

                                const rcxClientAppType =
                                    urlParams.get(RCX_CLIENT_APP_TYPE);

                                const newUrl = rcxClientAppType
                                    ? `${path}?${urlParams.toString()}`
                                    : path;

                                window.history.pushState(
                                    { path: newUrl },
                                    '',
                                    newUrl
                                );
                            } catch (e) {
                                window.console.log(
                                    'error removing code query param'
                                );
                            }
                        })
            );
        }

        return (
            PromiseCacheService.get(
                PromiseCacheService.KEYS.FETCH_TOKEN_FROM_AUTH_CODE
            ) || Promise.reject('ok')
        );
    }

    /**
     * Logs in to an app with legacy/native (non-sso) user/pass credentials.
     *
     * @param {Object} credentials
     * @param {String} credentials.username
     * @param {String} credentials.password
     * @param {String} credentials.platformId
     * @param {"admin"|"agent"|"analytics"} credentials.loginType
     */
    static legacyLogin(credentials: ICredentials): Promise<string> {
        if (
            !Utils.isObject(credentials) ||
            typeof credentials.username !== 'string' ||
            typeof credentials.password !== 'string' ||
            typeof credentials.platformId !== 'string' ||
            typeof credentials.loginType !== 'string' ||
            (credentials.loginType !== 'admin' &&
                credentials.loginType !== 'agent' &&
                credentials.loginType !== 'analytics')
        ) {
            return Promise.reject(
                `Credentials must be an object of the format {"username": string, "password": string, "platformId": string, "loginType": "admin"|"agent"|"analytics"}, got ${
                    Utils.isObject(credentials)
                        ? JSON.stringify(credentials)
                        : credentials
                }`
            );
        }

        const { username, password, platformId, loginType } = credentials;
        const body = { username, password, platformId, redirectToApp: true };

        PromiseCacheService.setIfEmpty(PromiseCacheService.KEYS.LOGIN, () =>
            HttpService.post(`/api/auth/login/${loginType}`, {
                headers: getDefaultContentTypeHeader(),
                body,
            }).then((respObj) => {
                storeAccessTokenResult(respObj);
                return 'ok';
            })
        );

        return PromiseCacheService.get(PromiseCacheService.KEYS.LOGIN);
    }

    /**
     * Logs out the current Engage Auth user. Local storage is unaffected.
     *
     * @returns {Promise} Promise that resolves once the logout request has been completed.
     */
    static async logout(): Promise<PromiseObj> {
        const refreshToken = StorageService.getRefreshToken();
        if (StorageService.getRCAccessToken()) {
            await this.revokeRCToken();
        }
        //TODO: This delete expects two variables. That's not how it was working before..
        return HttpService.delete(`/api/auth/logout/${refreshToken}`, null);
    }

    // ----------------------------------------------------
    // endregion LOGIN / AUTHENTICATION / LOGOUT METHODS
    // ----------------------------------------------------
}
