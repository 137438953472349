export const DROPDOWN_H_PADDING = 12;
export const TOGGLE_HEIGHT = 40;
export const SMALL_TOGGLE_HEIGHT = 32;
export const ITEM_GAP = 8;
export const SMALL_ITEM_GAP = 4;
export const ITEM_HEIGHT = 24;
export const SMALL_ITEM_HEIGHT = 20;
export const LIST_ITEM_HEIGHT = 40;
export const SMALL_LIST_ITEM_HEIGHT = 32;
export const LIST_ITEM_EDGE_PADDING = 8;
export const LIST_ITEMS_COUNT = 7;
export const TOGGLE_ICON_SIZE = 16;
export const CLOSE_ICON_SIZE = 16;
export const ICON_PADDING = 8;
export const TOGGLE_BORDER = 1;
export const BORDER_RADIUS = 4;
export const TOGGLE_H_PADDING = DROPDOWN_H_PADDING - TOGGLE_BORDER;
export const REMOVE_ITEM_DEFAULT_TIMEOUT = 500;
export const DROPDOWN_MARGIN = 40;
export const TOGGLE_MAX_HEIGHT = 135;
export const ACTIONS_WRAPPER_HEIGHT = 40;
export const COUNTER_MAX_WIDTH = 40;
export const DROPDOWN_PADDING_LEFT = 12;
export const ITEM_MARGIN_RIGHT = 8;
export const COUNTER_WIDTH = 34;
export const ICON_LEFT_MARGIN = 4;

export const KEY_CODES = {
    enter: 13,
    up_arrow: 38,
    down_arrow: 40,
    tab: 9,
    escape: 27,
    delete_code: 46,
    backspace_code: 8,
};

export const NO_RESULTS_FOUND = 'No results found';
export const NOTHING_AVAILABLE = 'Nothing Available';
export const SELECT_PLACEHOLDER = 'Select';
export const SELECT_ALL = 'Select all';
export const DESELECT_ALL = 'Deselect all';
