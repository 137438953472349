import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { AutocompleteRenderGetTagProps } from '@mui/material';
import type { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type {
    IAutocompleteProps,
    IOption,
} from '../../../components/form/Autocomplete';
import { Autocomplete } from '../../../components/form/Autocomplete';
import { INDETERMINATE_FIELD_VALUE } from '../../../constants';
import type { RHFControllerRender } from '../../../types';

type IRenderAutocomplete<T> = Pick<IAutocompleteProps<T>, 'data' | 'disabled'> &
    Omit<IAutocompleteProps<T>, keyof ControllerRenderProps | 'renderTags'> & {
        onChange?: ControllerRenderProps['onChange'];
        dataAid?: string;
        renderTags?:
            | ((
                  value: IOption<T>[],
                  getTagProps: AutocompleteRenderGetTagProps,
                  onChange: ControllerRenderProps['onChange']
              ) => ReactNode)
            | undefined;
    };

export function renderAutocompleteControl<T>({
    onChange,
    dataAid,
    message,
    placeholder,
    renderTags,
    ...props
}: IRenderAutocomplete<T>): RHFControllerRender {
    return ({ field, fieldState }) => {
        const { t } = useTranslation();

        const isIndeterminateValue = field.value === INDETERMINATE_FIELD_VALUE;

        const handleChange = onChange || field.onChange;

        const handleRenderTags = useMemo(() => {
            if (!renderTags) return;

            return (
                value: IOption<T>[],
                getTagProps: AutocompleteRenderGetTagProps
            ) => {
                return renderTags(value, getTagProps, handleChange);
            };
        }, [handleChange]);

        return (
            <Autocomplete
                message={message || fieldState.error?.message}
                error={!!message || !!fieldState.error}
                {...field}
                {...props}
                onChange={handleChange}
                value={isIndeterminateValue ? '' : field.value || null}
                placeholder={
                    isIndeterminateValue
                        ? t('GENERICS.LABELS.INPUT_MULTI_VALUE')
                        : placeholder
                }
                //TODO: This will be fixed by updating to React 19 and removing the forwardRef as forwardRef cannot be made genetic
                // component receives T from forwardRef render function
                //@ts-ignore
                renderTags={handleRenderTags}
                data-aid={dataAid}
            />
        );
    };
}
