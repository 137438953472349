import type { FC } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as AppIcon } from './assets/ring_sense.svg';
import type { RingSenseButtonType } from './types';
import { AppButton } from '../AppButton';

export const RingSenseButton: FC<RingSenseButtonType> = ({ ringSenseUrl }) => {
    const { t } = useTranslation();
    return (
        <AppButton href={ringSenseUrl} name={t('APPS.RING_SENSE')} openInNewTab>
            <AppIcon />
        </AppButton>
    );
};

export default memo(RingSenseButton);
