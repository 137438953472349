import { defaultPIIMaskRules } from './constants';
import { EnhancedSanitization as Sanitization } from './enhancedSanitization';
import type { PIIMaskRules } from './types';

export class PiiInterceptor {
    defaultMaskRules: PIIMaskRules;

    constructor(defaultMaskRules: PIIMaskRules) {
        this.defaultMaskRules = defaultMaskRules;
    }

    private getMaskRuleCaseInsensitive(maskRules: PIIMaskRules, key: string) {
        const lowerCaseKey = key.toLowerCase();
        const field = Object.keys(maskRules).find(
            (k) => k.toLowerCase() === lowerCaseKey
        );
        return field ? maskRules[field] : null;
    }

    public appendOrOverrideDefaultMaskRules(newRules: PIIMaskRules) {
        this.defaultMaskRules = { ...this.defaultMaskRules, ...newRules };
    }

    public sanitize(data: any, maskRules?: PIIMaskRules) {
        try {
            return JSON.parse(this.sanitizeToString(data, maskRules));
        } catch (err) {
            return data;
        }
    }

    public sanitizeToString(data: any, maskRules?: PIIMaskRules) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        const hierarchicalPathCache = new WeakMap();
        try {
            return JSON.stringify(data, function (key, value) {
                if (!key || value === null) {
                    return value;
                }

                const currentPathCache = hierarchicalPathCache.get(this) || {};
                const hierarchicalPath = currentPathCache.path
                    ? `${currentPathCache.path}${
                          currentPathCache.isArray ? '' : `.${key}`
                      }`
                    : key;

                if (typeof value === 'object') {
                    hierarchicalPathCache.set(value, {
                        path: hierarchicalPath,
                        isArray: Array.isArray(value),
                    });
                }

                let maskRule = self.getMaskRuleCaseInsensitive(
                    maskRules || self.defaultMaskRules,
                    key
                );

                if (hierarchicalPath !== key) {
                    maskRule =
                        self.getMaskRuleCaseInsensitive(
                            maskRules || self.defaultMaskRules,
                            hierarchicalPath
                        ) || maskRule;
                }

                if (maskRule && value) {
                    return maskRule.call(Sanitization, value);
                }

                return value;
            });
        } catch (err) {
            console.error('Sanitization failed with error: ', err);
            return JSON.stringify(data);
        }
    }
}

export const piiInterceptor = new PiiInterceptor(defaultPIIMaskRules);
