const EXP_COEFFICIENT = 200;

/**
 * @ignore
 *
 * Utility class that is used internally
 */

export class Utils {
    /**
     * @ignore
     * Attempts to parse the claims in the provided token. If there is no token in storage,
     * or the claims can't be parsed, the method will throw an error.
     *
     * @returns {Object} Object representing the claims embedded in the access token.
     * @throws {Error} Error representing detailed message
     */
    static getClaimsFromToken<T>(token?: string): T {
        if (!token || token.length === 0) {
            throw new Error('No accessToken was provided');
        }

        const encodedClaimsString: string | undefined =
            token && token.split('.')[1];

        if (
            typeof encodedClaimsString !== 'string' ||
            encodedClaimsString.length === 0
        ) {
            throw new Error('Invalid token was provided');
        }

        try {
            const claimsString: string = window.atob(encodedClaimsString);
            return JSON.parse(claimsString);
        } catch (e) {
            throw new Error('Invalid token was provided');
        }
    }

    /**
     * @ignore
     * Checks if a value is an object and also not-null.
     *
     * @param {*} val
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isObject(val: any): boolean {
        return typeof val === 'object' && val !== null;
    }

    /**
     * @ignore
     *
     * Dispatches storage event in the current window.
     */
    static dispatchStorageEvent(): void {
        const event = window.document.createEvent('Event');
        event.initEvent('storage', true, true);
        window.dispatchEvent(event);
    }

    /**
     * @ignore
     *
     * Checks if a given jwt.exp has expired
     */
    static isJwtTimeExpired(mills: number): boolean {
        return Math.round(mills * 1000 - Date.now() - EXP_COEFFICIENT) <= 0;
    }
}
