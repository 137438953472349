export const AccountTypes = {
    OMNICHANNEL: 'OMNICHANNEL',
    STANDALONE: 'STANDALONE',
} as const;

export const VODAFONE_AGENT_APP_URL =
    'https://app.vodafonebusiness.ringcentral.com/l/ring_cx/agent';

export * from './brandIds';
export * from './clientIds';
