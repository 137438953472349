import { createModuleClosure } from '../../moduleClosure';
import type { DigitalJWTModifier } from '../Session';

export type DigitalJWT = {
    jwt: string;
    exp: number;
    type: Nullable<DigitalJWTModifier>;
    id: string;
};

export const digitalJWTCache = createModuleClosure<DigitalJWT>({
    jwt: '',
    exp: 0,
    type: null,
    id: '',
});

export const getDigitalJWT = (): DigitalJWT => digitalJWTCache.value;
export const setDigitalJWT = ({ jwt, exp, type, id }: DigitalJWT) => {
    digitalJWTCache.value.jwt = jwt;
    digitalJWTCache.value.exp = exp;
    digitalJWTCache.value.type = type;
    digitalJWTCache.value.id = id;
};
