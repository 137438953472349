export enum Languages {
    EN_US = 'en-US',
    ES_US = 'es-US',
    ES_ES = 'es-ES',
    DE_DE = 'de-DE',
    IT_IT = 'it-IT',
    FR_CA = 'fr-CA',
    FR_FR = 'fr-FR',
    EN_GB = 'en-GB',
    EN_AU = 'en-AU',
    ES_419 = 'es-419',
    RC_XX = 'rc-XX', // pseudo language
}

export enum LanguageLabels {
    EN_AU = 'English (Australia)',
    EN_US = 'English (U.S.)',
    EN_GB = 'English (U.K.)',
    ES_ES = 'Español',
    FR_FR = 'Français',
    IT_IT = 'Italiano',
    DE_DE = 'Deutsch',
    FR_CA = 'Français (Canada)',
    ES_419 = 'Español (Latinoamérica)',
}
