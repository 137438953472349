import { useEffect } from 'react';

import type { RcTheme } from '@ringcentral/juno';
import {
    addListener,
    sendPostMessage,
    unregisterPostMessageListener,
    registerPostMessageListener,
} from '@ringcx/shared';

import * as jupiterService from '../services/jupiter';

const JUPITER_EVENT_TYPE = 'JUPITER_EVENT_TYPE';

type ToAgentEvent = {
    action: 'sendTheme';
    data: RcTheme;
    type: 'JUPITER_EVENT_TYPE';
};

const sendThemeMessage = () => {
    sendPostMessage({
        message: JSON.parse(
            JSON.stringify({
                type: JUPITER_EVENT_TYPE,
                action: 'getTheme',
            })
        ),
        toParent: true,
    });
};

export const useThemeMessageHandler = () => {
    useEffect(() => {
        registerPostMessageListener();

        addListener<ToAgentEvent>(
            (event) => event?.data?.type === JUPITER_EVENT_TYPE,
            async ({ data, action }) => {
                switch (action) {
                    case 'sendTheme': {
                        const theme = data as RcTheme;
                        jupiterService.setPalette(theme?.palette);
                        jupiterService.setTheme(theme);
                        break;
                    }
                }
            }
        );

        sendThemeMessage();

        return unregisterPostMessageListener;
    }, []);
};
