import { Languages, Session } from '@ringcx/shared';
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { getUserLocale } from './translate.helpers';

const { regionalSettings } = Session.getUserDetails();

const lng: string = getUserLocale(regionalSettings?.language);

// eslint-disable-next-line import/no-named-as-default-member
i18next
    .use(initReactI18next)
    .use(
        resourcesToBackend(async (language: string) => {
            /* istanbul ignore next */
            switch (language as Languages) {
                case Languages.RC_XX:
                    return import('./locales/rc-XX.json');
                case Languages.DE_DE:
                    return import('./locales/de-DE.json');
                case Languages.ES_419:
                    return import('./locales/es-419.json');
                case Languages.ES_ES:
                    return import('./locales/es-ES.json');
                case Languages.EN_AU:
                    return import('./locales/en-AU.json');
                case Languages.EN_GB:
                    return import('./locales/en-GB.json');
                case Languages.FR_CA:
                    return import('./locales/fr-CA.json');
                case Languages.FR_FR:
                    return import('./locales/fr-FR.json');
                case Languages.IT_IT:
                    return import('./locales/it-IT.json');
                default:
                    return import('./locales/en-US.json');
            }
        })
    )
    .init({
        fallbackLng: Languages.EN_US,
        lng,
        interpolation: {
            escapeValue: false,
        },
        ...(process.env.NODE_ENV === 'test' && {
            resources: {
                [Languages.EN_US]: {
                    translation: require('./locales/en-US.json'),
                },
            },
        }),
    });

// eslint-disable-next-line import/no-named-as-default-member
const t = i18next.t.bind(i18next);

export { t, i18next };
