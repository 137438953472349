export const BRAND_ID = '1210';
export const RCX_CLIENT_APP_TYPE = 'RingCX';
export const CLIENT_ID = 'GBN6JgdsTmWpaSesm0jjvg';
export const WEBINAR_CLIENT_ENDPOINT_ID =
    '1783b5cb-5b81-4ff0-8a40-01d287ee45e1';

export const DEFAULT_TOKEN_TYPE = 'Bearer';

export const CPR_INFO_SECTION = 'problemReportRequest';
export const CPR_ATTACHMENTS_SECTION = 'attachments';

export const LOG_FILE_PREFIX = 'RCX_CPR_';
export const EXPORT_LOG_TYPE = 'json';
export const LOG_FILES_FOLDER = 'files';
