import type { RcPalette, RcTheme } from '@ringcentral/juno';
import { BehaviorSubject } from 'rxjs';

export const $theme = new BehaviorSubject<RcTheme | undefined>(undefined);

export const setProperty = (key: string, value: string) => {
    document.documentElement.style.setProperty(`--${key}`, value);
};
export const removeProperty = (key: string) => {
    document.documentElement.style.removeProperty(`--${key}`);
};

export const setPalette = (palette: RcPalette) => {
    const theme = {
        'content-background': palette?.nav.b01,
        'primary-text-color': palette?.nav.f04,
        'secondary-text-color': palette?.nav.f02,
        'list-item-hover': `color-mix(in srgb, ${palette?.content.brand} 16%, transparent)`,
        'table-row-background': palette?.neutral.b01,
    };

    Object.entries(theme).forEach(([key, value]) => {
        if (palette) {
            setProperty(key, value);
        } else {
            removeProperty(key);
        }
    });
};

export const setTheme = (theme: RcTheme) => {
    $theme.next(theme);
};
