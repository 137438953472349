//// EDR

export type EdrInfo = {
    pushInterval: number;
    uri: string;
    token: string;
    tokenExpiresIn: number;
    connectionTimeout: number;
    channels: EdrChannelInfo[];
    cprUri: string;
};

export type EdrChannelInfo = {
    id: EdrChannelId;
    pushInterval: number;
};

export enum EdrChannelId {
    problemReports = 'problemReports',
}

//// CPR

export type ProblemReportData = {
    title: string;
    description: string;
    when?: string;
    attachments?: File[];
};

export interface ProblemReportSendingParams {
    reportType?: ReportType;
    productType: ProductType;
    title: string;
    description: string;
    attachments?: File[];
    when?: string;
}

export type ProblemReportRequest = {
    title: string;
    description?: string;
    submitterEmail?: string;
    reportType: ReportType;
    clientId?: string;
    clientEndpointId?: string;
    clientVersion?: string;
    clientAppType: string;
    clientDetails: string;
    accountId?: string;
    userId?: string;
    brandId?: string;
    productCategory: string;
    productSubcategory?: string;
    additionalAttributes?: ProblemReportAdditionalAttributes;
};

export type ProblemReportResponse = {
    id: string;
    creationTime: string;
    deletionTime: string;
    envName: string;
    accountId: string;
    userId: string;
    brandId: string;
    title: string;
    description: string;
    submitterEmail: string;
    clientId: string;
    clientAppType: string;
    clientVersion: string;
    clientEndpointId: string;
    clientDetails: string;
    productCategory: string;
    productSubcategory: string;
    reportType: ReportType;
    reportSubType: Nullable<string>;
    additionalAttributes: Nullable<ProblemReportAdditionalAttributes>;
    attachments: [
        {
            id: string;
            fileName: string;
            contentType: string;
            size: number;
            deletionTime: string;
        },
    ];
};

type ProblemReportAdditionalAttributes = {
    productSubcategory: ProductSubcategory;
};

export interface CprUserInfo {
    userId: string;
    accountId: string;
    email: string;
    productSubcategory: ProductSubcategory;
}

export interface ZipParams {
    logs: unknown;
    attachments?: File[];
}

export interface CprParams {
    blob: Blob;
    filename?: string;
}

export enum ReportType {
    ProblemReport = 'ProblemReport',
    CrashReport = 'CrashReport',
    StarSurveyReport = 'StarSurveyReport',
    Agent = 'Agent',
}

export enum ProductType {
    CRM = 'CRM',
    RCX = 'RCX',
}

export const productTypeMap = {
    [ProductType.CRM]: 'RingCX',
    [ProductType.RCX]: 'RingCX Native',
};

export enum ProductSubcategory {
    Agent = 'Agent',
    Admin = 'Admin',
    Supervisor = 'Supervisor',
    Analytics = 'Analytics',
    AuthClient = 'AuthClient',
    AnalyticsClient = 'AnalyticsClient',
}
