import type { FC } from 'react';
import { useMemo } from 'react';

import deepmerge from 'deepmerge';

import { StyledIconWrapper } from './Adornment.styled';
import type { IAdornment } from './types';
import { Information } from '../../icons';
import Tooltip from '../Tooltip';

const Adornment: FC<IAdornment> = ({
    tooltipMessage = '',
    icon = null,
    size = 'small',
    placement = 'bottom',
    legacyMode = false,
    offset,
    inline = false,
    tooltipPopperProps,
    tooltipWidth,
}) => {
    const content = useMemo(() => {
        const renderIcon = icon || <Information />;
        return (
            <StyledIconWrapper
                legacyMode={legacyMode}
                size={size}
                tooltipWidth={tooltipWidth}
                inline={inline}
            >
                {renderIcon}
            </StyledIconWrapper>
        );
    }, [icon, inline, legacyMode, size, tooltipWidth]);

    const localOffset = useMemo(() => {
        switch (placement) {
            case 'bottom':
            case 'top': {
                return offset ? offset : inline ? '0, -8px' : '0, -12px';
            }
            default: {
                return '';
            }
        }
    }, [inline, offset, placement]);

    const popperProps = useMemo(() => {
        const offsetProps = {
            modifiers: {
                offset: {
                    offset: localOffset,
                },
            },
        };
        return deepmerge(tooltipPopperProps || {}, offsetProps);
    }, [localOffset, tooltipPopperProps]);

    if (tooltipMessage) {
        return (
            <Tooltip
                PopperProps={popperProps}
                placement={placement}
                title={tooltipMessage}
            >
                {content}
            </Tooltip>
        );
    }
    return content;
};

export default Adornment;
