import { Dialog, Button as EuiButton } from '@ringcx/ui';
import styled from 'styled-components';

import { device } from '../../constants/breakpoints';

export const DialogStoryWrapper = styled.div`
    margin: 10px;
    button {
        margin: 10px;
    }
`;

export const StyledDialog = styled(Dialog)`
    && {
        .MuiPaper-root {
            overflow: visible;
        }
        .MuiDialogContent-root {
            overflow: visible;
        }
        p.MuiDialogContentText-root:last-child {
            margin-bottom: 0;
        }
        .MuiDialogContent-root {
            padding-bottom: 8px;
        }
        .MuiDialogActions-root {
            padding-bottom: 32px;
        }
        @media ${device.crm} {
            .MuiDialog-paper {
                margin-left: 0;
                margin-right: 0;
            }
            .MuiDialog-paperWidthSm {
                max-width: calc(100% - 24px);
            }
            .MuiDialogContent-root {
                min-width: initial;
            }
        }
    }
`;

export const Row = styled.div`
    &:not(:first-child) {
        margin-top: 20px;
    }
`;

export const Button = styled(EuiButton)`
    && {
        font-weight: normal;
        font-size: 14px;
    }
`;

export const RoundButton = styled(EuiButton)`
    && {
        font-size: 16px;
        font-weight: 500;
        width: 219px;
        height: 46px;
        border-radius: 24px;
        letter-spacing: 0.17px;
        line-height: 16px;
    }
`;
export const Heading = styled.span`
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    height: 46px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray[900]};
    white-space: pre-line;
`;

export const SignInButtonWrapper = styled.div`
    justify-content: center;
    flex-grow: 1;
    display: flex;
`;
