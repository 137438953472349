const envPlatformMapping = {
    xmrupxmn: ['aws91-l32', 'aws91-l08'],
    cffe1ams: ['aws91-l26', 'aws93-l15', 'aws93-l13'],
    rcxlabams: ['aws91-l28', 'aws91-l21', 'aws91-l20', 'aws91-l30'],
    ops443: ['fra20'],
    ops: ['aws46'],
};

interface IAppCredentials {
    link: string;
    clientId: string;
    clientSecret: string;
}

//TODO: Add app creds for others platforms
const publicApplicationCredentials: Record<string, IAppCredentials> = {
    xmrupxmn: {
        link: 'https://api-xmrupxmn.int.rclabenv.com',
        clientId: '',
        clientSecret: '',
    },
    cffe1ams: {
        link: 'https://api-cffe1ams.lab.nordigy.ru',
        clientId: '87StSNePrsqeYwZk7xAVAl',
        clientSecret: 'aBGXJxfTVMIahw1hGPh69PZQzBMXJ8FAOfVNaqitAtBv',
    },
    rcxlabams: {
        link: 'https://api-rcxlabams.int.rclabenv.com',
        clientId: '',
        clientSecret: '',
    },
    ops443: {
        link: 'https://api.ops.ringcentral.com:443',
        clientId: 'eFYjQTPKNHbbVzHmkOyEiY',
        clientSecret: 'AD49R1xsFzHbjVQcXZE8Eb6xaUZHUHHa5d1ywlWCglbs',
    },
    ops: {
        link: 'https://api.ops.ringcentral.com',
        clientId: 'eFYjQTPKNHbbVzHmkOyEiY',
        clientSecret: 'AD49R1xsFzHbjVQcXZE8Eb6xaUZHUHHa5d1ywlWCglbs',
    },
    prod: {
        link: 'https://api.ringcentral.com',
        clientId: '4igHvnLARe2bDweCDGli2w',
        clientSecret: 'enqjZ6Nq1RudKPd1dYgFric9LxkSodlP7bh7b5kSNh6l',
    },
};

function getAppConfig() {
    const params = new URL(window.location.href).searchParams;
    const platformId = params.get('plat');
    const defaultAppConfig = publicApplicationCredentials.prod;

    for (const [key, values] of Object.entries(envPlatformMapping)) {
        if (platformId && values.includes(platformId)) {
            return publicApplicationCredentials[key];
        }
    }

    return defaultAppConfig;
}

export const fetchRCAccessToken = async (token: string) => {
    const appConfig = getAppConfig();
    const { clientId, clientSecret, link } = appConfig;

    //only for testing purposes
    const clientIdLs = localStorage.getItem('clientId');
    const clientSecretLs = localStorage.getItem('clientSecret');

    const clientIdResult = clientIdLs || clientId;
    const clientSecretResult = clientSecretLs || clientSecret;

    const autorization = btoa(clientIdResult + ':' + clientSecretResult);

    const params = new URLSearchParams();
    params.append('grant_type', 'urn:ietf:params:oauth:grant-type:jwt-bearer');
    params.append('assertion', token);

    try {
        const response = await fetch(`${link}/restapi/oauth/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + autorization,
            },
            body: params.toString(),
        });

        if (!response.ok) {
            throw new Error('Error fetching RC access token');
        }

        const { access_token, refresh_token, token_type } =
            await response.json();
        return { access_token, refresh_token, token_type };
    } catch (err) {
        console.error('Error fetching RC access token:', err);
        return null;
    }
};

export const fetchRCXAccessToken = async (
    rcAccessToken: string,
    rcTokenType: string
) => {
    try {
        const params = new URLSearchParams();
        params.append('rcAccessToken', rcAccessToken);
        params.append('rcTokenType', rcTokenType);
        const response = await fetch(
            `${window.location.origin}/api/auth/login/rc/accesstoken?includeRefresh=true`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString(),
            }
        );

        if (!response.ok) {
            throw new Error('Error fetching RCX access token');
        }

        const { accessToken, refreshToken, tokenType } = await response.json();

        return {
            rcxAccessToken: accessToken,
            rcxRefreshToken: refreshToken,
            rcxTokenType: tokenType,
        };
    } catch (err) {
        console.error('Error fetching RCX access token:', err);
        return null;
    }
};
