import { MaterialDesignContent } from 'notistack';
import styled from 'styled-components';

export const StyledSnackbarContent = styled(MaterialDesignContent)`
    &.notistack-MuiContent-success {
        background-color: ${(p) => p.theme.colors.success};
    }

    &.notistack-MuiContent-error {
        background-color: ${(p) => p.theme.colors.error};
    },
`;
