import type { FC } from 'react';
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { PlaceholderWrapper } from './LoadingPlaceholder.styled';

const LoadingPlaceholder: FC = () => {
    const { t } = useTranslation();
    return <PlaceholderWrapper>{t('GENERICS.LOADING')}</PlaceholderWrapper>;
};

export default memo(LoadingPlaceholder);
