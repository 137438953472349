import { Sanitization } from '@ringcx/pii-interceptor';
import mixpanel from 'mixpanel-browser';

import type {
    AnalyticsProvider,
    InitializeEvent,
    RegisterOptions,
} from '../../types';

const mixpanelProvider: AnalyticsProvider = {
    loadProvider(token: string, cookieDomain?: string): void {
        mixpanel.init(token, {
            ip: false,
            ...(cookieDomain
                ? { cookie_domain: cookieDomain }
                : { cross_subdomain_cookie: false }),
        });
    },
    initialize({
        id,
        email,
        agentType,
        accountId,
        rcAccountId,
        mainAccountId,
        platformId,
        appVersion,
        accountType,
        isSSO,
        app,
    }: InitializeEvent): void {
        mixpanel.identify(id.toString());

        mixpanel.people.set({
            $first_name: '',
            $last_name: '',
            $name: '',
            $email: Sanitization.hashByMD5(email),
            agentType: agentType,
            accountId: accountId,
            rcAccountId: rcAccountId,
            mainAccountId: mainAccountId,
            platformId: platformId,
            appVersion: appVersion,
            accountType: accountType,
            isSSO: isSSO,
            app: app,
        });
    },
    register(info: RegisterOptions): void {
        mixpanel.register(info);
    },
    track(eventName: string, properties: Record<string, any>): void {
        mixpanel.track(eventName, properties);
    },
    reset(): void {
        mixpanel.reset();
    },
    alias(existingAliasId: string, otherDistinctId: string): void {
        mixpanel.alias(existingAliasId, otherDistinctId);
    },
    getDistinctId(): string {
        return mixpanel.get_distinct_id();
    },
};

export default mixpanelProvider;
