import { Sanitization } from '@ringcentral/pii-utils';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';

export class EnhancedSanitization extends Sanitization {
    static hashBySHA256 = (text: string): string => {
        if (!text) {
            return text;
        }

        return sha256(text).toString();
    };

    static hashByMD5 = (text: string): string => {
        if (!text) {
            return text;
        }

        return md5(text).toString();
    };
}
