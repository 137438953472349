import type { FC, PropsWithChildren } from 'react';

import { SnackbarProvider as NotistackProvider } from 'notistack';

import { CloseAction } from './components';
import { StyledSnackbarContent } from './SnackbarProvider.styled';

const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => (
    <NotistackProvider
        hideIconVariant
        action={(key) => <CloseAction snackKey={key} />}
        Components={{
            success: StyledSnackbarContent,
            error: StyledSnackbarContent,
        }}
    >
        {children}
    </NotistackProvider>
);

export default SnackbarProvider;
