import { EnhancedSanitization as Sanitization } from './enhancedSanitization';
import type { PIIMaskRules } from './types';

export const defaultPIIMaskRules: PIIMaskRules = {
    ani: Sanitization.hashBySHA256,
    aniE164: Sanitization.hashBySHA256,
    email: Sanitization.maskEmail,
    agentEmail: Sanitization.maskEmail,
    name: Sanitization.maskString,
    firstName: Sanitization.maskString,
    lastName: Sanitization.maskString,
    agentFirstName: Sanitization.maskString,
    agentLastName: Sanitization.maskString,
    agentUserName: Sanitization.maskString,
    destination: Sanitization.hashBySHA256,
    dnis: Sanitization.hashBySHA256,
    dnisE164: Sanitization.hashBySHA256,
    phone: Sanitization.hashBySHA256,
    leadPhone: Sanitization.hashBySHA256,
    leadPhoneE164: Sanitization.hashBySHA256,
    dialDest: Sanitization.hashBySHA256,
    edUuid: Sanitization.maskString,
    username: Sanitization.maskString,
};
