import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

import { UNUSED } from '../../../../helpers/usage';
import { DigitalFontIcon } from '../../../DigitalFontIcon';
import { DROPDOWN_H_PADDING, LIST_ITEM_EDGE_PADDING } from '../../constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledListItem = styled(({ listItemHeight, ...rest }) => {
    UNUSED(listItemHeight);
    return <MenuItem {...rest} />;
})<{ listItemHeight: number }>`
    && {
        &.Mui-selected {
            background-color: unset;
        }
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 11px ${DROPDOWN_H_PADDING}px;
        height: ${({ listItemHeight }) => listItemHeight}px;
        white-space: nowrap;
        transition: none;

        &.selected-item,
        &.selected-item:hover,
        &.selected-item:focus {
            color: ${(p) =>
                `var(--menu-item-active-text, ${p.theme.colors.background})`};
            background-color: ${(p) => p.theme.colors.primary};
            & > div:not([disabled])::before {
                display: block;
            }
        }

        &:hover,
        &.hovered-item:not(.selected-item) {
            background-color: ${(p) => p.theme.colors.gray[300]};

            & > div:not([disabled])::before {
                display: block;
            }
        }
        &:first-child {
            margin-top: ${LIST_ITEM_EDGE_PADDING}px;
        }
        &:last-child {
            margin-bottom: ${LIST_ITEM_EDGE_PADDING}px;
        }
    }
`;

export const ListItemIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

export const ListItemDotVariant = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
`;

export const StyledDigitalFontIcon = styled(DigitalFontIcon)`
    margin-left: 4px;
`;

export const DisabledOptionVariant = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
`;

export const DisabledText = styled.span`
    font-size: 12px;
    font-weight: 700;
`;
