import type { FC, PropsWithChildren } from 'react';
import { useState, useEffect } from 'react';

import { Session, updateSentryUser } from '@ringcx/shared';
import i18next from 'i18next';

import { AppContext, appContext } from './AppContext';
import { getUserDetailsResult } from './services/userDetailsService';
import { AuthStates } from './types';
import {
    STATE_MANAGEMENT_KEY,
    STATE_MANAGEMENT_NAME,
    CRM_EVENT_TYPE,
    CRM_REQUESTS,
    KEYS,
} from '../../constants';
import {
    getUserLocale,
    setLandingPageLocale,
} from '../../services/translate/translate.helpers';

function _postMessage(action: string) {
    const payload = JSON.parse(
        JSON.stringify({
            type: CRM_EVENT_TYPE,
            action,
        })
    );
    window.parent.postMessage(payload, '*');
}

function _createPostMessageFunction(eventName: string) {
    return () => {
        _postMessage(eventName);
    };
}

function _loadCRMLocale() {
    const postMessage = _createPostMessageFunction(CRM_REQUESTS.GET_CRM_LOCALE);
    postMessage();
}

Session.initClientAppType();

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [appContextState, setAppContextState] = useState({
        ...appContext,
    });

    const _handleUserDetailsResult = async () => {
        const userDetails = await getUserDetailsResult();

        i18next.changeLanguage(
            getUserLocale(userDetails.regionalSettings?.language)
        );

        updateSentryUser();

        //setting userLocale in local storage for landing page
        if (userDetails.regionalSettings?.language) {
            setLandingPageLocale(
                userDetails.regionalSettings.language as string
            );
        }

        setAppContextState((prevState) => ({
            ...prevState,
            ...userDetails,
            authState: AuthStates.AUTHENTICATED,
        }));
    };

    const _handleAuthenticationError = () => {
        setAppContextState((prevState) => ({
            ...prevState,
            authState: AuthStates.NOT_AUTHENTICATED,
        }));
    };

    const refreshAuthentication = () => {
        if (appContextState.authState !== AuthStates.UNKNOWN) {
            setAppContextState((prev) => ({
                ...prev,
                authState: AuthStates.REFRESHING_AUTHENTICATION,
            }));
        }
    };

    useEffect(() => {
        switch (appContextState.authState) {
            case AuthStates.UNKNOWN:
                Session.init(false)
                    .then(() => _handleUserDetailsResult())
                    .catch(() => _handleAuthenticationError());
                break;
            // Refresh the current user's authentication
            case AuthStates.REFRESHING_AUTHENTICATION:
                Session.refreshToken()
                    .then(() => _handleUserDetailsResult())
                    .catch(() => _handleAuthenticationError());
                break;
        }
    }, [appContextState.authState]);

    useEffect(() => {
        const stateManagement =
            localStorage.getItem(STATE_MANAGEMENT_KEY) === 'true' || false;
        setAppContextState((prevState) => ({
            ...prevState,
            stateManagement,
        }));
        Object.defineProperty(window, STATE_MANAGEMENT_NAME, {
            value: (val: string) => {
                // true/false values are set to be string due to localStorage limitations
                const stringVal = val.toString();
                if (stringVal === 'true' || stringVal === 'false') {
                    localStorage.setItem(STATE_MANAGEMENT_KEY, stringVal);
                    setAppContextState((prevState) => ({
                        ...prevState,
                        stateManagement: stringVal === 'true' || false,
                    }));
                } else {
                    window.console.error(
                        "Acceptable values: 'true' || 'false'"
                    );
                }
            },
            configurable: true,
            writable: true,
        });
    }, []);

    useEffect(() => {
        _loadCRMLocale();
        const handleMessage = (event: MessageEvent) => {
            const { action, data, type } = event.data;
            if (
                type === CRM_EVENT_TYPE &&
                action === CRM_REQUESTS.SET_CRM_LOCALE
            ) {
                i18next.changeLanguage(data.locale);
                localStorage.setItem(KEYS.OVERRIDE_LOCALE, data.locale);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <AppContext.Provider
            value={{ ...appContextState, refreshAuthentication }}
        >
            {children}
        </AppContext.Provider>
    );
};
