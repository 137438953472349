import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import { Session } from '@ringcx/shared';

import TopHat from './components/TopHat';
import { useTopHatEventService } from './TopHat.service';
import TopHatContext from './TopHatContext';
import type { ITopHatState } from './types';
import { FlatRef } from '../../types/FlatRef';

const TopHatProvider: FC<PropsWithChildren> = ({ children }) => {
    const [topHat, setTopHat] = useState<FlatRef<ITopHatState>>(
        new FlatRef({
            queue: {},
            current: null,
        })
    );

    useTopHatEventService(topHat, setTopHat);

    return (
        <TopHatContext.Provider value={[topHat, setTopHat]}>
            {Session.isEmbeddedAgentClientAppType() ? null : <TopHat />}
            {children}
        </TopHatContext.Provider>
    );
};

export { TopHatProvider };
