export const REQUEST_VERSION_ERROR_MSG = 'Cannot request the app manifest';
export const GETTING_VERSION_ERROR_MSG = 'Cannot get the app version';
export const VERSION_FORMAT_ERROR_MSG = 'Version has an incompatible format';

//braunch version for preview looks like: ticket number - commit hash (8 symbols)
//use for preview after merge request
//example: EA-1234-c1f315ab
export const BRANCH_VERSION_PREVIEW_REGEXP = new RegExp(
    /^(.+)-(\d+)-([\d\w]{8})$/,
    'i'
);

// branch version looks like: branch name - commit hash (8 symbols) - counter
// example: develop-spb-fe1-c1f315ab-354
export const BRANCH_VERSION_REGEXP = new RegExp(
    /^(.+)-([\d\w]{8})-(\d+)$/,
    'i'
);

// package version looks like semver roughly: major version . minor version . patch version - build version
// example: 22.1.1-3 or 22.1.2-rc.7
export const PACKAGE_VERSION_REGEXP = new RegExp(
    /^(\d+)\.(\d+)\.(\d+)(?:-(?:\w+(?:\.)?)?(\d+))?$/,
    'i'
);

export const DEFAULT_MANIFEST_PATH = 'static/manifest.json';
export const DEFAULT_TIMEOUT = 300000;
