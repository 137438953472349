import localProvider from './providers/local';
import mixpanelProvider from './providers/mixpanel';
import type {
    AnalyticsProvider,
    InitOptions,
    SubAccountChangedOptions,
    RegisterOptions,
} from './types';
export enum ProviderTypes {
    MIXPANEL = 'mixpanel',
}

export class Analytics {
    private readonly provider: AnalyticsProvider;
    private subAccountId: InitOptions['subAccountId'];
    private subAccountName: InitOptions['subAccountName'];
    private mainAccountId: InitOptions['mainAccountId'];
    private mainAccountName: InitOptions['mainAccountName'];
    private rcAccountId: InitOptions['rcAccountId'];
    private initialized: boolean;

    constructor(
        provider: ProviderTypes = ProviderTypes.MIXPANEL,
        params?: {
            token: string;
            cookieDomain?: string;
        }
    ) {
        if (!params?.token) {
            this.provider = localProvider;
            this.provider.loadProvider('LOCAL');
        } else {
            switch (provider) {
                case ProviderTypes.MIXPANEL:
                    this.provider = mixpanelProvider;
                    this.provider.loadProvider(
                        params.token,
                        params.cookieDomain
                    );
                    break;
                default:
                    this.provider = localProvider;
                    this.provider.loadProvider('LOCAL');
            }
        }

        this.subAccountId = '';
        this.subAccountName = '';
        this.mainAccountId = '';
        this.mainAccountName = '';
        this.rcAccountId = '';
        this.initialized = false;
    }

    public init({
        id,
        firstName,
        lastName,
        email,
        agentType,
        isSSO,
        platformId,
        subAccountId,
        subAccountName,
        mainAccountId,
        mainAccountName,
        rcAccountId,
        accountId,
        appVersion,
        accountType,
        app,
    }: InitOptions): void {
        if (!this.initialized) {
            this.initialized = true;
            this.subAccountId = subAccountId;
            this.subAccountName = subAccountName;
            this.mainAccountId = mainAccountId;
            this.mainAccountName = mainAccountName;
            this.rcAccountId = rcAccountId;

            this.provider.initialize({
                id,
                firstName,
                lastName,
                email,
                agentType,
                accountId,
                rcAccountId,
                mainAccountId,
                platformId,
                appVersion,
                accountType,
                isSSO,
                app,
            });
        }
    }

    public register(info: RegisterOptions): void {
        this.provider.register(info);
    }

    public subAccountChanged({
        subAccountId,
        subAccountName,
        mainAccountId,
        mainAccountName,
        rcAccountId,
    }: SubAccountChangedOptions): void {
        this.subAccountId = subAccountId;
        this.subAccountName = subAccountName;
        this.mainAccountId = mainAccountId;
        this.mainAccountName = mainAccountName;
        this.rcAccountId = rcAccountId;
    }

    public track(eventName: string, properties: Record<string, any>): void {
        this.provider.track(eventName, properties);
    }

    public reset(): void {
        this.provider.reset();
    }

    public alias(existingAliasId: string, otherDistinctId: string): void {
        this.provider.alias(existingAliasId, otherDistinctId);
    }

    public getDistinctId(): string {
        return this.provider.getDistinctId();
    }
}
