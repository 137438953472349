import type { FC } from 'react';
import { useCallback, useContext } from 'react';

import { Error404Page } from '@ringcx/ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROOT } from '../../constants/paths';
import { AppContext, AuthStates } from '../../contexts';
import PageLayout from '../../layouts/PageLayout';

const Error404: FC = () => {
    const { push } = useHistory();
    const { authState } = useContext(AppContext);
    const isAuthenticated = authState === AuthStates.AUTHENTICATED;
    const { t } = useTranslation();

    const onClick = useCallback(() => push(ROOT), [push]);

    return (
        <PageLayout>
            <Error404Page
                {...{
                    title: t('404.TITLE'),
                    subtitle: t('404.TEXT'),
                    buttonText: isAuthenticated ? t('GENERICS.BACK') : '',
                    onClick,
                }}
            />
        </PageLayout>
    );
};

export default Error404;
