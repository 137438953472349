import type { FC } from 'react';
import { useCallback, useContext, useMemo } from 'react';

import { Navigation, ProductType, Session } from '@ringcx/shared';
import { useReportAnIssue } from '@ringcx/ui';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
    DEFAULT_AGENT_LOGO,
    DEFAULT_LOGO,
    REPORT_AN_ISSUE_STORAGE_KEY,
} from './constants';
import { LogoutMenuItem, StyledTopPanel } from './Header.styled';
import { SELECT_AGENT } from '../../constants/paths';
import { AppContext, AuthStates } from '../../contexts';
import { AnalyticsSvc } from '../../services/analytics';
import { AuthClientCrp } from '../../services/problemReport/authClientCrp';

const Header: FC = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const appSwitcherLabels = useMemo(() => {
        return {
            AGENT: t('APPS.AGENT'),
            ANALYTICS: t('APPS.ANALYTICS'),
            ADMIN: t('APPS.ADMIN'),
            WEM: t('APPS.WEM_ENTERPRISE'),
            AI: t('APPS.AI'),
            ENGAGE: t('APP_SWITCHER.ENGAGE'),
        };
    }, [t]);

    const {
        authState,
        rcUser,
        mainAccountId: contextMainAccountId,
    } = useContext(AppContext);
    const isAuthenticated = authState === AuthStates.AUTHENTICATED;
    const isNotAuthenticated = authState === AuthStates.NOT_AUTHENTICATED;

    const isReportAnIssueEnabled = useMemo(() => {
        return localStorage.getItem(REPORT_AN_ISSUE_STORAGE_KEY) === 'true';
    }, []);

    const [reportAnIssueButton, reportAnIssueModal] = useReportAnIssue(
        (reportData) => {
            return AuthClientCrp.getInstance().handleProblemReportSubmit(
                ProductType.RCX,
                reportData
            );
        }
    );

    const onClickLogout = useCallback(() => {
        Session.logout().finally(() => {
            const loginUrl = Navigation.getLoginUrl();
            Session.clearSession();
            window.location.assign(loginUrl);
        });
    }, []);

    const userMenuData = useMemo(() => {
        if (isAuthenticated) {
            let userData;

            if (rcUser) {
                const { firstName, lastName, email } = rcUser;
                userData = {
                    fullName: '',
                    firstName: firstName || '',
                    lastName: lastName || '',
                    email: email || '',
                };
                userData.fullName =
                    `${userData.firstName} ${userData.lastName}`.trim();
            }

            const items = [
                {
                    component: isReportAnIssueEnabled
                        ? reportAnIssueButton
                        : null,
                },
                {
                    component: (
                        <LogoutMenuItem onClick={onClickLogout}>
                            {t('GENERICS.SIGN_OUT')}
                        </LogoutMenuItem>
                    ),
                },
            ];

            return {
                userData,
                items,
            };
        }

        return undefined;
    }, [
        isAuthenticated,
        rcUser,
        reportAnIssueButton,
        onClickLogout,
        t,
        isReportAnIssueEnabled,
    ]);

    const defaultLogo = useMemo(
        () =>
            pathname.startsWith(SELECT_AGENT)
                ? DEFAULT_AGENT_LOGO
                : DEFAULT_LOGO,
        [pathname]
    );

    const mainAccountId = useMemo(() => {
        if (isAuthenticated || isNotAuthenticated) {
            return contextMainAccountId || undefined;
        }

        return '';
    }, [contextMainAccountId, isAuthenticated, isNotAuthenticated]);

    const onTrackAnalytics = (event: string, property?: any) => {
        AnalyticsSvc.track(event, property);
    };

    return (
        <>
            <StyledTopPanel
                {...{
                    mainAccountId,
                    defaultLogo,
                    userMenuData,
                    disableAppSwitcher: !userMenuData,
                    appSwitcherLabels,
                    onTrackAnalytics,
                }}
            />
            {reportAnIssueModal}
        </>
    );
};

export default Header;
