import type { FC } from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

import type { AgentTable } from './types';
import {
    StyledTable,
    HeaderTableCell,
    StyledTableBody,
} from '../../AgentPicker.styled';
import { Row } from '../Row';

const Table: FC<AgentTable> = ({ agentDetails }) => {
    const { t } = useTranslation();
    return (
        <StyledTable>
            <TableHead>
                <TableRow>
                    <HeaderTableCell>
                        {t('AGENT_PICKER.ACCOUNT_NAME')}
                    </HeaderTableCell>
                    <HeaderTableCell>
                        {t('AGENT_PICKER.AGENT_GROUP')}
                    </HeaderTableCell>
                    <HeaderTableCell>{t('AGENT_PICKER.ROLE')}</HeaderTableCell>
                </TableRow>
            </TableHead>
            <StyledTableBody>
                {agentDetails.map(
                    ({
                        agentId,
                        agentType,
                        accountName,
                        agentGroupName,
                        accountId,
                    }) => (
                        <Row
                            key={agentId}
                            {...{
                                agentId,
                                agentType,
                                accountName,
                                agentGroupName,
                                accountId,
                            }}
                        />
                    )
                )}
            </StyledTableBody>
        </StyledTable>
    );
};

export default Table;
