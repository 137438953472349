import { useEffect, useRef } from 'react';

import { AppVersionComparison } from '@ringcx/shared';

import { TopHatPriorities } from '../components/TopHat/constants';
import type { ITopHatMessage } from '../components/TopHat/types';
import useTopHat from '../components/TopHat/UseTopHat';

type UseAppVersionNotification = (props: {
    manifestPath?: string;
    timeout?: number;
    urgentDifference: ITopHatMessage;
    satisfiedDifference: ITopHatMessage;
}) => void;

export const useAppVersionNotification: UseAppVersionNotification = ({
    manifestPath,
    timeout,
    urgentDifference,
    satisfiedDifference,
}) => {
    const topHatRef = useRef(useTopHat());

    useEffect(() => {
        AppVersionComparison.start({
            manifestPath,
            timeout,
            onUrgentDifference: () => {
                topHatRef.current.error(urgentDifference.text, {
                    priority: TopHatPriorities.error,
                    ...urgentDifference.options,
                });
            },
            onSatisfiedDifference: () => {
                topHatRef.current.info(satisfiedDifference.text, {
                    priority: TopHatPriorities.info,
                    closeWithX: { action: topHatRef.current.pop },
                    ...satisfiedDifference.options,
                });
            },
            onError: (reason) => {
                window.console.warn(reason);
            },
        });
    }, [
        manifestPath,
        satisfiedDifference.options,
        satisfiedDifference.text,
        timeout,
        urgentDifference.options,
        urgentDifference.text,
    ]);
};
