import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

import type { BGTypes } from './types';
import { device } from '../../../../constants/breakpoints';

export const Name = styled.span`
    text-align: center;
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 20px;
    display: block;
    text-transform: none;
    margin-top: 12px;
    margin-bottom: 5px;
`;

export const Button = styled(MuiButton)`
    && {
        display: inline-flex;
        margin-right: 32px;
        @media ${device.crm} {
            margin-right: 0px;
        }
        margin-bottom: 30px;
        padding: 0;
        background: ${({ theme }) => theme.colors.gray[0]};
        border-radius: 24px;
        border: 1px solid ${({ theme }) => theme.colors.gray[200]};
        height: 165px;
        width: 176px;
        transition: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
            border: none;
            background: transparent;
        }
    }

    .MuiButton-label {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const StyledBGWrapper = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
`;

export const StyledBGButton = styled(MuiButton)<{ version: BGTypes }>`
    && {
        height: 40px;
        width: 50%;
        border: none;
        border-radius: 0;
        background-color: ${({ version }) => version};
        cursor: pointer;
    }
`;
