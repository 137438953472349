export const TEST_AID = {
    GRID_LIST: {
        CELL: 'eui-gl-cell',
        ROW: 'eui-gl-row',
        ROW_GROUP: 'eui-gl-row-group',
        SUB_ROW: 'eui-gl-sub-row',
        EMPTY_ROW: 'eui-gl-empty-row',
        HEADER: 'eui-gl-header',
        COLUMN_HEADER: 'eui-column-header',
        EMPTY_FILTER_RESULT: 'eui-gl-empty-filter-result',
        SORT_LABEL: 'eui-gl-sort-label',
        ROW_OPEN: 'gl-row-open',
    },
    TOPHAT: 'eui-tophat',
    TOPHAT_CLOSE_BUTTON: 'eui-tophat-closebutton',
    TOPHAT_ACTION_PRIMARY: 'eui-tophat-action_primary',
    TOPHAT_ACTION_SECONDARY: 'eui-tophat-action_secondary',
    TOPHAT_OLD: 'eui-tophat-old',
    TOPHAT_CLOSE_BUTTON_OLD: 'eui-tophat-closebutton-old',
    TOPHAT_ACTION_PRIMARY_OLD: 'eui-tophat-action_primary-old',
    TOPHAT_ACTION_SECONDARY_OLD: 'eui-tophat-action_secondary-old',
    DROPDOWN_LIST_GROUP_ITEM: 'eui-dropdown-list-group-item',
    USER_MENU_TOGGLE: 'eui-user-menu-toggle',
    USER_MENU: 'eui-user-menu',
    USER_MENU_EMAIL: 'eui-user-menu-email',
    MENU_HAMBURGER: 'eui-menu-hamburger',
    APP_SWITCHER_TOGGLE: 'eui-app-switcher-toggle',
    APP_SWITCHER: 'eui-app-switcher',
    APP_SWITCHER_LIST_ITEM: 'eui-app-switcher-list-item',
    TEXT_INPUT: 'eui-text-input',
    BADGE: 'eui-badge',
    CLOSE_X_BUTTON: 'eui-close-x',
    DIALOG_TITLE: 'eui-dialog-title',
    NAV_MENU_ITEM: 'eui-nav-menu-item',
    TOP_PANEL_DIVIDER: 'eui-top-panel-divider',
    NO_MATCH_FOUND: 'eui-no-match-found',
    MULTI_ACCOUNT_HEADER_CHECKBOX_LABEL:
        'eui-multi-account-header-checkbox-label',
    MULTI_ACCOUNT_ROW_CHECKBOX_LABEL: 'eui-multi-account-row-checkbox-label',
    MULTI_ACCOUNT_HEADER_CHECKBOX: 'eui-multi-account-header-checkbox',
    MULTI_ACCOUNT_ROW_CHECKBOX: 'eui-multi-account-row-checkbox',
    MULTI_TOGGLE_CLOSE_ICON: 'multi-toggle-close-icon',
    SINGLE_SELECT_CLOSE_ICON: 'single-select-close-icon',
    SIDE_NAV: 'eui-side-nav',
    SIDE_NAV_SUB_MENU_HEADER: 'eui-side-nav-sub-menu-header',
    MENU_LINK_DTID: 'menu-link-',
    FOOTER_MESSAGE: 'footer-message',
    SEARCH_BAR: 'eui-search-bar',
    MULTI_TOOGLE_CLOSE_BTN: 'multi-toggle-close-btn',
    TAB_CONTAINER: 'eui-tab-container',
    AUTOCOMPLETE: {
        GROUP_HEADER: 'eui-autocomplete-group-header',
    },
    FEEDBACK_CANCEL_BUTTON: 'feedback-cancel-button',
    FEEDBACK_DESCRIPTION: 'feedback-description',
    FEEDBACK_SEND_BUTTON: 'feedback-send-button',
    FEEDBACK_WHEN: 'feedback-when',
    SKILL_PROFILE: {
        ROW_CHECKBOX: 'skill-profile-row-checkbox',
        HEADER_CHECKBOX: 'skill-profile-header-checkbox',
    },
    TOAST_CLOSE_BUTTON: 'toast-close-button',
    NUMERIC_STEPPER: {
        MINUS_BUTTON: 'numeric-stepper-minus-button',
        PLUS_BUTTON: 'numeric-stepper-plus-button',
        INPUT: 'numeric-stepper-input',
    },
    EXTERNAL_CREDENTIALS_ADD_BUTTON: 'external-credentials-add-button',
};

export const INDETERMINATE_FIELD_VALUE = Symbol.for('indeterminateFieldValue');
