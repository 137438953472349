import { type FC } from 'react';

import { Navigation, Session, BYOT_RCX_CLIENT_APP_TYPE } from '@ringcx/shared';
import { useTranslation } from 'react-i18next';

import type { AgentTable } from './types';
import { sendTokenToByot } from '../../../../helpers/sendTokenToByot';
import {
    AgentContainer,
    AgentColumn,
    AgentRow,
    AgentNextRow,
    AgentAccount,
} from '../../AgentPicker.styled';

const CardTable: FC<AgentTable> = ({ agentDetails }) => {
    const { t } = useTranslation();

    const goToAgent = async (agentId: number) => {
        const href = await Navigation.getAgentUrl(agentId);
        if (Session.rcxClientAppType === BYOT_RCX_CLIENT_APP_TYPE) {
            sendTokenToByot(agentId);
        }

        window.location.assign(href);
    };

    return (
        <>
            {agentDetails.map(
                ({ agentId, agentType, accountName, agentGroupName }) => (
                    <AgentContainer
                        data-aid='row'
                        key={agentId}
                        onClick={() => goToAgent(agentId)}
                    >
                        <AgentNextRow>
                            <AgentRow>
                                <AgentAccount>{accountName}</AgentAccount>
                            </AgentRow>
                        </AgentNextRow>
                        <AgentNextRow>
                            <AgentRow>
                                <AgentColumn>
                                    {t('AGENT_PICKER.AGENT_GROUP')}
                                </AgentColumn>
                                <AgentColumn>{agentGroupName}</AgentColumn>
                            </AgentRow>
                            <AgentRow>
                                <AgentColumn>
                                    {t('AGENT_PICKER.ROLE')}
                                </AgentColumn>
                                <AgentColumn>{agentType}</AgentColumn>
                            </AgentRow>
                        </AgentNextRow>
                    </AgentContainer>
                )
            )}
        </>
    );
};

export default CardTable;
