import { v4 as uuid } from 'uuid';

import { ENGAGE_CLIENT_REQUEST_ID_HEADER_NAME } from './constants';
import type { EngageClientRequestIdHeader, ILoggingService } from './types';

let __componentName = '';

export const initLoggingService = ({
    componentName,
}: ILoggingService): void => {
    __componentName = componentName;
};

export const getEngageClientRequestIdHeader =
    (): EngageClientRequestIdHeader => {
        const clientRequestId = `${__componentName}:${uuid()}`;
        return { [ENGAGE_CLIENT_REQUEST_ID_HEADER_NAME]: clientRequestId };
    };
