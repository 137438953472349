import { KEYS } from './constant';

export { getLocaleOverride, getFormatLocaleOverride } from './versionHelper';

export const checkHelperAvailability = () => {
    const helperStatus = localStorage.getItem(KEYS.AVAILABILITY);

    return helperStatus === 'true';
};

export const changeHelperAvailability = async (status?: boolean) => {
    if (typeof status !== 'boolean' && checkHelperAvailability()) {
        status = true;
    }

    if (typeof status === 'boolean') {
        localStorage.setItem(KEYS.AVAILABILITY, String(status));

        const { start, stop } = await import('./versionHelper');

        status ? start() : stop();
    }
};

export const registerVersionStatusHandlers = () => {
    window.versionHelper = {
        enable() {
            return changeHelperAvailability(true);
        },
        disable() {
            return changeHelperAvailability(false);
        },
    };
};

export const initVersionsHelper = async (forceState?: boolean) => {
    await changeHelperAvailability(forceState);

    registerVersionStatusHandlers();
};
