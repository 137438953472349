import type { FC } from 'react';

import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';

import { Button, CloseIcon } from './CloseAction.styled';

export const CloseAction: FC<{ snackKey: OptionsObject['key'] }> = ({
    snackKey,
}) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <Button
            variant={'text'}
            onClick={() => closeSnackbar(snackKey)}
            aria-label='close'
        >
            <CloseIcon />
        </Button>
    );
};
