import { Sanitization } from '@ringcx/pii-interceptor';
import type { ILogStorage } from '@ringcx/shared';
import {
    createDateRangeQuery,
    ConsoleHooks,
    LogStorage,
    Session,
    KEY_DTS,
    MAX_RECORDS_LIMIT,
    DAYS_TO_KEEP,
} from '@ringcx/shared';

import type { ConsoleLogRecord } from './types';

const LOGGER_NAME = 'AuthClientConsoleLogging';
const STORE_NAME = 'authClientConsoleLogger';

const INDEX_TYPE = 'type';
const INDEX_USER = 'userId';
const INDEX_TYPE_AND_USER = 'typeAndUser';

const KEY_TYPE = 'type';
const KEY_USER = 'userId';

// TODO: Consider to move more logger funcitonality to @ringcx/shared
export class ConsoleLogger {
    private static instance: ConsoleLogger;
    private logStorage: Nullable<ILogStorage<ConsoleLogRecord>> = null;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    public static getInstance() {
        if (!ConsoleLogger.instance) {
            ConsoleLogger.instance = new ConsoleLogger();
        }

        return ConsoleLogger.instance;
    }

    public async initialize() {
        try {
            if (this.logStorage) {
                return;
            }

            this.logStorage = await LogStorage.create<ConsoleLogRecord>(
                {
                    loggerName: LOGGER_NAME,
                    dbVersion: 1,
                    storeName: STORE_NAME,
                    indices: [
                        { name: INDEX_TYPE, keyPath: KEY_TYPE },
                        { name: INDEX_USER, keyPath: KEY_USER },
                        {
                            name: INDEX_TYPE_AND_USER,
                            keyPath: [KEY_TYPE, KEY_USER],
                        },
                    ],
                    purgeOld: true,
                    daysToKeep: DAYS_TO_KEEP,
                },
                (storage) => {
                    ConsoleHooks.getInstance().addConsoleHook((type, args) => {
                        const agentDetails =
                            Session.getUserDetails().agentDetails;
                        const currentAgent = agentDetails?.[0];
                        const agentId = currentAgent?.agentId;
                        const fullName = currentAgent
                            ? `${currentAgent?.firstName} ${currentAgent?.lastName}`.trim()
                            : '';

                        storage.addRecord(args, {
                            type,
                            userId: agentId?.toString() ?? '',
                            userName: Sanitization.maskString(fullName),
                        });
                    });
                }
            );
        } catch (error) {
            console.error('Failed to initialize ConsoleLogger', error);
        }
    }

    public async getConsoleLogRecords({
        type,
        maxRows = MAX_RECORDS_LIMIT,
        startDate,
        endDate,
    }: {
        type?: string;
        maxRows?: number;
        startDate?: Date;
        endDate?: Date;
    } = {}) {
        if (!this.logStorage) {
            return [];
        }
        const agentDetails = Session.getUserDetails().agentDetails;
        const currentAgent = agentDetails?.[0];
        const userId = currentAgent?.agentId ?? '';

        return this.logStorage.getRecords({
            index: type ? INDEX_TYPE_AND_USER : INDEX_USER,
            query: type
                ? IDBKeyRange.only([type, userId])
                : IDBKeyRange.only(userId),
            limit: maxRows,
            ranges: [
                {
                    keyPath: KEY_DTS,
                    range: createDateRangeQuery(startDate, endDate),
                },
            ],
        });
    }
}
