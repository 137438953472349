import type { FC, UIEventHandler, SyntheticEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

import {
    StyledCloseIcon,
    StyledDialog,
    StyledDialogActions,
    StyledDialogBottomText,
    StyledDialogContent,
    StyledDialogContentText,
    StyledDialogIconButton,
    StyledDialogTitle,
} from './Dialog.styled';
import type { IStyledDialogProps } from './types/Dialog';
import { TEST_AID } from '../../constants';

const Dialog: FC<IStyledDialogProps> = ({
    open,
    onClose,
    content = null,
    actions,
    dialogTitle,
    closeButtonText,
    bottomText,
    hideCloseWithX = false,
    disabledX = false,
    maxWidth = undefined,
    visible = false,
    scrollable = false,
    disableBackdropClick,
    onExited: deprecatedOnExited,
    ...props
}) => {
    const [isFit, setIsFitValue] = useState<boolean>(true);
    const [isScrolled, setIsScrolled] = useState(false);

    const resizeObserver = useMemo(
        () =>
            new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.target.parentElement) {
                        const { clientHeight, scrollHeight } =
                            entry.target.parentElement;
                        setIsFitValue(clientHeight >= scrollHeight);
                    }
                }
            }),
        []
    );

    const dialogContentRef = useCallback(
        (element: HTMLDivElement) => {
            if (element) {
                for (const child of Array.from(element.children)) {
                    resizeObserver.observe(child);
                }
            } else {
                resizeObserver.disconnect();
            }
        },
        [resizeObserver]
    );

    const handleScroll: UIEventHandler<HTMLDivElement> = (event) => {
        setIsScrolled(event.currentTarget.scrollTop > 0);
    };

    const dialogInfo =
        typeof content === 'string' ? (
            <StyledDialogContentText>{content}</StyledDialogContentText>
        ) : (
            content
        );

    const handleOnClose = useCallback(
        (e: SyntheticEvent, reason?: string) => {
            if (reason !== 'backdropClick' || !disableBackdropClick) {
                onClose(e);
            }
        },
        [disableBackdropClick, onClose]
    );

    return (
        <StyledDialog
            {...{
                open,
                onClose: handleOnClose,
                maxWidth,
                $visible: visible,
                $scrollable: scrollable,
                TransitionProps: {
                    onExited: deprecatedOnExited,
                },
                ...props,
            }}
        >
            {!hideCloseWithX && (
                <StyledDialogIconButton
                    disabled={disabledX}
                    disableRipple
                    disableFocusRipple
                    size='small'
                    onClick={handleOnClose}
                    data-aid={TEST_AID.CLOSE_X_BUTTON}
                    {...(closeButtonText && { title: closeButtonText })}
                >
                    <StyledCloseIcon width={14} height={14} />
                </StyledDialogIconButton>
            )}

            <StyledDialogTitle
                data-aid={TEST_AID.DIALOG_TITLE}
                withShadow={isScrolled}
            >
                {dialogTitle}
            </StyledDialogTitle>
            <StyledDialogContent
                ref={dialogContentRef}
                withBorder={!isFit}
                maxWidth={maxWidth}
                scrollable={scrollable}
                onScroll={handleScroll}
            >
                {dialogInfo}
            </StyledDialogContent>
            {actions && <StyledDialogActions>{actions}</StyledDialogActions>}
            {bottomText && (
                <StyledDialogBottomText>{bottomText}</StyledDialogBottomText>
            )}
        </StyledDialog>
    );
};

export default Dialog;
