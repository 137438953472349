import {
    BRANCH_VERSION_REGEXP,
    PACKAGE_VERSION_REGEXP,
    BRANCH_VERSION_PREVIEW_REGEXP,
} from './constants';
import type { ComparisonResult } from './types';

export const isBranchPreviewVersion = (v: string): boolean => {
    return BRANCH_VERSION_PREVIEW_REGEXP.test(v);
};

export const isBranchVersion = (v: string): boolean => {
    return BRANCH_VERSION_REGEXP.test(v);
};

export const isPackageVersion = (v: string): boolean => {
    return PACKAGE_VERSION_REGEXP.test(v);
};

export const hasSameVersionFormat = (v1: string, v2: string): boolean => {
    const packageVersions = [v1, v2].every(isPackageVersion);
    const branchVersions = [v1, v2].every(isBranchVersion);
    const branchPreviewVersions = [v1, v2].every(isBranchPreviewVersion);
    return packageVersions || branchVersions || branchPreviewVersions;
};

export const validate = (v: string): boolean => {
    return (
        isBranchVersion(v) || isPackageVersion(v) || isBranchPreviewVersion(v)
    );
};

export const compare = (v1: string, v2: string): ComparisonResult => {
    const compareArray = [];

    if ([v1, v2].every(isBranchPreviewVersion)) {
        const v1Versions = v1.match(BRANCH_VERSION_PREVIEW_REGEXP);
        const v2Versions = v2.match(BRANCH_VERSION_PREVIEW_REGEXP);

        if (v1Versions && v2Versions) {
            compareArray.push([+v1Versions[2], +v2Versions[2]]);
        }
    }

    if ([v1, v2].every(isBranchVersion)) {
        const v1Versions = v1.match(BRANCH_VERSION_REGEXP);
        const v2Versions = v2.match(BRANCH_VERSION_REGEXP);

        if (v1Versions && v2Versions) {
            compareArray.push([v1Versions[3], v2Versions[3]]);
        }
    }

    if ([v1, v2].every(isPackageVersion)) {
        const v1Versions = v1.match(PACKAGE_VERSION_REGEXP);
        const v2Versions = v2.match(PACKAGE_VERSION_REGEXP);

        if (v1Versions && v2Versions) {
            for (let i = 1; i < v1Versions.length; i++) {
                compareArray.push([+v1Versions[i], +v2Versions[i]]);
            }
        }
    }

    for (const [v1Version, v2Version] of compareArray) {
        if (v1Version > v2Version) {
            return 1;
        }

        if (v2Version > v1Version) {
            return -1;
        }
    }

    return 0;
};
