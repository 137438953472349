import { OLD_EDGE_NAME, NEW_EDGE_NAME } from '../constants';

/** Browser version represented as separate `number`s. This is required due to
 * the fact that SemVer, along with any Major.Minor(...) format, cannot be
 * safely expressed as a floating-point number in every hypothetical scenario.
 */
export type BrowserVersion = {
    /** Major version. */
    major: number;
    /** Trailing version numbers in order. */
    minors: number[];
};

// Namespaces are convenient here to pair the companion stuff with its type
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BrowserVersion {
    /** Compare two browser versions, basically 'a > b'. */
    export function gt(a: BrowserVersion, b: BrowserVersion): boolean {
        if (a.major !== b.major) {
            return a.major > b.major;
        }

        for (let i = 0; a.minors[i] !== undefined; i++) {
            // If we have an additional .a in our version, we're certainly >
            if (b.minors[i] === undefined) {
                return true;
            }

            if (a.minors[i] !== b.minors[i]) {
                return a.minors[i] > b.minors[i];
            }
        }

        return false;
    }

    const VERSION_REGEX = /^\d+(?:\.\d+)*$/;

    /** Check if version is a valid numeric version number. */
    export const validate: (verStr: string) => boolean =
        VERSION_REGEX.test.bind(VERSION_REGEX);

    /** Parse a version string into comparable format. */
    export function parse(versionString: string): BrowserVersion {
        const [major, ...minors] = versionString
            .split('.')
            .map((n) => parseInt(n, 10));

        let cutFrom = -1;

        // Find leftmost trailing zero real quick...
        for (let i = minors.length - 1; i >= 0 && minors[i] === 0; i--) {
            cutFrom = i;
        }

        // Remove trailing zeroes due to comparative irrelevance.
        if (cutFrom >= 0) {
            minors.splice(cutFrom);
        }

        return { major, minors };
    }
}

/** Record of minimal versions supported by the application. */
export type SupportedBrowsers = Record<string, BrowserVersion>;

// Namespaces are convenient here to pair the companion stuff with its type
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SupportedBrowsers {
    /** Create a record of version requirements from browserslist. */
    export function from(browsersList: string[]): SupportedBrowsers {
        const result: SupportedBrowsers = {};

        for (const browser of browsersList) {
            const [name, _version] = browser.split(' ');

            const [earliest] = _version.split('-');

            // Skip potential gibberish
            if (!BrowserVersion.validate(earliest)) {
                continue;
            }

            const version = BrowserVersion.parse(earliest);

            // If there's no entry or this version is earlier than one recorded,
            // overwrite.
            if (!result[name] || BrowserVersion.gt(result[name], version)) {
                result[name] = version;
            }
        }

        return result;
    }

    export type ComparisonResult = {
        unsupportedBrowser?: boolean;
        unsupportedBrowserVersion?: boolean;
    };

    // Namespaces are convenient here to pair the companion stuff with its type
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ComparisonResult {
        export const ok = {};

        export const unsupportedBrowser = {
            unsupportedBrowser: true,
        };

        export const unsupportedBrowserVersion = {
            unsupportedBrowserVersion: true,
        };
    }

    /** Compare browser version against supported browsers. */
    export function compare(
        browserVersions: SupportedBrowsers,
        browserName: string,
        browserVersion: string
    ): ComparisonResult {
        // Translate it to browserslist language...
        const name = (
            browserName === NEW_EDGE_NAME ? OLD_EDGE_NAME : browserName
        ).toLowerCase();

        const versionEntry = browserVersions[name];

        if (!versionEntry) {
            return ComparisonResult.unsupportedBrowser;
        }

        const version = BrowserVersion.parse(browserVersion);

        if (BrowserVersion.gt(versionEntry, version)) {
            return ComparisonResult.unsupportedBrowserVersion;
        }

        return ComparisonResult.ok;
    }

    /** Compare browser version against browserslist. */
    export function listCompare(
        browsersList: string[],
        browserName: string,
        browserVersion: string
    ): ComparisonResult {
        const versionList = SupportedBrowsers.from(browsersList);
        return SupportedBrowsers.compare(
            versionList,
            browserName,
            browserVersion
        );
    }
}
