import type { AxiosError } from 'axios';

// eslint-disable-next-line no-restricted-imports
import { t } from '../services/translate';

const axiosErrorParser = (e: AxiosError) => {
    let errorMessage = t('ERRORS.BASE');

    try {
        if (!e || !e.isAxiosError) {
            throw new Error(t('ERRORS.EXPECTED'));
        }

        const deserializedResp = e.response?.data;

        errorMessage =
            (deserializedResp &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (deserializedResp.detail || deserializedResp.message)) ||
            errorMessage;
    } catch (parseError) {
        window.console.warn(
            `errorMessageParser: couldn't parse error "${e}"! Cause: ${parseError}`
        );
    }

    return errorMessage;
};

export default axiosErrorParser;
