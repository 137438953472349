import styled from 'styled-components';

export const TextContainer = styled.div`
    line-height: 16px;
    color: ${({ theme }) => theme.colors.gray[850]};

    p {
        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
