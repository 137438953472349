import styled from 'styled-components';

export const PrimaryText = styled.h2`
    margin: 1.5em;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.27px;
    color: ${({ theme }) =>
        `var(--primary-text-color, ${theme.colors.gray[850]})`};
`;
