import styled from 'styled-components';

export const ErrorPageLayout = styled.div`
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, sans-serif;
`;

export const ErrorPageLogo = styled.div`
    margin-top: 20px;
    height: 28px;
    width: 210px;
    display: flex;
    justify-content: center;

    & > div {
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;

        & > img {
            width: 100%;
            height: 100%;
        }
    }
`;

export const ErrorPageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ErrorPageIcon = styled.div`
    margin-bottom: 20px;
    i {
        font-size: x-large;
        color: ${({ theme }) => theme.colors.secondary};
    }
`;

export const ErrorPageLine = styled.h3`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    margin: 0 28px;
`;

export const LogoutButton = styled.button`
    font-size: 14px;
    font-weight: 400;
    height: auto;
    margin-bottom: 16px;
    word-wrap: break-word;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    :hover {
        background-color: transparent;
    }
`;
