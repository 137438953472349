export interface IContentTypeHeader {
    'Content-Type': string;
}

export interface IAuthHeader {
    Authorization?: string;
}

export interface IRequestOptions {
    ignoreCache?: boolean;
    headers?: IContentTypeHeader & IAuthHeader;
    timeout?: number;
    body?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export enum SessionType {
    ADMIN = 'admin',
    AGENT = 'agent',
    ANALYTICS = 'analytics',
    NONE = 'none',
    RESET_PASSWORD = 'reset_password',
}

export enum LoginApp {
    ADMIN = 'ADMIN',
    AGENT = 'AGENT',
    ANALYTICS = 'ANALYTICS',
    SSO = 'SSO',
}

export interface IAccessTokenResult {
    response: string;
}

export enum DigitalJWTModifier {
    AGENT = 'agent',
    ADMIN = 'admin',
}

export interface IGetDigitalJWT {
    modifier: DigitalJWTModifier;
    evSubAccountId?: string;
    agentId?: string;
    force?: boolean;
}
