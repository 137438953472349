import type { FC } from 'react';
import { useMemo, useContext, useEffect, useState } from 'react';

import { Session } from '@ringcx/shared';
import { useTranslation } from 'react-i18next';

import {
    Link,
    Card,
    Title,
    ArrowBack,
    BackButton,
    StyledText,
    AgentPickerContainer,
    BackButtonContainer,
    CRMStyledText,
} from './AgentPicker.styled';
import { Table } from './components';
import CardTable from './components/Table/CardTable';
import { RESPONSIVE_BREAKPOINT } from '../../constants/breakpoints';
import { ROOT } from '../../constants/paths';
import { AppContext } from '../../contexts';
import PageLayout from '../../layouts/PageLayout';
import { AnalyticsSvc } from '../../services/analytics';

const AgentPicker: FC = () => {
    const [isCRMScreen, setIsCRMScreen] = useState(
        window.innerWidth <= RESPONSIVE_BREAKPOINT
    );

    useEffect(() => {
        AnalyticsSvc.track('Viewed RCX_agent_multipleAccountList Page');
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsCRMScreen(window.innerWidth <= RESPONSIVE_BREAKPOINT);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { ssoLogin, permissions, agentDetails } = useContext(AppContext);

    const { t } = useTranslation();

    const renderButton = useMemo(() => {
        if (
            ssoLogin &&
            [
                permissions.Agent,
                permissions.Analytics,
                permissions.Admin,
            ].filter((i) => i).length > 1
        ) {
            return (
                <BackButtonContainer>
                    <Link to={ROOT}>
                        <BackButton variant='text' color='primary'>
                            <ArrowBack />
                            {!isCRMScreen && t('GENERICS.BACK')}
                        </BackButton>
                    </Link>
                    {isCRMScreen && (
                        <StyledText>{t('GENERICS.SELECT_AN_ACC')}</StyledText>
                    )}
                </BackButtonContainer>
            );
        }

        return isCRMScreen ? (
            <BackButtonContainer>
                <CRMStyledText>{t('GENERICS.SELECT_AN_ACC')}</CRMStyledText>
            </BackButtonContainer>
        ) : null;
    }, [t, ssoLogin, permissions, isCRMScreen]);

    return (
        <PageLayout
            withoutHeader={
                Session.isEmbeddedAgentClientAppType() ||
                Session.isCRMClientAppType()
            }
        >
            <AgentPickerContainer>
                {renderButton}
                {!isCRMScreen && (
                    <Title>
                        <StyledText>{t('GENERICS.SELECT_AN_ACC')}</StyledText>
                    </Title>
                )}
                <Card elevation={0}>
                    {isCRMScreen ? (
                        <CardTable
                            {...{
                                agentDetails,
                            }}
                        />
                    ) : (
                        <Table
                            {...{
                                agentDetails,
                            }}
                        />
                    )}
                </Card>
            </AgentPickerContainer>
        </PageLayout>
    );
};

export default AgentPicker;
