import type { Callback, CallbackCondition } from './types';

export const listeners: {
    callbackCondition: unknown;
    callback: unknown;
}[] = [];

export const addListener = <A>(
    callbackCondition: CallbackCondition<A>,
    callback: Callback<A>
) => {
    listeners.push({
        callbackCondition,
        callback,
    });
};

export const removeListener = <A>(callback: Callback<A>) => {
    const index = listeners.findIndex(
        (listener) => listener.callback === callback
    );

    if (~index) {
        listeners.splice(index, 1);
    }
};

export const onIframeMessageListener = <A>(event: MessageEvent<A>) => {
    listeners.forEach((listener) => {
        if (
            typeof listener.callback === 'function' &&
            typeof listener.callbackCondition === 'function' &&
            listener.callbackCondition(event)
        ) {
            listener.callback(event.data);
        }
    });
};

export const waitMessage = <A>(
    callbackCondition: CallbackCondition<A>,
    stayActive: boolean,
    timeout: number
) => {
    return new Promise<A>((resolve, reject) => {
        const callback = (data: A) => {
            window.clearTimeout(timeoutTimer);

            if (!stayActive) {
                removeListener(callback);
            }

            resolve(data);
        };
        const timeoutTimer = window.setTimeout(() => {
            if (!stayActive) {
                removeListener(callback);
            }

            reject('Expired timeout wait callback from iframe');
        }, timeout);

        addListener<A>(callbackCondition, callback);
    });
};

export const registerHandler = () => {
    window.addEventListener('message', onIframeMessageListener);
};

export const unregisterHandler = () => {
    window.removeEventListener('message', onIframeMessageListener);
    listeners.length = 0;
};
