import colors from './colors';

const fontBase = 14;

const font = {
    family: "'Roboto', Helvetica, Arial, sans-serif",
    weight: 300,
    color: colors.gray[900],
    lineHeight: 1.1,
    size: {
        base: `${fontBase}px`,
        small: `${Math.ceil(fontBase * 0.85)}px`,
        large: `${Math.ceil(fontBase * 1.25)}px`,
    },
};

export default font;
