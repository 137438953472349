import type { FC, FormEvent } from 'react';
import { useState, useContext, useCallback, useMemo } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import type { ILoginType } from '@ringcx/shared';
import { Session } from '@ringcx/shared';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardContent,
    Label,
    Input,
    Button,
    ForgotPasswordLinkContainer,
    ForgotPasswordLink,
    InputContainer,
    EyeOpened,
    EyeClosed,
} from './Login.styled';
import { APP_TITLES } from '../../constants/appTitles';
import { FORGOT_PASSWORD } from '../../constants/paths';
import { AppContext } from '../../contexts';
import engageAuthJsErrorParser from '../../helpers/EngageAuthJsErrorParser';
import { useQuery } from '../../hooks';
import PageLayout from '../../layouts/PageLayout';

const ADMIN_ANALYTICS_APPS_REGEXP = /(admin|analytics)/;

const Login: FC = () => {
    const { app } = useParams<{ app: string }>();
    const platformId = useQuery('plat');
    const appName = app.toUpperCase();

    const { refreshAuthentication } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const isAdminOrAnalyticsApp = useMemo(
        () => ADMIN_ANALYTICS_APPS_REGEXP.test(app),
        [app]
    );

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSubmitting(true);

        Session.legacyLogin({
            username,
            password,
            platformId,
            loginType: app as ILoginType,
        })
            .then(refreshAuthentication)
            .catch((error) => {
                const errorMessage = `Login failed: ${engageAuthJsErrorParser(
                    error
                )}`;

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
            })
            .finally(() => setSubmitting(false));
    };

    const passwordResetLink = (
        <ForgotPasswordLinkContainer>
            <ForgotPasswordLink
                to={`${FORGOT_PASSWORD}${app}?plat=${platformId}`}
            >
                Forgot your password?
            </ForgotPasswordLink>
        </ForgotPasswordLinkContainer>
    );
    const viewChangeHandler = useCallback(() => {
        setViewPassword((prev) => !prev);
    }, []);

    return (
        <PageLayout>
            <Card elevation={0}>
                <form onSubmit={onSubmit}>
                    <CardHeader>{`${APP_TITLES[appName]} - Sign in`}</CardHeader>
                    <CardContent>
                        <Label htmlFor='username'>
                            {isAdminOrAnalyticsApp
                                ? 'Email or phone number'
                                : 'Username'}
                        </Label>
                        <Input
                            type='text'
                            id='username'
                            name='username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <Label htmlFor='password'>Password</Label>
                        <InputContainer>
                            <Input
                                type={viewPassword ? 'text' : 'password'}
                                id='password'
                                name='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {viewPassword ? (
                                <EyeClosed
                                    onClick={viewChangeHandler}
                                    data-aid='password-eye'
                                />
                            ) : (
                                <EyeOpened
                                    onClick={viewChangeHandler}
                                    data-aid='password-eye'
                                />
                            )}
                        </InputContainer>
                        <Button
                            size='small'
                            fullWidth
                            type='submit'
                            disabled={submitting}
                        >
                            {submitting ? (
                                <CircularProgress
                                    color='inherit'
                                    size='1.2em'
                                    data-aid='spinner'
                                />
                            ) : (
                                'Sign in'
                            )}
                        </Button>
                    </CardContent>
                </form>
                {isAdminOrAnalyticsApp && passwordResetLink}
            </Card>
        </PageLayout>
    );
};

export default Login;
