import MuiButton from '@material-ui/core/Button';
import MuiCard from '@material-ui/core/Card';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import { ArrowBack as MuiArrowBack } from '@material-ui/icons';
import { Button as EuiButton } from '@ringcx/ui';
import { Link as RRLink } from 'react-router-dom';
import styled from 'styled-components';

import { PrimaryText } from '../../components/PrimaryText';
import { device } from '../../constants/breakpoints';

export const Title = styled(PrimaryText)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
`;

export const BackButtonContainer = styled.div`
    @media not screen and ${device.crm} {
        position: absolute;
        left: -8px;
        top: -6px;
    }

    @media ${device.crm} {
        text-align: start;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
    }
`;

export const AgentNavigationContainer = styled.div`
    @media not screen and ${device.crm} {
        text-align: center;
        padding: 32px 0 16px 0;
    }

    @media ${device.crm} {
        padding: 0;
    }
`;

export const Button = styled(EuiButton)`
    && {
        font-weight: normal;
        font-size: 14px;
    }
`;

export const Link = styled(RRLink)`
    text-decoration: none;
`;

export const ArrowBack = styled(MuiArrowBack)`
    width: 24px !important;
    padding-right: 0 !important;
    margin-right: 8px;
`;

export const StyledText = styled.span`
    font-weight: normal;
    @media ${device.crm} {
        font-weight: 500;
        color: black;
        vertical-align: middle;
        margin-left: 20px;
    }
`;

export const CRMStyledText = styled.div`
    font-weight: 500;
    color: black;
    vertical-align: middle;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 0px;
    text-align: center;
`;

export const BackButton = styled(MuiButton)`
    && {
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        font-weight: normal;

        ${ArrowBack} {
            padding-right: 6px;
            width: 33px;
            height: auto;
        }
    }
`;

export const StyledTable = styled(MuiTable)`
    min-width: 100%;
    width: 100%;
    text-align: left;
`;

export const StyledTableBody = styled(MuiTableBody)``;

const verticalPadding = 10;

export const TableCommonCell = styled(MuiTableCell)`
    && {
        padding: ${verticalPadding}px 40px ${verticalPadding}px 0;

        &:first-child {
            width: 319px;
            padding-left: 8px;
        }

        &:nth-child(2) {
            width: 300px;
        }

        &:last-child {
            width: 83px;
            padding-right: 8px;
        }
    }
`;

export const TableCell = styled(TableCommonCell)`
    && {
        padding: ${verticalPadding - 1}px 40px ${verticalPadding}px 0;
        font-size: 14px;
        line-height: 20px;
        color: var(--primary-text-color);

        &:nth-child(2) {
            max-width: 300px;
        }
    }
`;

export const HeaderTableCell = styled(TableCommonCell)`
    && {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: ${({ theme }) =>
            `var(--secondary-text-color, ${theme.colors.gray[700]})`};
    }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TableRow = styled(({ ...restProps }) => (
    <MuiTableRow {...restProps} />
))<{ active?: boolean }>`
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) =>
            `var(--list-item-hover, ${theme.colors.gray[50]})`};
    }
`;

export const Card = styled(MuiCard)`
    @media not screen and ${device.crm} {
        margin: 0 auto;
        border: none;
        background-color: var(--table-row-background);
    }
`;

export const AgentPickerContainer = styled.div`
    @media not screen and ${device.crm} {
        margin: 60px auto 0;
        width: 800px;
        position: relative;
    }
`;

export const AgentContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
    margin: 0;
    &:hover {
        background-color: ${({ theme }) => theme.colors.gray[300]};
        cursor: pointer;
    }
`;

export const AgentRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AgentNextRow = styled.div``;

export const AgentColumn = styled.div`
    flex: 1;
    padding: 5px;
    display: flex;
    white-space: nowrap;

    &:nth-child(1) {
        justify-content: start;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding-left: 20px;
        width: 100%;
        text-wrap: wrap;
        text-align: left;
        color: ${({ theme }) => theme.colors.gray[750]};
    }
    &:nth-child(2) {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        flex: 1;
        padding-left: 20px;
        text-align: left;
        text-wrap: wrap;
    }
`;

export const AgentAccount = styled(AgentColumn)`
    && {
        color: ${({ theme }) => theme.colors.gray[900]};
    }
`;
