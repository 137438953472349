import type { FC } from 'react';
import { useMemo } from 'react';

import UserInfo from './components/UserInfo/UserInfo';
import UserItems from './components/UserItems/UserItems';
import type { IUserMenu } from './types/UserMenu';
import {
    UserMenuDivider,
    StyledPopper,
    StyledAvatarTextUser,
    StyledUserToggleButton,
    StyledAvatarUserCircle,
    size,
} from './UserMenu.styled';
import { TEST_AID } from '../../../../constants';
import Skeleton from '../../../Skeleton';

const UserMenu: FC<IUserMenu> = ({
    items,
    userMenuContainer,
    userData,
    loading,
}) => {
    const renderToggle = useMemo(() => {
        let content = <StyledAvatarUserCircle />;

        if (userData && (userData.firstName || userData.lastName)) {
            let result = '';
            if (userData.firstName) {
                result = userData.firstName[0].toUpperCase();
            }

            if (userData.lastName) {
                result += userData.lastName[0].toUpperCase();
            }

            content = (
                <StyledAvatarTextUser data-aid={TEST_AID.USER_MENU_TOGGLE}>
                    {result}
                </StyledAvatarTextUser>
            );
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <StyledUserToggleButton>{content}</StyledUserToggleButton>;
    }, [userData]);

    const renderUserInfo = useMemo(() => {
        if (userData) {
            const { fullName, email } = userData;
            return <UserInfo fullName={fullName} email={email} />;
        }
        return null;
    }, [userData]);

    return loading ? (
        <Skeleton variant='circle' width={size} height={size} />
    ) : (
        <StyledPopper
            data-aid={TEST_AID.USER_MENU}
            disabled={loading}
            container={userMenuContainer}
            toggleComponent={renderToggle}
        >
            {renderUserInfo}
            {userData && <UserMenuDivider />}
            <UserItems {...{ items }} />
        </StyledPopper>
    );
};

export default UserMenu;
