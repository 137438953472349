import type { IClaims } from '../../services/auth';

export function isNullOrUndefined(
    payload: unknown
): payload is null | undefined {
    return payload === null || payload === undefined;
}

export function isObject(payload: unknown): payload is object {
    return (
        typeof payload === 'object' &&
        !Array.isArray(payload) &&
        !isNullOrUndefined(payload) &&
        !(payload instanceof Date)
    );
}

export function isFunction(payload: unknown): payload is () => unknown {
    return !!payload && {}.toString.call(payload) === '[object Function]';
}

export const encodeAccessToken = (tokenData: IClaims) =>
    `the_start.${Buffer.from(JSON.stringify(tokenData)).toString(
        'base64'
    )}.the_end`;
