import { LoggingService } from '@ringcx/shared';
import axios from 'axios';

import AuthInterceptor from './authInterceptor';
import { COMPONENT_NAME } from '../../constants';

const Transport = axios.create();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Transport.interceptors.request.use(AuthInterceptor);

export const initRequestLogging = () => {
    LoggingService.initLoggingService({ componentName: COMPONENT_NAME });

    axios.interceptors.request.use((request) => {
        const clientRequestIdHeader =
            LoggingService.getEngageClientRequestIdHeader();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        request.headers = { ...request.headers, ...clientRequestIdHeader };
        return request;
    });
};
