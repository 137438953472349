import type { FC } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { EngageUiThemeProvider, ErrorBoundary } from '@ringcx/ui';
import { BrowserRouter } from 'react-router-dom';

import { SnackbarProvider } from './components/SnackbarProvider';
import { AppContextProvider } from './contexts';
import { useThemeMessageHandler } from './hooks/useThemeMessageHandler';
import { MainLayout } from './layouts/MainLayout';

const App: FC = () => {
    useThemeMessageHandler();

    return (
        <EngageUiThemeProvider>
            <ErrorBoundary>
                <AppContextProvider>
                    <SnackbarProvider>
                        <BrowserRouter>
                            <CssBaseline />
                            <MainLayout />
                        </BrowserRouter>
                    </SnackbarProvider>
                </AppContextProvider>
            </ErrorBoundary>
        </EngageUiThemeProvider>
    );
};

export default App;
