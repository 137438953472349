import type { RegisterOptions } from '@ringcx/shared';

import { analytics } from '../../helpers/analytics';

class AnalyticsSvc {
    static track(event: string, info: object = {}): void {
        if (analytics?.value) {
            analytics.value.track(event, info);
        }
    }

    static register(info: RegisterOptions): void {
        if (analytics?.value) {
            analytics.value.register(info);
        }
    }

    static getDistinctId(): string {
        if (analytics?.value) {
            return analytics.value.getDistinctId();
        }
        return '';
    }
}

export { AnalyticsSvc };
