import * as Sentry from '@sentry/browser';
import type { Extras } from '@sentry/core';
import type { Primitive } from 'utility-types';

import { AppPlatform, DEFAULT_ENVIRONMENT, RESTRICTED_URLS } from './constants';
import {
    getAppEnvironment,
    getSanitizedUser,
    isFilteredNetworkError,
    isOnetrustCookieError,
    isReactDevToolsWarning,
    isSentryTestingModeEnabled,
} from './helpers';

export type SentryConsoleLevel = 'log' | 'info' | 'warn' | 'debug' | 'assert';
export type SentryNetworkCodes = [number, number] | number;

export type SentryFilteredNetworkError = {
    errorCode: number;
    // Matched by entry in event URL
    urlPart: string;
    // If omitted, then events with any HTTP method will be filtered
    method?: string;
};

// TODO: Add more obligatory meta if necessary
export interface SentryMeta {
    app: string;
}

/**
 * Sentry Adapter config
 *
 * The `dsn` field is mandatory and should be set externally from app instance to cover possible case with different per-project Sentry DSN
 *
 * Properties `environment` and `version` would be set by Sentry Service itself if not provided
 * However, these fields could be overriden in Service implementation for specific app components
 *
 * Metadata can be extended using optional <T> generic type
 */
export interface SentryConfig<
    T extends Record<string, Primitive> = NonNullable<unknown>,
> {
    // Sentry Project DSN (i.e., for 'rcx-ui' project)
    // If not provided, Sentry won't be initialized
    dsn?: string;
    environment?: string;
    // Extendable metadata
    metadata?: SentryMeta & T;
    // List of error messages that should be additionally ignored from the client side
    // It could be a string (partial match) or regex pattern that match error messages
    ignoreErrors?: Array<string>;
    // Custom callback to drop some specific errors only for particular instance
    // It should return `true` in order to prevent sending the error to Sentry
    customIgnoreErrorsCallback?: (event: Sentry.ErrorEvent) => boolean;
    // Custom Console levels to be caught by Sentry in addition to default 'error' level
    customConsoleLevels?: Array<keyof Console>;
    // Custom Network codes to be caught by Sentry in addition to defaults
    // This array can contain tuples of [begin, end] (both inclusive), single status codes, or a combinations of both
    // Example: [[500, 505], 507]
    customNetworkCodes?: Array<SentryNetworkCodes>;
    // Custom callback to perform additional actions on error sending
    onErrorSending?: (event: Sentry.ErrorEvent) => void;
}

// List of error messages that should be ignored from the client side
// It could be a string (partial match) or regex pattern that match error messages
const ignoreErrors = [
    // TODO: Add more if necessary
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    '[webpack-dev-server]',
];

/**
 * Sentry Adapter Service
 * Metadata can be extended using optional <T> generic type
 *
 * It's designed to be initialized in components using the exported `initSentryService` method with custom configuration that could be extended as necessary
 *
 * Sentry Service instance should be initialized as soon as possible during component loading in order to catch potential errors
 *
 * In order to update Sentry user after initialization, you can use exported `updateSentryUser` method
 * (i.e. to update async User data in EAC and ESU components after successful login)
 *
 * In order to test it in DEV environment, you can execute `localStorage.setItem("isSentryTestingModeEnabled", "true")` command and refresh the page
 * It would also enable Test Sentry error emitter for DEV testing purposes using `window.emitTestSentryError("errorText")` command
 * Please DO NOT FORGET to switch testing mode OFF with `localStorage.removeItem("isSentryTestingModeEnabled")` command with page refresh after testing!
 *
 * TODO:
 *  - Implement Source Maps creation for chunks created with Webpack Dynamic Imports
 *  - Add simple error queue implementation that should catch all errors that happened before Sentry initialization
 *    We also could use that to catch errors that Sentry couldn't send due to bad or missing network connection
 *  - Add ApiRateLimitIntegration if necessary
 */
export class SentryService<
    T extends Record<string, Primitive> = NonNullable<unknown>,
> {
    private _initialized = false;
    private _testingModeEnabled = false;
    private config: SentryConfig<T>;
    private platform: AppPlatform;
    private version: string = '';

    constructor(config: SentryConfig<T>) {
        this.config = config;
        this.platform = this.determinePlatform();
        this._testingModeEnabled = isSentryTestingModeEnabled();
        if (typeof window !== 'undefined') {
            this.version = window.__settings?.versions?.reviewTag;
        }
    }

    // Getting correct platform type in order to not mix LAB/OPS envs with PROD
    private determinePlatform(): AppPlatform {
        // Fallback case
        if (typeof window === 'undefined') {
            return DEFAULT_ENVIRONMENT;
        }

        // Getting current env from config (if provided) or from node process
        const environment = this.config?.environment ?? getAppEnvironment();

        // Differentiate 'stage' env from 'production'
        if (environment === AppPlatform.PROD) {
            // If current URL will match to some regexp in the list, then it's not a prod env
            const isStageDomain = RESTRICTED_URLS.some(
                (url) => window?.location.hostname.match(url)
            );
            return isStageDomain ? AppPlatform.STAGE : AppPlatform.PROD;
        }

        // Final return for 'dev' and 'test' envs
        return environment as AppPlatform;
    }

    // Checking for conditions in which sending the error to Sentry should be skipped
    private shouldIgnoreSentryError(event: Sentry.ErrorEvent): boolean {
        const { customIgnoreErrorsCallback } = this.config;

        // Ignoring all ENVs except production in non-testing mode
        if (this.platform !== AppPlatform.PROD && !this._testingModeEnabled) {
            return true;
        }

        // Ignoring some network errors corresponding to the `FILTERED_NETWORK_ERRORS` above
        if (isFilteredNetworkError(event)) {
            return true;
        }

        // Ignoring some errors that happens on the Onetrust script side
        if (isOnetrustCookieError(event)) {
            return true;
        }

        // Ignoring some Dev local errors in testing mode, i.e., local React DevTools error messages
        if (this._testingModeEnabled && isReactDevToolsWarning(event)) {
            return true;
        }

        // Additionally ignoring specific errors if custom callback was provided for particular Sentry Service instance
        if (customIgnoreErrorsCallback) {
            return customIgnoreErrorsCallback(event);
        }

        return false;
    }

    public run(): void {
        if (this._initialized) {
            console.log('Sentry already initialized');
            return;
        }

        const {
            dsn,
            metadata,
            ignoreErrors: customIgnoreErrors = [],
            customConsoleLevels = [],
            customNetworkCodes = [],
        } = this.config;

        if (
            !this.config.dsn &&
            (this.platform === AppPlatform.PROD || this._testingModeEnabled)
        ) {
            console.error('Sentry service requires a valid DSN');
            return;
        }

        if (!this.version) {
            console.error(
                'Cannot init Sentry service without valid app version'
            );
            return;
        }

        Sentry.init({
            dsn,
            environment: this.platform,
            release: this.version,
            // Enable traces feature with low sample rate, only for PROD env
            tracesSampleRate: this.platform === AppPlatform.PROD ? 0.01 : 0,
            integrations: [
                // Sentry will capture all console.errors by default
                Sentry.captureConsoleIntegration({
                    levels: ['error', ...customConsoleLevels],
                }),
                // Sentry will capture 301, and all 4xx and 5xx network errors by default
                Sentry.httpClientIntegration({
                    failedRequestStatusCodes: [
                        301,
                        [400, 499],
                        [500, 599],
                        ...customNetworkCodes,
                    ],
                }),
                // TODO: Add this if would be necessary
                //  new ApiRateLimitIntegration(),
            ],
            // Ignoring some errors from the client side using array with messages texts
            ignoreErrors: [...ignoreErrors, ...customIgnoreErrors],
            beforeSend: (event) => {
                if (this.shouldIgnoreSentryError(event)) {
                    return null;
                }

                this.config.onErrorSending?.(event);

                return event;
            },
            // Ensuring that events form local or stage envs won't be sent to Sentry
            // (skipping this filter in the testing mode)
            denyUrls: this._testingModeEnabled ? [] : RESTRICTED_URLS,
        });

        if (metadata) {
            Sentry.setTags(metadata);
        }

        // Setting User info in Sentry
        Sentry.setUser(getSanitizedUser());

        this._initialized = true;

        // Error emitter for DEV testing purposes
        if (
            this._testingModeEnabled &&
            typeof window !== 'undefined' &&
            this.platform !== AppPlatform.PROD
        ) {
            console.info(
                'Sentry TESTING MODE with error emitter for tests is ON!' +
                    '\nYou can use `window.emitTestSentryError("errorText")` command in console to log custom error in Sentry.' +
                    '\nPlease DO NOT FORGET to switch testing mode OFF with `localStorage.removeItem("isSentryTestingModeEnabled")` command with page refresh after testing!'
            );
            window.emitTestSentryError = (errorText: string) => {
                this.captureException(new Error(errorText), {});
                console.log(
                    'Test Sentry error sent successfully with message:',
                    errorText
                );
            };
        }

        if (this._testingModeEnabled) {
            console.log(
                'Sentry service was initialised successfully with config: ',
                this.config,
                this.version,
                this.platform
            );
        }
    }

    /**
     * Optional but exposed method to make Sentry Service log custom exception
     * As a rule, it should not be used in the project
     * @public
     */
    public captureException(error: Error, context?: Extras): void {
        if (this._initialized) {
            Sentry.captureException(error, { extra: context });
        } else {
            console.error('Sentry is not initialized');
        }
    }

    /**
     * Optional but exposed method to make Sentry Service log custom message
     * As a rule, it should not be used in the project
     * @public
     */
    public captureMessage(message: string, context?: Extras): void {
        if (this._initialized) {
            Sentry.captureMessage(message, { extra: context });
        } else {
            console.error('Sentry is not initialized');
        }
    }

    /**
     * Exposed method to make Sentry Service update User info form Session Storage
     * It should be used only in EAC component after successful login event
     * @public
     */
    public updateUser<U>(user?: U): void {
        if (this._initialized) {
            // Updating User info in Sentry
            if (user) {
                // If user is passed from outside, use it
                Sentry.setUser(getSanitizedUser(user));
            } else {
                // Otherwise get it from Session
                Sentry.setUser(getSanitizedUser());
            }
        } else {
            console.error('Sentry is not initialized');
        }
    }

    /**
     * Exposed method to update Sentry Metadata, i.e. after navigation to other App component
     * @param meta Sentry metadata object with optional keys
     * @public
     */
    public setMetadata(meta: SentryMeta & T): void {
        if (this._initialized) {
            Sentry.setTags(meta);
        } else {
            console.error('Sentry is not initialized');
        }
    }
}

let instance: SentryService | null = null;

// Need only for tests
export const resetSentryInstance = () => {
    instance = null;
};

export const initSentryService = (config: SentryConfig) => {
    if (!instance) {
        instance = new SentryService(config);
        instance.run();
    } else {
        console.log('Sentry already initialized');
    }
};

export const updateSentryUser = <U>(user?: U) => {
    if (instance) {
        instance.updateUser<U>(user);
    } else {
        console.error('Sentry is not initialized');
    }
};

export default initSentryService;
