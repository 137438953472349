import defaultFlags from './default.config';
import { FFS_RULES } from './rules';
import type { FFS_RULES_KEYS } from './rules';
import type {
    IDiscoveryParams,
    IDiscoveryResponse,
    IFeatureFlagParams,
    IFeatureFlagResponse,
    IFullParams,
} from './type';
import { StorageService, RCX_CLIENT_ID } from '../auth/index';

const FFS_DOMAIN_EXTERNAL = 'FFS_DOMAIN_EXTERNAL';
const FFS_DOMAIN_INITIAL = 'FFS_DOMAIN_INITIAL';
export class FfsService {
    static singleton: FfsService | undefined = undefined;

    private rcPlatform: string = '';
    private userDetail: Record<string, any> | undefined;
    private ffsDomainKey: string = '';
    private fallbackFfsResponse: IFeatureFlagResponse = { flags: defaultFlags };

    ffsDomain: string = '';

    constructor() {
        const userDetail = StorageService.getUserDetails();
        this.userDetail = userDetail;
        this.rcPlatform = userDetail?.rcPlatform;
    }

    static instance() {
        if (!FfsService.singleton) {
            FfsService.singleton = new FfsService();
        }
        return FfsService.singleton;
    }

    private setFfsDomainKey(isExternal: boolean = false) {
        this.ffsDomainKey = isExternal
            ? FFS_DOMAIN_EXTERNAL
            : FFS_DOMAIN_INITIAL;
    }

    queryDiscoveryApi(
        discoveryParams: IDiscoveryParams = {}
    ): Promise<IDiscoveryResponse> {
        const { isExternal, body = {}, ...otherParams } = discoveryParams;
        this.setFfsDomainKey(isExternal);

        const queryParams = {
            clientId: RCX_CLIENT_ID,
            ...body,
        };
        const searchParams = new URLSearchParams(queryParams).toString();

        return new Promise((resolve, reject) => {
            window
                .fetch(
                    `${this.rcPlatform}/.well-known/entry-points/${
                        isExternal ? 'external' : 'initial'
                    }?${searchParams}`,
                    {
                        ...otherParams,
                    }
                )
                .then((response: any) => {
                    if (response?.ok) {
                        response.json().then((result: IDiscoveryResponse) => {
                            this.ffsDomain = result.featureFlagsApi.baseUri;
                            resolve(result);
                        });
                    } else {
                        throw new Error('Failed to fetch discovery api.');
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    queryFeatureFlagApi(
        ffsParams: IFeatureFlagParams
    ): Promise<IFeatureFlagResponse> {
        return new Promise((resolve) => {
            if (!this.ffsDomain) {
                console.error(
                    'The ffsDomain is not set, please check discovery api. Return default flags.'
                );
                resolve(this.fallbackFfsResponse);
                return;
            }
            const {
                headers = {},
                body,
                rules = [],
                ...otherParams
            } = ffsParams;

            const extraRuleParams = rules.reduce(
                (acc: any, rule: FFS_RULES_KEYS) => {
                    if (typeof FFS_RULES[rule] === 'string') {
                        // @ts-ignore
                        acc[FFS_RULES[rule]] =
                            this.userDetail?.[FFS_RULES[rule] as string];
                    } else {
                        // @ts-ignore
                        acc[FFS_RULES[rule].name] = FFS_RULES[rule].value({
                            userDetail: this.userDetail,
                            ...otherParams,
                        });
                    }
                    return acc;
                },
                {}
            );

            window
                .fetch(`${this.ffsDomain}/feature-flags/client/v1/fetch`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        ...headers,
                    },
                    body: JSON.stringify({
                        ...body,
                        parameters: {
                            ...(body.parameters || {}),
                            ...extraRuleParams,
                        },
                    }),
                    ...otherParams,
                })
                .then((response) => {
                    if (response?.ok) {
                        response
                            .json()
                            .then((result: IFeatureFlagResponse) => {
                                resolve(result);
                            })
                            .catch((err) => {
                                throw new Error(err);
                            });
                    } else {
                        throw new Error('Failed to fetch feature flags.');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    resolve(this.fallbackFfsResponse);
                });
        });
    }

    async getFeatureFlag(
        fullParams: IFullParams
    ): Promise<IFeatureFlagResponse> {
        const storedDomain = this.getStoredFeatureFlagDomain();
        if (!fullParams?.storeFfsDomain || !storedDomain) {
            try {
                await this.queryDiscoveryApi(fullParams?.discoveryParams);
            } catch (err) {
                console.error(err);
                return this.fallbackFfsResponse;
            }
        }
        if (fullParams?.storeFfsDomain) {
            this.storeFeatureFlagDomain(this.ffsDomain);
            !this.ffsDomain && (this.ffsDomain = storedDomain);
        }

        return this.queryFeatureFlagApi(fullParams?.ffsParams);
    }

    storeFeatureFlagDomain(val: string) {
        window.localStorage.setItem(this.ffsDomainKey, val);
    }
    getStoredFeatureFlagDomain() {
        const _domain = window.localStorage.getItem(this.ffsDomainKey) || '';
        return _domain;
    }
    clearStoredFeatureFlagDomain() {
        window.localStorage.removeItem(this.ffsDomainKey);
    }

    // storeFeatureFlags ()

    // clearStoreFeatureFlag()

    // startPollingFFS ()

    // clearPollingFFS()
}
