import { TextField, Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledSharedDashboardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const StyledSharedDashboardFormField = styled(TextField)`
    width: 500px;
`;

export const StyledSharedDashboardButton = styled(Button)`
    margin-left: 10px;
`;
