import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import {
    Button as EuiButton,
    EyeOpened as EuiEyeOpened,
    EyeClosed as EuiEyeClosed,
} from '@ringcx/ui';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { device } from '../../constants/breakpoints';

export const Card = styled(MuiCard)`
    margin: 60px auto;
    max-width: 350px;
    border: 0 solid ${({ theme }) => theme.colors.gray[200]};
    text-align: left;
    padding: 0;
    @media ${device.crm} {
        margin: 50px auto;
        max-width: calc(100% - 24px);
    }
`;
export const CardContent = styled(MuiCardContent)`
    padding: 0;
    margin: 0;
`;
export const CardHeader = styled.h2`
    color: ${({ theme }) => theme.colors.gray[900]};
    text-align: center;
    margin-bottom: 5px;

    * {
        vertical-align: middle;
    }
`;

export const Label = styled.label`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-weight: bold;
    font-size: 13px;
`;

export const Input = styled.input`
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    display: block;
    width: 100%;
    border-radius: 4px;
    height: 40px;
    font-size: inherit;
    padding: 6px 8px;
    box-sizing: border-box;
    margin: 10px 0 10px 0;
    padding-right: ${({ name }) => (name === 'password' ? '32px' : '8px')};

    &:hover {
        border-color: ${({ theme }) => theme.colors.gray[700]};
    }

    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.primary};
    }

    transition: border-color 200ms;
`;

export const Button = styled(EuiButton)`
    && {
        font-size: inherit;
        margin-top: 10px;
        font-weight: 400;
        border-radius: 4px;
        height: 40px;
    }
`;

export const ForgotPasswordLinkContainer = styled.div`
    text-align: center;
    margin: 5px 0 0 0;
    font-weight: normal;
`;

export const TextMessages = styled.div`
    color: ${({ theme }) => theme.colors.gray[900]};
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
    white-space: pre-line;
`;

export const ForgotPasswordLink = styled(Link)`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    margin-top: 0;
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    position: relative;
`;
const eyeCss = css`
    margin-right: 5px;
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: 38%;
    right: 1%;
`;
export const EyeClosed = styled(EuiEyeClosed)`
    ${eyeCss}
`;
export const EyeOpened = styled(EuiEyeOpened)`
    ${eyeCss};
`;
