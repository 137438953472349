import type { SvgIconProps } from '@material-ui/core/SvgIcon';

const SvgComponent = ({ ...props }: SvgIconProps) => {
    return (
        <svg viewBox='0 0 34 34' {...props}>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g
                    transform='translate(-1381.000000, -15.000000)'
                    fillRule='nonzero'
                >
                    <g transform='translate(1381.000000, 15.000000)'>
                        <path
                            d='M17,6.58064516 C20.3314516,6.58064516 23.0322581,9.28145161 23.0322581,12.6129032 C23.0322581,15.9443548 20.3314516,18.6451613 17,18.6451613 C13.6685484,18.6451613 10.9677419,15.9443548 10.9677419,12.6129032 C10.9677419,9.28145161 13.6685484,6.58064516 17,6.58064516 Z'
                            fill='#FFFFFF'
                        />
                        <path
                            d='M17,30.1612903 C12.9762097,30.1612903 9.37056452,28.3379032 6.95766129,25.4862903 C8.24637097,23.0596774 10.7689516,21.3870968 13.7096774,21.3870968 C13.8741935,21.3870968 14.0387097,21.4145161 14.196371,21.4625 C15.0875,21.7504032 16.0197581,21.9354839 17,21.9354839 C17.9802419,21.9354839 18.9193548,21.7504032 19.803629,21.4625 C19.9612903,21.4145161 20.1258065,21.3870968 20.2903226,21.3870968 C23.2310484,21.3870968 25.753629,23.0596774 27.0423387,25.4862903 C24.6294355,28.3379032 21.0237903,30.1612903 17,30.1612903 Z'
                            fill='#FFFFFF'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SvgComponent;
