import type { FC } from 'react';
import {
    useCallback,
    useDeferredValue,
    useEffect,
    useState,
    useMemo,
} from 'react';

import { UserService } from '@ringcx/shared';

import Tooltip from './../../components/Tooltip';
import { getPermissions } from './AppSwitcher.service';
import {
    StyledIconButton,
    StyledPopper,
    AppSwitcherWrapper,
} from './AppSwitcher.styled';
import { AppSwitcherBlank } from './components/AppSwitcherBlank/AppSwitcherBlank';
import { List } from './components/List';
import type { ListGroupType } from './components/ListGroup';
import type { ListItemType } from './components/ListItem';
import { AppSwitcherIcons, defaultLabels } from './constants';
import { getPermittedItems } from './helpers';
import type { AppSwitcherProps } from './types';
import { TEST_AID } from '../../constants';
import { AppSwitcherMenu } from '../../icons';

// We will show placeholder only if pass loading attribute as true
// Its help correct work in case when app support pre-loaders and when its work without them
const AppSwitcher: FC<AppSwitcherProps> = ({
    loading,
    rcAccountId,
    labels = defaultLabels,
    onTrackAnalytics,
    isAgent,
    accessibilityLabels,
}) => {
    const [isPermissionChecking, setPermissionChecking] = useState(loading);
    const [isOpenMenu, setOpenMenu] = useState(false);
    const [appGroups, setAppGroups] = useState<ListGroupType[] | null>(null);

    const toggleMenu = useCallback(() => {
        setOpenMenu((oldIsOpenMenu) => !oldIsOpenMenu);
        onTrackAnalytics && onTrackAnalytics('RCX_app_picker_clicked');
    }, [onTrackAnalytics]);

    const isLoading = useDeferredValue(loading || isPermissionChecking);

    const areGroupsLoaded = !!appGroups;

    useEffect(() => {
        if (!isOpenMenu && areGroupsLoaded) {
            return;
        }

        (async () => {
            const [permissions, routes] = await getPermissions(isAgent);
            const engageItems: ListItemType[] = getPermittedItems([
                {
                    name: labels.AGENT,
                    path: routes.Agent,
                    icon: AppSwitcherIcons.AGENT,
                    hasPermission: permissions.Agent,
                    openInNewTab: UserService.isVodafoneBrand(),
                },
                {
                    name: labels.ANALYTICS,
                    path: routes.Analytics,
                    icon: AppSwitcherIcons.ANALYTICS,
                    hasPermission: permissions.Analytics,
                },
                {
                    name: labels.ADMIN,
                    path: routes.Admin,
                    icon: AppSwitcherIcons.ADMIN,
                    hasPermission: permissions.Admin,
                },
                {
                    name: labels.WEM,
                    path: routes.WEMEnterprise,
                    icon: AppSwitcherIcons.WEM_ENTERPRISE,
                    hasPermission: permissions.WEMEnterprise,
                    openInNewTab: true,
                },
                {
                    name: labels.AI,
                    path: routes.RingSense,
                    icon: AppSwitcherIcons.RING_SENSE,
                    hasPermission: permissions.RingSense,
                    openInNewTab: true,
                },
            ]);
            const engageGroup: ListGroupType = {
                items: engageItems,
            };

            setPermissionChecking(false);
            setAppGroups([engageGroup]);
        })();
    }, [rcAccountId, labels, areGroupsLoaded, isOpenMenu, isAgent]);

    const renderMenu = useMemo(() => {
        return (
            <StyledIconButton isOpenMenu={isOpenMenu}>
                <AppSwitcherMenu />
            </StyledIconButton>
        );
    }, [isOpenMenu]);

    if (!appGroups || appGroups.flatMap((group) => group.items).length < 2) {
        return null;
    }

    return (
        <AppSwitcherWrapper data-aid={TEST_AID.APP_SWITCHER_TOGGLE}>
            {isLoading ? (
                <AppSwitcherBlank />
            ) : (
                <StyledPopper
                    toggleComponent={
                        accessibilityLabels?.appSwitcher ? (
                            <Tooltip
                                title={
                                    isOpenMenu
                                        ? accessibilityLabels.appSwitcher.close
                                        : accessibilityLabels.appSwitcher.open
                                }
                            >
                                {renderMenu}
                            </Tooltip>
                        ) : (
                            renderMenu
                        )
                    }
                    isOpen={isOpenMenu}
                    onOpen={toggleMenu}
                    onClose={toggleMenu}
                    data-aid={TEST_AID.APP_SWITCHER}
                >
                    <List
                        items={appGroups}
                        onTrackAnalytics={onTrackAnalytics}
                    />
                </StyledPopper>
            )}
        </AppSwitcherWrapper>
    );
};

export default AppSwitcher;
