import { Session } from '@ringcx/shared';
import type { AxiosRequestConfig } from 'axios';

const AuthInterceptor = (config: AxiosRequestConfig) => {
    config.headers = config.headers || {};
    Object.assign(config.headers, Session.getAuthHeader());
    return config;
};

export default AuthInterceptor;
