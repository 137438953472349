import { DEFAULT_TOKEN_TYPE } from './constants';
import type { EdrInfo } from './types';
import { EdrChannelId } from './types';
import { Session, StorageService } from '../auth';

export class EdrClient {
    public readonly userAgent: string = window.navigator.userAgent;
    public readonly xUserAgent: string = '';

    private readonly channelIds: EdrChannelId[] = [EdrChannelId.problemReports];

    private edrInfo: Nullable<EdrInfo> = null;
    private tokenExpiresAt?: number;

    public constructor(
        private readonly rcPlatform: string,
        private readonly tokenType: string = DEFAULT_TOKEN_TYPE
    ) {}

    /**
     * Checks that we have EDR info, including valid token and push interval for channels.
     * If we don't have EDR info, then function attempts to retrieve it.
     */
    public async getEdrInfo(): Promise<EdrInfo> {
        if (!(await this.getRCToken())) {
            throw new Error('Fail to get RC Token, please check login status');
        }

        // Check token for sending.
        if (!this.hasValidToken || !this.edrInfo) {
            await this.loadEdrInfo().catch(() => void 0);
            // Token still invalid.
            if (!this.hasValidToken || !this.edrInfo) {
                throw new Error('Fail to get EDR info');
            }
        }

        return this.edrInfo;
    }

    private async getRCToken(isForceRefresh = false) {
        const rcAccessToken = StorageService.getRCAccessToken();
        if (!rcAccessToken || isForceRefresh) {
            return Session.refreshRCToken();
        }
        return rcAccessToken;
    }

    private get hasValidToken() {
        // 3 minutes before it expires.
        return (
            this.tokenExpiresAt &&
            new Date().getTime() < this.tokenExpiresAt - 3 * 60 * 1000
        );
    }

    private async loadEdrInfo(): Promise<void> {
        const channelIds = this.channelIds
            .map((cd) => `channelId=${cd}`)
            .join('&');

        const accessToken = await this.getRCToken();

        const resp = await fetch(
            `${this.rcPlatform}/restapi/v1.0/client-info/edr-info?${channelIds}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `${this.tokenType} ${accessToken}`,
                },
            }
        );

        if (resp.status === 401) {
            const refreshToken = await this.getRCToken(true);
            if (!refreshToken) {
                throw new Error('Fail to get refreshToken');
            }

            await this.loadEdrInfo();

            return;
        }

        this.edrInfo = await resp.json();

        if (this.edrInfo?.tokenExpiresIn) {
            this.tokenExpiresAt =
                new Date().getTime() + this.edrInfo.tokenExpiresIn * 1000;
        }
    }
}
