import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { Navigation } from '@ringcx/shared';
import { TextEclipse } from '@ringcx/ui';
import { useTranslation } from 'react-i18next';

import { AgentTypes } from './constant';
import type { RowType } from './types';
import { AnalyticsSvc } from '../../../../services/analytics';
import { TableRow, TableCell } from '../../AgentPicker.styled';

const Row: FC<RowType> = ({
    agentId,
    agentType,
    accountName,
    agentGroupName,
    accountId,
}) => {
    const { t } = useTranslation();
    const onSelectAgent = async () => {
        AnalyticsSvc.track('RCX_agent_multipleAccountListAction');
        const href = await Navigation.getAgentUrl(agentId);
        Navigation.setWEMUrl?.(accountId);
        window.location.assign(href);
    };

    const getAgentRoleName = useCallback(
        (agentType: string) => {
            switch (agentType) {
                case AgentTypes.AGENT:
                case AgentTypes.MULTI_USER:
                    return t('ROLES.AGENT');
                case AgentTypes.SUPERVISOR:
                case AgentTypes.MULTI_SUPERVISOR:
                    return t('ROLES.SUPERVISOR');
            }
        },
        [t]
    );

    return (
        <TableRow onClick={onSelectAgent} data-aid='row'>
            <TableCell>{accountName}</TableCell>
            <TableCell>
                <TextEclipse tooltipMsg={agentGroupName}>
                    {agentGroupName}
                </TextEclipse>
            </TableCell>
            <TableCell>{getAgentRoleName(agentType)}</TableCell>
        </TableRow>
    );
};

export default memo(Row);
