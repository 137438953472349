import type { FC, ReactElement } from 'react';
import { useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
    Title,
    Information,
    Container,
    AppPickerWrapper,
} from './AppPicker.styled';
import {
    NeedHelp,
    AgentAppButton,
    AnalyticsAppButton,
    AdminAppButton,
} from './components';
import { RingSenseButton } from './components/RingSenseButton';
import { WEMEnterpriseAppButton } from './components/WEMEnterpriseAppButton';
import { AppContext } from '../../contexts';
import PageLayout from '../../layouts/PageLayout';
import { AnalyticsSvc } from '../../services/analytics';

const AppPicker: FC = () => {
    const { t } = useTranslation();
    const {
        permissions,
        adminUrl,
        agentUrl,
        analyticsUrl,
        stateManagement,
        wemUrl,
        ringSenseUrl,
    } = useContext(AppContext);

    useEffect(() => {
        AnalyticsSvc.track('RCX_Viewed_App_Section');
    }, []);

    const tiles = useMemo(() => {
        const tiles: ReactElement[] = [];

        permissions.Agent &&
            tiles.push(
                <AgentAppButton
                    key={agentUrl}
                    {...{ agentUrl, stateManagement }}
                />
            );

        permissions.Analytics &&
            tiles.push(
                <AnalyticsAppButton
                    key={analyticsUrl}
                    {...{ analyticsUrl, stateManagement }}
                />
            );

        permissions.Admin &&
            tiles.push(
                <AdminAppButton
                    key={adminUrl}
                    {...{
                        adminUrl,
                        stateManagement,
                    }}
                />
            );

        permissions.WEMEnterprise &&
            tiles.push(
                <WEMEnterpriseAppButton
                    key={wemUrl}
                    {...{
                        wemUrl,
                        stateManagement,
                    }}
                />
            );

        permissions.RingSense &&
            tiles.push(
                <RingSenseButton
                    key={ringSenseUrl}
                    {...{
                        ringSenseUrl,
                        stateManagement,
                    }}
                />
            );

        return tiles;
    }, [
        wemUrl,
        adminUrl,
        agentUrl,
        permissions,
        analyticsUrl,
        ringSenseUrl,
        stateManagement,
    ]);

    return (
        <PageLayout>
            <AppPickerWrapper>
                <Title>{t('GENERICS.WELCOME', { sign: '!' })}</Title>
                <Information>{t('ROOT.INFO')}</Information>
                <Container tilesNumber={tiles.length}>{tiles}</Container>
                <NeedHelp />
            </AppPickerWrapper>
        </PageLayout>
    );
};

export default AppPicker;
