import { getLocaleOverride, Languages } from '@ringcx/shared';

const USERLOCALE = 'userLocale';

export const getUserLocale = (regionalSettingLang?: Nullable<string>) => {
    return (
        getLocaleOverride() ||
        regionalSettingLang ||
        localStorage.getItem(USERLOCALE) ||
        isBrowserLanguageSupported(window.navigator?.language)
    );
};

const isBrowserLanguageSupported = (lang: string): string => {
    const supportedLangsArray: string[] = [
        Languages.EN_US,
        Languages.EN_GB,
        Languages.IT_IT,
        Languages.FR_FR,
        Languages.DE_DE,
    ];
    return supportedLangsArray.includes(lang) ? lang : Languages.EN_US;
};

export const setLandingPageLocale = (language: string) => {
    localStorage.setItem(USERLOCALE, language);
};
