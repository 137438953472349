import type {
    IAgent,
    IRcUser,
    IRegionalSettings,
    AppPermissions,
} from '@ringcx/shared';

// TODO soon it must be substituted with IAgent when a field agentGroupName will be coming within IAgent
export interface IAgentDetails extends IAgent {
    agentGroupName: string;
}

export enum AuthStates {
    UNKNOWN = 'unknown',
    REFRESHING_AUTHENTICATION = 'refreshing_authentication',
    AUTHENTICATED = 'authenticated', // indicates that our last authentication attempt went successfully, and that we currently have a valid session
    NOT_AUTHENTICATED = 'not_authenticated', // indicates that we don't currently have a valid session
}

export type AppContextValue = Readonly<{
    authState: AuthStates;
    permissions: AppPermissions;
    agentDetails: IAgentDetails[];
    sessionType: string | null;
    ssoLogin: boolean;
    adminUrl: string;
    analyticsUrl: string;
    agentUrl: string;
    wemUrl: string;
    ringSenseUrl: string;
    refreshAuthentication: () => void;
    rcUser: IRcUser | null;
    regionalSettings: IRegionalSettings;
    mainAccountId: string;
    redirectUrl: string;
    stateManagement: boolean;
}>;
