export type AppButtonType = {
    name: string;
    href: string;
    stateManagement?: boolean;
    openInNewTab?: boolean;
};

export enum BGTypes {
    BLUE = 'blue',
    GREEN = 'green',
}
