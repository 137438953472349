import type { SentryFilteredNetworkError } from './SentryService';

export enum AppPlatform {
    PROD = 'production',
    DEV = 'development',
    TEST = 'test',
    STAGE = 'stage',
}

// Defaults to 'Dev' in order to prevent false-positives
export const DEFAULT_ENVIRONMENT = AppPlatform.DEV;

// List of URLs to be filtered out before sending to Sentry
// Could be substring or Regexp
// TODO: Adjust as necessary
export const RESTRICTED_URLS: Array<string | RegExp> = [
    // Skip local envs
    'localhost',
    '127.0.0.1',
    // Skip LAB and OPS environments, only PROD will be sent to Sentry
    '.lab.',
    '.ops.',
    /fra\d{2}/,
    /aws\d{2}/,
];

// Array of network errors meta that should be filtered from Sentry
export const FILTERED_NETWORK_ERRORS: SentryFilteredNetworkError[] = [
    // stale Auth token error
    { errorCode: 400, method: 'POST', urlPart: 'api/auth/token/refresh' },
    // logout action
    { errorCode: 404, method: 'DELETE', urlPart: 'api/auth/logout' },
    // unauthorized `service-info` requests
    { errorCode: 401, urlPart: '/service-info' },
    // failing manifest.json requests
    { errorCode: 401, urlPart: 'static/manifest.json' },
    { errorCode: 403, urlPart: 'static/manifest.json' },
    { errorCode: 404, urlPart: 'static/manifest.json' },
];

export const ONETRUST_COOKIE_ERROR_MESSAGES = [
    `Cannot read properties of undefined (reading 'isValid')`,
    't is undefined',
];
