import { useState, useEffect, useCallback } from 'react';

import { Session, StorageService } from '@ringcx/shared';
import { useTranslation } from 'react-i18next';

import { fetchRCAccessToken, fetchRCXAccessToken } from './helpers';
import {
    StyledSharedDashboardWrapper,
    StyledSharedDashboardFormField,
    StyledSharedDashboardButton,
} from './Shared.styled';
import PageLayout from '../../layouts/PageLayout';

export default function Shared() {
    const { t } = useTranslation();
    const [token, setToken] = useState('');

    const isDisableSave = !token;

    const handleTokenSave = useCallback(async (t: string) => {
        const params = new URL(window.location.href).searchParams;
        const dashboardId = params.get('dashboardId');
        const userId = params.get('userId');
        const accountId = params.get('accountId');
        try {
            const res = await auth(t);
            if (res) {
                await Session.refreshToken();
                StorageService.setSharedDashboardToken(t);
                window.location.href = `/analytics/voice/shared?dashboardId=${dashboardId}&userId=${userId}&accountId=${accountId}`;
            }
        } catch (error) {
            window.location.href = `/shared?dashboardId=${dashboardId}&userId=${userId}&accountId=${accountId}`;
            console.error('Auth failed - ', error);
        }
    }, []);

    async function auth(token: string) {
        const rcTokenResponse = await fetchRCAccessToken(token);
        if (!rcTokenResponse) return false;

        const { access_token, token_type } = rcTokenResponse;
        const rcxTokenResponse = await fetchRCXAccessToken(
            access_token,
            token_type
        );

        if (!rcxTokenResponse) return false;

        const { rcxRefreshToken } = rcxTokenResponse;
        StorageService.setRefreshToken(rcxRefreshToken);

        return true;
    }

    useEffect(() => {
        const tokenFromStorage = StorageService.getSharedDashboardToken();
        if (tokenFromStorage) {
            handleTokenSave(tokenFromStorage);
        }
    }, [handleTokenSave]);

    return (
        <PageLayout>
            <StyledSharedDashboardWrapper>
                <StyledSharedDashboardFormField
                    label={t('SHARED.INPUT_PLACEHOLDER')}
                    variant='outlined'
                    size='small'
                    onChange={(e) => setToken(e.target.value)}
                />
                <StyledSharedDashboardButton
                    variant='outlined'
                    color='primary'
                    disabled={isDisableSave}
                    onClick={() => handleTokenSave(token)}
                >
                    {t('SHARED.BUTTON')}
                </StyledSharedDashboardButton>
            </StyledSharedDashboardWrapper>
        </PageLayout>
    );
}
