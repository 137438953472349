// eslint-disable-next-line no-restricted-imports
import { t } from '../services/translate';

const engageAuthJsErrorParser = (e: { response: string | never }): string => {
    let errorMessage = t('ERRORS.BASE');

    try {
        if (!e || typeof e.response !== 'string') {
            throw new Error(t('ERRORS.UNEXPECTED'));
        }

        const deserializedResp = JSON.parse(e.response);
        errorMessage = deserializedResp.detail || deserializedResp.message;
    } catch (parseError) {
        window.console.warn(
            `errorMessageParser: couldn't parse error "${e}"! Cause: ${parseError}`
        );
    }

    return errorMessage;
};

export default engageAuthJsErrorParser;
