import type { FC } from 'react';

import type { IAppIcon } from './types/AppIcon';

export const AppSwitcherRingSense: FC<IAppIcon> = () => {
    const defId = 'eui-AppSwitcherRingSense-' + Date.now() + Math.random();

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='28'
            height='28'
            viewBox='0 0 54 54'
        >
            <defs>
                <linearGradient id={defId} x1='0%' y1='0%' x2='100%' y2='100%'>
                    <stop stopColor='#066FAC' offset='0%' />
                    <stop stopColor='#509AC4' offset='100%' />
                </linearGradient>
            </defs>
            <path
                fill={`url(#${defId})`}
                fillRule='evenodd'
                d='M44.938 18.75c2.108 0 4.157.25 6.121.721l.964.26c.633.176 1.025.792.944 1.426l-.056.243-1.542 4.643a1.307 1.307 0 0 1-1.588.85l-.662-.172-.108-.025a18.73 18.73 0 0 0-4.073-.446c-10.33 0-18.706 8.395-18.706 18.75 0 3.847 1.156 7.423 3.138 10.4l-1.948 7.513 7.704-1.947a18.586 18.586 0 0 0 9.812 2.784l.43-.011.581-.035.348-.025a1.31 1.31 0 0 1 1.401 1.134l.643 4.835a1.311 1.311 0 0 1-1.13 1.47l-.725.056-1.327.07-.221.006a26.08 26.08 0 0 1-10.19-2.063l-.564-.249-7.894 1.978a5.625 5.625 0 0 1-6.826-6.814l1.905-7.657-.11-.224a26.212 26.212 0 0 1-2.497-10.435L18.75 45c0-14.497 11.725-26.25 26.188-26.25ZM56.285 52.5c1.034 0 1.871.84 1.871 1.875v8.002a3.75 3.75 0 0 1 1.87 3.248 3.746 3.746 0 0 1-3.74 3.75 3.746 3.746 0 0 1-3.742-3.75 3.75 3.75 0 0 1 1.869-3.247l.002-6.128-15.9.002-.168-.015a.937.937 0 0 1-.767-.923V53.44c0-.517.419-.937.935-.937l17.77-.002ZM67.51 41.262a3.746 3.746 0 0 1 3.741 3.75 3.746 3.746 0 0 1-3.741 3.75 3.738 3.738 0 0 1-3.254-1.897l-29.481-.001a.937.937 0 0 1-.935-.938v-1.875c0-.46.33-.843.767-.922l.168-.015h29.508a3.738 3.738 0 0 1 3.227-1.852ZM60.027 22.5a3.746 3.746 0 0 1 3.74 3.75 3.75 3.75 0 0 1-1.87 3.248v6.127a1.873 1.873 0 0 1-1.87 1.875l-23.383-.002a.937.937 0 0 1-.935-.937v-1.875c0-.46.33-.843.767-.923l.168-.015 21.512.002-.002-4.253a3.75 3.75 0 0 1-1.869-3.247 3.746 3.746 0 0 1 3.742-3.75Z'
                transform='translate(-18 -18)'
            />
        </svg>
    );
};
