import { LinkButton } from '@ringcx/ui';
import styled, { css } from 'styled-components';

import { ReactComponent as leftImg } from './assets/leftImage.svg';
import { RoundButton } from '../../components/AlternativeSignIn/AlternativeSignIn.styled';
import { device } from '../../constants/breakpoints';

export const commonFlexProp = css`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
`;

export const LandingPageBody = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;
export const LandingPageContent = styled.div`
    display: flex;
    flex: auto;
    max-width: 986px;
    margin: 0 auto;
`;
export const LandingPageImgContainer = styled.div`
    ${commonFlexProp};

    flex: 1;
    width: 544px;
    justify-content: flex-end;
    height: 100%;

    @media (max-width: 1115px) {
        width: 242px;
    }

    @media (max-width: 799px) {
        display: none;
    }
`;
export const LeftImage = styled(leftImg)``;
export const LandingPageEntrance = styled.div`
    ${commonFlexProp};

    flex-flow: column;
    flex: 1;
    padding-left: 124px;
    align-items: center;

    @media (max-width: 799px) {
        align-items: center;
        padding: 0;
    }
    @media ${device.crm} {
        padding: 0 24px;
    }
`;

export const WelcomeView = styled.div`
    ${commonFlexProp};

    flex-flow: column;
    width: 318px;
    @media ${device.crm} {
        width: 100%;
        margin-top: 70px;
    }
`;
export const WelcomeViewLogo = styled.div`
    margin-bottom: 32px;
    height: 34px;
    width: 245px;

    & > div {
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;

        & > img {
            width: 100%;
            height: 100%;
        }
    }
`;
export const WelcomeHeadLine = styled.h3`
    margin: 0 0 13px;
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.gray[900]};
`;
export const WelcomeBody = styled.div`
    color: ${({ theme }) => theme.colors.gray[900]};
    margin-bottom: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
`;
export const EntranceButtons = styled.div`
    ${commonFlexProp};

    flex-flow: column;
    @media ${device.crm} {
        width: 100%;
    }
`;
export const SignInButton = styled(RoundButton)`
    && {
        width: 318px;
        margin-bottom: 30px;
        padding: 6px 8px;
        line-height: 30px;

        @media ${device.crm} {
            width: 100%;
            margin-bottom: 96px;
        }
    }
`;
export const EntranceFooter = styled(WelcomeBody)`
    color: ${({ theme }) => theme.colors.gray[800]};
    display: flex;
    margin-bottom: 13px;
    margin-top: 3px;
    width: 318px;
    &:before,
    &:after {
        content: '';
        flex: 1 1;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
        margin: auto 5px;
    }
    @media ${device.crm} {
        display: none;
    }
`;
export const ExternalLinkButton = styled(LinkButton)`
    && {
        font-size: 16px;
        @media ${device.crm} {
            display: none;
        }
    }
`;
export const LoadingDialog = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(${({ theme }) => theme.colors.gray[900]}, 0.3);
`;
