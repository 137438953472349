import { createContext } from 'react';

import type { AppContextValue } from './types';
import { AuthStates } from './types';
import { WEM_URI } from '../../constants/links';

export const appContext: AppContextValue = {
    authState: AuthStates.UNKNOWN,
    agentDetails: [],
    sessionType: null,
    ssoLogin: false,
    adminUrl: '',
    analyticsUrl: '',
    agentUrl: '',
    wemUrl: WEM_URI,
    ringSenseUrl: '',
    permissions: {
        Agent: false,
        Admin: false,
        Analytics: false,
        WEMEnterprise: false,
        RingSense: false,
    },
    rcUser: null,
    regionalSettings: {
        homeCountry: 1,
        homeCountryCode: '1',
        timezone: null,
        language: null,
        greetingLanguage: null,
        formattingLocale: null,
        timeFormat: null,
    },
    redirectUrl: '',
    mainAccountId: '',
    refreshAuthentication: () => {
        // empty method
    },
    stateManagement: false,
};

export const AppContext = createContext(appContext);
