import {
    defaultPIIMaskRules,
    piiInterceptor,
    Sanitization,
} from '@ringcx/pii-interceptor';
import type * as Sentry from '@sentry/browser';

import {
    DEFAULT_ENVIRONMENT,
    FILTERED_NETWORK_ERRORS,
    ONETRUST_COOKIE_ERROR_MESSAGES,
} from './constants';
import type { IUserDetails } from '../auth';
import { Session } from '../auth';

const isNetworkErrorCode = (code: number, message = ''): boolean =>
    message.startsWith('HTTP Client Error with status code: ') &&
    message.endsWith(code.toString());

// TODO: Possibly better to use some cookies with short lifetime instead or the FF service if would be necessary for AQA
// Method to determine the Sentry Testing mode status
export const isSentryTestingModeEnabled = () =>
    localStorage?.getItem('isSentryTestingModeEnabled') === 'true';

// Method to get User data cleared from PII
// Note that there would be additional server-side data scrubbing performed on the Sentry side
export const getSanitizedUser = (
    userData?: Partial<IUserDetails>
): Sentry.User => {
    const user = userData ?? Session.getUserDetails();
    // const fullUser = UserService.getFullUserDetails();

    // Additional PII Mask extensions
    const additionalRules = {
        tokenType: Sanitization.maskString,
        refreshToken: Sanitization.maskToken,
        accessToken: Sanitization.maskToken,
        'rcUser.firstName': Sanitization.maskString,
        'rcUser.lastName': Sanitization.maskString,
        'rcUser.email': Sanitization.maskEmail,
        'rcUser.company': Sanitization.maskString,
        'rcUser.jobTitle': Sanitization.maskString,
        'rcUser.businessPhone': Sanitization.maskPhone,
        'rcUser.mobilePhone': Sanitization.maskPhone,
        'rcUser.department': Sanitization.maskString,
        'agentDetails.firstName': Sanitization.maskString,
        'agentDetails.lastName': Sanitization.maskString,
        'agentDetails.email': Sanitization.maskEmail,
        'agentDetails.username': Sanitization.maskEmail,
        'agentDetails.password': Sanitization.maskString,
        // ESU user
        name: Sanitization.maskString,
        surname: Sanitization.maskString,
        fullName: Sanitization.maskString,
        email: Sanitization.maskEmail,
    };

    return piiInterceptor.sanitize(user, {
        ...defaultPIIMaskRules,
        // Extending default PII Mask,
        ...additionalRules,
    });
};

// Method to determine whether network error that should be filtered from Sentry
export const isFilteredNetworkError = (event: Sentry.ErrorEvent) =>
    FILTERED_NETWORK_ERRORS.some(({ errorCode, urlPart, method }) => {
        // Matching any event method if specific value was not provided
        const hasRequestMethodMatch =
            !method || event?.request?.method === method;

        return (
            isNetworkErrorCode(errorCode, event.message) &&
            !!event?.request?.url?.includes(urlPart) &&
            hasRequestMethodMatch
        );
    });

// Method to determine Onetrust cookies errors to except it from Sentry logs
export const isOnetrustCookieError = (event: Sentry.ErrorEvent) =>
    // Match with all URLs in event Breadcrumbs
    event?.breadcrumbs?.some(
        (breadcrumb) =>
            (breadcrumb.data?.['url'] as string)?.includes('onetrust')
    ) &&
    ONETRUST_COOKIE_ERROR_MESSAGES.some(
        (msg) =>
            !!event?.message?.includes(msg) ||
            !!event?.exception?.values?.some((exc) => exc?.value?.includes(msg))
    );

// Method to determine local React DevTools errors in order to except it from Sentry logs
export const isReactDevToolsWarning = (event: Sentry.ErrorEvent) =>
    !!event?.message?.startsWith('Warning: ');

// Method to get current App environment
export const getAppEnvironment = (): string =>
    process?.env?.NODE_ENV ? process.env.NODE_ENV : DEFAULT_ENVIRONMENT;
