import {
    IDS,
    CSS,
    KEYS,
    APPS,
    VALUES,
    MARKERS,
    CLASSES,
    APPS_LIST,
    EMPTY_LOCALE,
    ENGAGE_SUPPORT_LOCALE_LIST,
    FULL_RC_LOCALE_LIST,
    COOKIE_ATTRIBUTES,
} from './constant';
import { UserService } from '../../services/auth';
import type { Languages } from '../../services/init';

let _currentVersion: string = VALUES.BLUE;
let _currentLocale: string = EMPTY_LOCALE;
let _currentFormatLocale: string = EMPTY_LOCALE;

export const addVersionHelperStyle = () => {
    const style = document.createElement('style');
    const head = document.head;

    style.setAttribute('id', IDS.STYLE);
    style.setAttribute('type', 'text/css');

    style.appendChild(document.createTextNode(CSS));

    head.appendChild(style);
};

export const removeElement = (id: string) => {
    const elem = document.getElementById(id);

    return elem?.parentNode?.removeChild(elem);
};

export const removeVersionHelperStyle = () => {
    removeElement(IDS.STYLE);
};

export const addVersionHelperMarkup = () => {
    // create body
    const helperBody = document.createElement('div');
    helperBody.setAttribute('id', IDS.BODY);
    helperBody.setAttribute('class', CLASSES.BODY);
    document.body.appendChild(helperBody);

    const versionBody = document.createElement('div');
    versionBody.setAttribute('id', IDS.VERSIONS);
    versionBody.setAttribute('class', CLASSES.VERSIONS);
    document.body.appendChild(versionBody);
    const tableBody = document.createElement('table');
    versionBody.appendChild(tableBody);

    const headerContainer1 = document.createElement('tr');
    const headerComponent = document.createElement('th');
    headerComponent.setAttribute('rowspan', '2');
    headerComponent.appendChild(document.createTextNode('Component'));
    const headerMode = document.createElement('th');
    headerMode.setAttribute('colspan', '3');
    headerMode.appendChild(document.createTextNode('Mode'));
    tableBody.appendChild(headerContainer1);
    headerContainer1.appendChild(headerComponent);
    headerContainer1.appendChild(headerMode);
    const headerContainer2 = document.createElement('tr');
    const headerBlue = document.createElement('th');
    headerBlue.appendChild(document.createTextNode('Blue'));
    const headerGreen = document.createElement('th');
    headerGreen.appendChild(document.createTextNode('Green'));
    const headerTicket = document.createElement('th');
    headerTicket.appendChild(document.createTextNode('Ticket'));
    tableBody.appendChild(headerContainer2);
    headerContainer2.appendChild(headerBlue);
    headerContainer2.appendChild(headerGreen);
    headerContainer2.appendChild(headerTicket);

    // create apps control row
    APPS_LIST.forEach((app) => {
        const appBody = document.createElement('tr');
        appBody.setAttribute('class', CLASSES.CONTROLS);
        tableBody.appendChild(appBody);

        const appName = document.createElement('td');
        appName.dataset['type'] = app + MARKERS.NAME;
        appName.appendChild(document.createTextNode(app));
        appBody.appendChild(appName);

        const blueContainer = document.createElement('td');
        const blueControl = document.createElement('input');
        blueControl.setAttribute('type', 'checkbox');
        blueControl.dataset['type'] = app + MARKERS.BLUE;
        appBody.appendChild(blueContainer);
        blueContainer.appendChild(blueControl);

        const greenContainer = document.createElement('td');
        const greenControl = document.createElement('input');
        greenControl.setAttribute('type', 'checkbox');
        greenControl.dataset['type'] = app + MARKERS.GREEN;
        appBody.appendChild(greenContainer);
        greenContainer.appendChild(greenControl);

        const ticketContainer = document.createElement('td');
        ticketContainer.setAttribute('class', CLASSES.TICKET);
        const ticketControl = document.createElement('input');
        ticketControl.dataset['type'] = app + MARKERS.TICKET;
        appBody.appendChild(ticketContainer);
        ticketContainer.appendChild(ticketControl);
    });

    const localeContainer = document.createElement('div');
    localeContainer.setAttribute('class', CLASSES.LOCALE_CONTROLS);
    versionBody.appendChild(localeContainer);

    const localeFieldset = document.createElement('fieldset');
    localeContainer.appendChild(localeFieldset);
    const localeLabel = document.createElement('label');
    localeLabel.appendChild(document.createTextNode('Override Locale'));
    localeFieldset.appendChild(localeLabel);
    const localeSelect = document.createElement('select');
    localeSelect.dataset['type'] = MARKERS.LOCALE;
    [EMPTY_LOCALE, ...ENGAGE_SUPPORT_LOCALE_LIST].forEach((option: string) => {
        const localeOption = document.createElement('option');
        localeOption.appendChild(document.createTextNode(option));
        localeOption.value = option;
        localeSelect.appendChild(localeOption);
    });
    localeFieldset.appendChild(localeSelect);

    const formatLocaleFieldset = document.createElement('fieldset');
    localeContainer.appendChild(formatLocaleFieldset);
    const formatLocaleLabel = document.createElement('label');
    formatLocaleLabel.appendChild(
        document.createTextNode('Override Format Locale')
    );
    formatLocaleFieldset.appendChild(formatLocaleLabel);
    const formatLocaleSelect = document.createElement('select');
    formatLocaleSelect.dataset['type'] = MARKERS.FORMAT_LOCALE;
    [EMPTY_LOCALE, ...FULL_RC_LOCALE_LIST].forEach((option: string) => {
        const formatLocaleOption = document.createElement('option');
        formatLocaleOption.appendChild(document.createTextNode(option));
        formatLocaleOption.value = option;
        formatLocaleSelect.appendChild(formatLocaleOption);
    });
    formatLocaleFieldset.appendChild(formatLocaleSelect);

    const edContainer = document.createElement('div');
    edContainer.id = IDS.ED_CONTROLS;
    versionBody.appendChild(edContainer);

    const edLabel = document.createElement('p');
    edLabel.appendChild(document.createTextNode('ED: '));
    const edLink = document.createElement('a');
    edLink.href = '';
    edLink.target = '_blank';
    edLink.id = IDS.ED_URL;
    edLink.appendChild(document.createTextNode(''));
    edLabel.appendChild(edLink);
    edContainer.appendChild(edLabel);

    const bottomContainer = document.createElement('div');
    bottomContainer.setAttribute('class', CLASSES.CONTROLS_BUTTONS);
    versionBody.appendChild(bottomContainer);
    const cancelButton = document.createElement('button');
    cancelButton.appendChild(document.createTextNode('Cancel'));
    cancelButton.dataset['type'] = MARKERS.CANCEL;
    bottomContainer.appendChild(cancelButton);
    const submitButton = document.createElement('button');
    submitButton.appendChild(document.createTextNode('Save'));
    submitButton.dataset['type'] = MARKERS.SUBMIT;
    bottomContainer.appendChild(submitButton);
};

export const toggleVersionControl = () => {
    const edUrl = UserService.getFullUserDetails().digitalAccountEmbedUrl;

    if (edUrl) {
        const edLink = document.getElementById(IDS.ED_URL);
        const edContainer = document.getElementById(IDS.ED_CONTROLS);
        if (edLink && edContainer) {
            edLink.setAttribute('href', edUrl);
            edLink.setAttribute('title', edUrl);
            edLink.textContent = edUrl;
            edContainer.setAttribute('style', 'display: block');
        }
    }

    document
        .getElementById(IDS.VERSIONS)
        ?.classList.toggle(CLASSES.VERSIONS_VISIBLE);
};

export const isCurrentApp = (app: string) => {
    const currentAppPath = location.pathname;

    return APPS[app]?.length > 1
        ? currentAppPath.startsWith(APPS[app])
        : currentAppPath === APPS[app];
};

const regExp = /[a-zA-Z]{2,5}-\d{1,5}/;
export const checkTicketVersion = (ticket: string) => regExp.test(ticket);

export const setCurrentStatus = () => {
    const version = window.__settings.versions.reviewTag || 'unknown';

    document
        .getElementById(IDS.BODY)
        ?.appendChild(document.createTextNode(version));
};

export const getControlsInputs = (app: string) => {
    const body = document.getElementById(IDS.VERSIONS);

    const blue = body?.querySelector<HTMLInputElement>(
        `[data-type="${app + MARKERS.BLUE}"]`
    );
    const green = body?.querySelector<HTMLInputElement>(
        `[data-type="${app + MARKERS.GREEN}"]`
    );
    const ticket = body?.querySelector<HTMLInputElement>(
        `[data-type="${app + MARKERS.TICKET}"]`
    );

    return [blue, green, ticket];
};

export const getLocaleOverride = () =>
    localStorage.getItem(KEYS.OVERRIDE_LOCALE);
const setLocaleOverride = (locale: string) =>
    localStorage.setItem(KEYS.OVERRIDE_LOCALE, locale);
const removeLocaleOverride = () =>
    localStorage.removeItem(KEYS.OVERRIDE_LOCALE);
export const getFormatLocaleOverride = () =>
    localStorage.getItem(KEYS.OVERRIDE_FORMAT_LOCALE);
const setFormatLocaleOverride = (locale: string) =>
    localStorage.setItem(KEYS.OVERRIDE_FORMAT_LOCALE, locale);
const removeFormatLocaleOverride = () =>
    localStorage.removeItem(KEYS.OVERRIDE_FORMAT_LOCALE);

export const loadVersions = () => {
    APPS_LIST.forEach((app) => {
        const [blue, green, ticket] = getControlsInputs(app);
        const result = document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');

            if (parts[0] === app + '_ver') {
                const value = decodeURIComponent(parts[1]);
                if (value === 'g') {
                    return VALUES.GREEN;
                }

                if (checkTicketVersion(value)) {
                    return value;
                }
            }

            return r;
        }, VALUES.BLUE);

        if (blue) {
            blue.checked = result === VALUES.BLUE;
        }

        if (green) {
            green.checked = result === VALUES.GREEN;
        }

        if (ticket) {
            ticket.value = checkTicketVersion(result) ? result : '';
        }

        const appName = document
            .getElementById(IDS.VERSIONS)
            ?.querySelector<HTMLInputElement>(
                `[data-type="${app + MARKERS.NAME}"]`
            );
        appName?.classList.toggle(
            CLASSES.CONTROLS_BLUE,
            result === VALUES.BLUE
        );
        appName?.classList.toggle(
            CLASSES.CONTROLS_GREEN,
            result === VALUES.GREEN
        );

        if (isCurrentApp(app)) {
            const body = document.getElementById(IDS.BODY);
            body?.classList.toggle(CLASSES.BLUE, result === VALUES.BLUE);
            body?.classList.toggle(CLASSES.GREEN, result === VALUES.GREEN);

            _currentVersion = result;
        }
    });

    const localeOverride = getLocaleOverride();
    const formatLocaleOverride = getFormatLocaleOverride();

    const localeSelect = document
        .getElementById(IDS.VERSIONS)
        ?.querySelector<HTMLInputElement>(`[data-type="${MARKERS.LOCALE}"]`);
    localeSelect && (localeSelect.value = EMPTY_LOCALE);
    _currentLocale = EMPTY_LOCALE;

    if (localeOverride) {
        if (ENGAGE_SUPPORT_LOCALE_LIST.includes(localeOverride as Languages)) {
            localeSelect && (localeSelect.value = localeOverride);
            _currentLocale = localeOverride;
        } else {
            removeLocaleOverride();
        }
    }

    const formatLocaleSelect = document
        .getElementById(IDS.VERSIONS)
        ?.querySelector<HTMLInputElement>(
            `[data-type="${MARKERS.FORMAT_LOCALE}"]`
        );
    formatLocaleSelect && (formatLocaleSelect.value = EMPTY_LOCALE);
    _currentFormatLocale = EMPTY_LOCALE;

    if (formatLocaleOverride) {
        if (FULL_RC_LOCALE_LIST.includes(formatLocaleOverride as Languages)) {
            formatLocaleSelect &&
                (formatLocaleSelect.value = formatLocaleOverride);
            _currentFormatLocale = formatLocaleOverride;
        } else {
            removeFormatLocaleOverride();
        }
    }
};

export const deleteAppCookie = (app: string) => {
    document.cookie = `${encodeURIComponent(
        app + '_ver'
    )}=''; ${COOKIE_ATTRIBUTES}; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const setAppCookie = (app: string, value: string) => {
    if (value) {
        document.cookie =
            encodeURIComponent(app + '_ver') +
            '=' +
            encodeURIComponent(
                value === VALUES.GREEN || value === VALUES.BLUE
                    ? value.slice(0, 1)
                    : value
            ) +
            '; ' +
            COOKIE_ATTRIBUTES;
    }
};

export const checkForceState = () => {
    const currentPageSearch = new URLSearchParams(window.location.search);
    if (currentPageSearch.has('state')) {
        const forceStateRaw = currentPageSearch.get('state')?.split('!');

        if (forceStateRaw?.length) {
            const forceState = forceStateRaw.reduce<Record<string, string>>(
                (accum, item) => {
                    const appState = item.split('_');

                    if (appState.length) {
                        accum[appState[0]] = appState[1];
                    }

                    return accum;
                },
                {}
            );

            APPS_LIST.forEach((app) => {
                const stateValue = forceState[app];

                if (stateValue) {
                    setAppCookie(app, stateValue);
                } else {
                    deleteAppCookie(app);
                }
            });

            const newHref = window.location.origin + window.location.pathname;

            currentPageSearch.delete('state');

            window.location.assign(
                `${newHref}?${currentPageSearch.toString()}`
            );
        }
    }
};

export const saveVersions = () => {
    const result: Record<string, string> = {};
    let needReload = false;

    APPS_LIST.forEach((app) => {
        const [blue, green, ticket] = getControlsInputs(app);

        if (blue?.checked) {
            result[app] = VALUES.BLUE;
            deleteAppCookie(app);
        } else if (green?.checked) {
            result[app] = VALUES.GREEN;
            setAppCookie(app, VALUES.GREEN);
        } else if (ticket?.value) {
            result[app] = checkTicketVersion(ticket?.value)
                ? ticket?.value
                : '';
            setAppCookie(app, ticket?.value);
        }

        if (isCurrentApp(app) && _currentVersion !== result[app]) {
            needReload = true;
        }
    });

    localStorage.setItem(KEYS.VERSIONS_KEY, JSON.stringify(result));

    const localeSelect = document
        .getElementById(IDS.VERSIONS)
        ?.querySelector<HTMLInputElement>(`[data-type="${MARKERS.LOCALE}"]`);

    if (localeSelect && localeSelect.value !== _currentLocale) {
        setLocaleOverride(localeSelect.value);
        needReload = true;
    }

    const formatLocaleSelect = document
        .getElementById(IDS.VERSIONS)
        ?.querySelector<HTMLInputElement>(
            `[data-type="${MARKERS.FORMAT_LOCALE}"]`
        );

    if (
        formatLocaleSelect &&
        formatLocaleSelect.value !== _currentFormatLocale
    ) {
        setFormatLocaleOverride(formatLocaleSelect.value);
        needReload = true;
    }

    if (needReload) {
        location.reload();
    }
};

export const save = () => {
    toggleVersionControl();
    saveVersions();
    loadVersions();
};

export const toggleControls = (type: string) => {
    const result = [MARKERS.BLUE, MARKERS.GREEN, MARKERS.TICKET].filter(
        (marker) => !type?.endsWith(marker)
    );

    if (result.length !== 3) {
        const app = type?.split('_')[0];
        const [blue, green, ticket] = getControlsInputs(app);

        result.forEach((marker: string) => {
            switch (marker) {
                case MARKERS.BLUE:
                    blue && (blue.checked = false);
                    break;
                case MARKERS.GREEN:
                    green && (green.checked = false);
                    break;
                case MARKERS.TICKET:
                    ticket && (ticket.value = '');
                    break;
            }
        });
    }
};
export const controlsController = (event: Event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const type = event.target?.dataset?.type;

    switch (type) {
        case MARKERS.CANCEL:
            toggleVersionControl();
            break;
        case MARKERS.SUBMIT:
            save();
            break;
        case MARKERS.LOCALE:
        case MARKERS.FORMAT_LOCALE:
            break;
        default:
            toggleControls(type);
    }
};

export const addVersionHelperScript = () => {
    loadVersions();

    document
        .getElementById(IDS.BODY)
        ?.addEventListener('click', toggleVersionControl);

    document
        .getElementById(IDS.VERSIONS)
        ?.addEventListener('click', controlsController);
};

export const removeVersionHelperScript = () => {
    document
        .getElementById(IDS.BODY)
        ?.removeEventListener('click', toggleVersionControl);

    document
        .getElementById(IDS.VERSIONS)
        ?.removeEventListener('click', controlsController);
};

export const removeVersionHelperMarkup = () => {
    removeElement(IDS.BODY);
    removeElement(IDS.VERSIONS);
};

export const start = () => {
    addVersionHelperStyle();
    addVersionHelperMarkup();
    addVersionHelperScript();

    checkForceState();

    loadVersions();
    setCurrentStatus();
};

export const stop = () => {
    removeVersionHelperScript();
    removeVersionHelperMarkup();
    removeVersionHelperStyle();
};
