import type { CprUserInfo } from '@ringcx/shared';
import {
    CprClient,
    ProductSubcategory,
    Session,
    UserService,
} from '@ringcx/shared';

import { ConsoleLogger } from '../logging/consoleLogger';

export class AuthClientCrp extends CprClient<object> {
    private static instance: Nullable<AuthClientCrp> = null;

    private constructor() {
        super();
    }

    public static getInstance(): AuthClientCrp {
        if (!this.instance) {
            this.instance = new AuthClientCrp();
        }

        return this.instance;
    }

    protected async collectLogs(): Promise<unknown[]> {
        return Promise.all([
            ConsoleLogger.getInstance().getConsoleLogRecords(),
        ]);
    }

    public override getUserInfo(): CprUserInfo {
        const userInfo = UserService.getFullUserDetails();
        const details = Session.getUserDetails();

        let email = details.rcUser?.email;
        if (!email) {
            if (details.agentDetails?.length === 1) {
                email = details.agentDetails?.[0]?.email;
            }
        }
        return {
            userId: `${userInfo?.rcUserId ?? ''}`,
            accountId: userInfo?.rcAccountId ?? '',
            email: email ?? '',
            productSubcategory: ProductSubcategory.AuthClient,
        };
    }
}
